import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Wrapper, H, P, Grid } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import { Demographic } from 'types'

import { FilterContext } from 'store/filter'
import { formatNumber, formatPercentage } from 'lib/formatting/numbers'
import { formatCurrencyInPounds } from 'lib/formatting/currency'
import { SkeletonLoader } from 'components/loader/SkeletonLoader'
import { GridItemWrapper } from 'components/stats/GridItemWrapper'
import { ScrollingWrapper } from 'components/ScrollingWrapper'
import { TabbedNavigation } from 'components/TabbedNavigation'
import { BAR_CHART_MARGIN } from 'components/stats/chart/constants'
import { BarChart, Data as BarChartData } from 'components/stats/BarChart'
import {
  FramedTable,
  Data as FramedTableData,
  Column as FramedTableColumn,
} from 'components/stats/FramedTable'
import {
  GroupedBarChart,
  Data as GroupedBarChartData,
} from 'components/stats/GroupedBarChart'

import { PerformanceContext } from './context'
import {
  getGiftChartColor,
  GiftChartCategory,
  StatFormat,
} from './chartHelpers'

interface Tab {
  label: string
  key: Demographic
}

const DEMOGRAPHIC_TABS: Tab[] = [
  {
    label: 'Region',
    key: 'region',
  },
  {
    label: 'Age',
    key: 'age',
  },
  {
    label: 'Marital status',
    key: 'marital_status',
  },
  {
    label: 'Number of children',
    key: 'number_of_children',
  },
]

const getIncomeTableColumns = (
  activeTab: Tab,
  hideCampaignColumns: boolean
): FramedTableColumn[] => {
  const columns = [
    {
      label: activeTab.label,
      key: activeTab.key as string,
      flex: 1.2,
    },
    {
      label: 'Voucher uses',
      key: 'voucher_uses',
      valueFormatter: formatNumber,
    },
    {
      label: 'Wills with gifts',
      key: 'wills_with_gifts',
      valueFormatter: formatNumber,
    },
    {
      label: 'Gift inclusion rate',
      key: 'gift_inclusion_rate',
      valueFormatter: formatPercentage,
    },
    {
      label: 'Est. income',
      key: 'estimated_income',
      valueFormatter: formatCurrencyInPounds,
    },
  ]

  if (hideCampaignColumns) {
    return [{ ...columns[0], flex: 2 }, columns[2], columns[4]]
  }

  return columns
}

const StyledChartTitle = styled.header`
  padding: ${GTR.XS} 0 ${GTR.M} ${BAR_CHART_MARGIN.left}px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  gap: ${GTR.XXS} ${GTR.S};

  ${screenMax.l`
    flex-direction: column;
  `}

  > * {
    margin: 0;
  }
`

export const Demographics = (): React.ReactElement => {
  const { data, loading } = useContext(PerformanceContext)
  const [{ current }] = useContext(FilterContext)
  const [activeTab, setActiveTab] = useState<Tab>(DEMOGRAPHIC_TABS[0])
  const tableData = data?.[activeTab.key] || []
  const chartData = tableData.filter(
    (row) => row[activeTab.key] !== 'Unknown' && row[activeTab.key] !== 'Other'
  )
  const campaignView = current.trafficTypeFilter === 'campaign'

  return (
    <Wrapper separator>
      <SkeletonLoader loading={loading} inline>
        <H tag="h2" size="M" margin={[0, 0, 'S']}>
          Demographics
        </H>
      </SkeletonLoader>

      <SkeletonLoader loading={loading}>
        <TabbedNavigation
          tabs={DEMOGRAPHIC_TABS}
          activeTabKey={activeTab.key as string}
          onTabChange={(key) => {
            const tab = DEMOGRAPHIC_TABS.find((t) => (t.key as string) === key)
            if (tab) setActiveTab(tab)
          }}
        />
      </SkeletonLoader>

      <Grid>
        <GridItemWrapper loading={loading} minWidth={700}>
          <StyledChartTitle>
            <H tag="h3" size="XS">
              Estimated future income by {activeTab.label.toLowerCase()}
            </H>
            <P size="S">
              Total:{' '}
              {formatCurrencyInPounds(
                data?.chart_data.estimated_income.total || 0
              )}
            </P>
          </StyledChartTitle>
          <BarChart
            data={chartData as BarChartData}
            xLabelKey={activeTab.key as string}
            label="Estimated future income"
            dataKey="estimated_income"
            color={getGiftChartColor(
              StatFormat.Monetary,
              GiftChartCategory.Total
            )}
          />
        </GridItemWrapper>

        <Grid.Item margin={[0, 0, 'S']}>
          <SkeletonLoader loading={loading}>
            <ScrollingWrapper minWidth={campaignView ? 650 : 500}>
              <FramedTable
                data={tableData as FramedTableData}
                columns={getIncomeTableColumns(activeTab, !campaignView)}
              />
            </ScrollingWrapper>
          </SkeletonLoader>
        </Grid.Item>

        <GridItemWrapper loading={loading} minWidth={700}>
          <StyledChartTitle>
            <H tag="h3" size="XS">
              Number of gifts by {activeTab.label.toLowerCase()}
            </H>
            <P size="S">
              Total gifts:{' '}
              {formatNumber(data?.chart_data.wills_with_gifts.total || 0)}
            </P>
          </StyledChartTitle>
          <GroupedBarChart
            data={chartData as GroupedBarChartData}
            xLabelKey={activeTab.key as string}
            groups={[
              {
                label: 'Residuary Gift',
                key: 'residuary_gifts_total',
                color: getGiftChartColor(
                  StatFormat.Numerical,
                  GiftChartCategory.Residuary
                ),
              },
              {
                label: 'Pecuniary Gift',
                key: 'pecuniary_gifts_total',
                color: getGiftChartColor(
                  StatFormat.Numerical,
                  GiftChartCategory.Pecuniary
                ),
              },
            ]}
          />
        </GridItemWrapper>

        <Grid.Item margin={[0, 0, 'S']}>
          <SkeletonLoader loading={loading}>
            <ScrollingWrapper minWidth={650}>
              <FramedTable
                data={tableData as FramedTableData}
                columns={[
                  {
                    label: activeTab.label,
                    key: activeTab.key as string,
                    flex: 1.5,
                  },
                  {
                    label: 'Total',
                    key: 'residuary_gifts_total',
                    valueFormatter: formatNumber,
                  },
                  {
                    label: 'Avg. size',
                    key: 'residuary_gifts_avg_size',
                    valueFormatter: formatPercentage,
                  },
                  {
                    label: 'Total',
                    key: 'pecuniary_gifts_total',
                    valueFormatter: formatNumber,
                  },
                  {
                    label: 'Avg. size',
                    key: 'pecuniary_gifts_avg_size',
                    valueFormatter: formatCurrencyInPounds,
                  },
                  {
                    label: 'Residuary %',
                    key: 'proportion_residuary',
                    valueFormatter: formatPercentage,
                    flex: 1.2,
                  },
                ]}
                groupedColumns={[
                  {
                    label: 'Residuary gifts',
                    keys: ['residuary_gifts_total', 'residuary_gifts_avg_size'],
                  },
                  {
                    label: 'Pecuniary gifts',
                    keys: ['pecuniary_gifts_total', 'pecuniary_gifts_avg_size'],
                  },
                ]}
              />
            </ScrollingWrapper>
          </SkeletonLoader>
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}
