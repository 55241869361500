import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { RadioGroup, Wrapper } from '@farewill/ui'

import { DatePicker } from 'components/form/DatePicker'
import { DropdownList } from 'components/form/DropdownList'
import { formatDate } from 'lib/formatting/time'
import { FilterContext } from 'store/filter'
import {
  DATE_RANGE,
  DATE_RANGE_OPTIONS,
  DEFAULT_DATE_RANGE_END_DATE,
  DEFAULT_DATE_RANGE_START_DATE,
  MINIMUM_DATE,
} from '../constants'
import { DateRangeOption, getDateRange } from './methods'

const StyledNoWrap = styled.span`
  white-space: nowrap;
`

enum DatesOption {
  AllTime = 'ALL_TIME',
  Range = 'RANGE',
  Custom = 'CUSTOM',
}

interface DatesFilterProps {
  startDateIsValid: boolean
  endDateIsValid: boolean
}

export const DatesFilter = ({
  startDateIsValid,
  endDateIsValid,
}: DatesFilterProps): React.ReactElement => {
  const [{ next }, { setStartDate, setEndDate, clearDates }] =
    useContext(FilterContext)
  /**
   * When the startDate and endDate are already set to the default "Last 12 months",
   * then we want the initial datesValue to be "Date range".
   * Otherwise, we will leave datesValue as undefined here, and instead set it in
   * the useEffect below.
   */
  const initialDatesValue =
    next.startDate?.getTime() === DEFAULT_DATE_RANGE_START_DATE.getTime() &&
    next.endDate?.getTime() === DEFAULT_DATE_RANGE_END_DATE.getTime()
      ? DatesOption.Range
      : undefined
  const [datesValue, setDatesValue] = useState<DatesOption>(initialDatesValue)

  useEffect(() => {
    /** If we have a startDate or endDate but don't have a datesValue (eg when page is refreshed), we will default to Custom */
    if (!datesValue && (next.startDate || next.endDate)) {
      setDatesValue(DatesOption.Custom)
    }

    if (!next.startDate && !next.endDate) {
      setDatesValue(DatesOption.AllTime)
    }
  }, [clearDates, datesValue, next])

  const toggleDatesOption = (option: DatesOption): void => {
    if (option === DatesOption.AllTime) {
      setDatesValue(DatesOption.AllTime)
      clearDates()
    }

    if (option === DatesOption.Range) {
      setDatesValue(DatesOption.Range)
      /** For "Date range", we default to "Last 12 months" */
      const { startDate, endDate } = getDateRange(DATE_RANGE.LAST_12_MONTHS)
      setStartDate(startDate)
      setEndDate(endDate)
    }

    if (option === DatesOption.Custom) {
      setDatesValue(DatesOption.Custom)
      const now = new Date()
      const oneMonthAgo = moment(now).subtract(1, 'months').toDate()
      setStartDate(oneMonthAgo)
      setEndDate(now)
    }
  }

  const options = [
    { value: DatesOption.AllTime, label: 'All dates' },
    {
      value: DatesOption.Custom,
      label: 'Custom dates',
      nestedContent: (
        <>
          <DatePicker
            label="Start date"
            value={next.startDate}
            onChange={setStartDate}
            min={new Date('2016-01-01')}
            max={next.endDate}
            errorMessage={
              !startDateIsValid ? (
                <>
                  Start date must be between{' '}
                  <StyledNoWrap>{formatDate(MINIMUM_DATE)}</StyledNoWrap> and
                  end date
                </>
              ) : null
            }
          />
          <DatePicker
            label="End date"
            value={next.endDate}
            onChange={setEndDate}
            min={next.startDate}
            max={new Date()}
            errorMessage={
              !endDateIsValid
                ? 'End date must be between start date and today'
                : null
            }
          />
        </>
      ),
    },
  ]

  options.splice(1, 0, {
    value: DatesOption.Range,
    label: 'Date range',
    nestedContent: (
      <DropdownList
        defaultValue={DATE_RANGE.LAST_12_MONTHS}
        data={DATE_RANGE_OPTIONS}
        onChange={(value) => {
          const { startDate, endDate } = getDateRange(value as DateRangeOption)
          setStartDate(startDate)
          setEndDate(endDate)
        }}
      />
    ),
  })

  return (
    <Wrapper margin={['L', 0]} tag="section">
      <RadioGroup
        legend="Dates"
        name="date"
        value={datesValue}
        options={options}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          toggleDatesOption(e.currentTarget.value as DatesOption)
        }}
      />
    </Wrapper>
  )
}
