import { COLOR } from '@farewill/ui/tokens'
import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

type GeneratedParagraphProps = {
  animate: boolean
  children: React.ReactNode
  isFirst: boolean
  isLast: boolean
  startIndex: number
}

const reveal = keyframes`
  from {
    background-size: 0 1lh;
  }
`
/**
 * [1] Since the content is JSX (and not just a string) we can't count the
 *     number of (visible characters) easily without rendering and then doing
 *     innerText.length. While we're doing this we set $measuring to true so
 *     that we 1) Don't start the animation and 2) Don't show the text.
 *
 * [2] Work around browsers treating custom properties as strings and thus not
 *    allowing the use of calc() in the background-size property. Once
 *    `@property` becomes globally supported, we can use that instead and move
 *     the calculation to CSS.
 */
const StyledSpan = styled.span<{
  $animate: boolean
  $measuring: boolean // [1]
  $blurDistance: number
}>`
  --blur-distance: ${({ $blurDistance }) => $blurDistance}px; // [2]

  -webkit-background-clip: text;
  animation-delay: var(--animation-delay);
  animation-direction: normal;
  animation-duration: var(--animation-duration);
  animation-fill-mode: both;
  animation-name: ${({ $animate, $measuring }) =>
    $animate && !$measuring ? reveal : 'none'};
  animation-timing-function: var(--ease);
  background-clip: text;
  background-image: ${({ $blurDistance }) => `linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) calc(100% - ${$blurDistance}px ),
    rgba(255, 255, 255, 0) 100%
  )`};
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: ${({ $blurDistance }) =>
    `calc(100% + ${$blurDistance}px) 1lh`};
  color: transparent;
  opacity: ${({ $measuring }) => ($measuring ? 0 : 1)};

  ::selection,
  *::selection {
    color: ${COLOR.BLACK};
  }
`

export const GeneratedParagraph = ({
  animate,
  children,
  isFirst,
  isLast,
  startIndex,
}: GeneratedParagraphProps): React.ReactElement => {
  const contentRef = useRef<HTMLSpanElement>(null)
  const [chars, setChars] = useState(0)
  const measuring = chars === 0

  let easing: 'ease-in' | 'ease-out' | 'ease-in-out' | 'linear'

  if (isFirst && isLast) {
    easing = 'ease-in-out'
  } else if (isFirst) {
    easing = 'ease-in'
  } else if (isLast) {
    easing = 'ease-out'
  } else {
    easing = 'linear'
  }

  useEffect(() => {
    if (contentRef.current) {
      setChars(contentRef.current.textContent?.length || 0)
    }
  }, [children])

  return (
    <p>
      <StyledSpan
        $animate={animate}
        $blurDistance={50} // px
        $measuring={measuring}
        ref={contentRef}
        style={
          {
            '--chars': chars,
            '--char-duration': '0.0024s',
            '--animation-duration': 'calc(var(--chars) * var(--char-duration))',
            '--ease': easing,
            '--animation-delay': `calc(${startIndex} * var(--char-duration))`,
          } as React.CSSProperties
        }
      >
        {children}
      </StyledSpan>
    </p>
  )
}
