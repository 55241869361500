import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Divider, Grid, H, P, Wrapper } from '@farewill/ui'

import { Reports } from 'types'
import { useApi } from 'lib/hooks/useApi'
import { BulletedList } from 'components/BulletedList'
import { SkeletonLoader } from 'components/loader/SkeletonLoader'
import { GlobalContext } from 'store/global'

import { Download } from './Download'

export const CustomerReports = (): React.ReactElement => {
  const [
    {
      partner: { id: partnerId, name: partnerName },
    },
  ] = useContext(GlobalContext)
  const [reportId, setReportId] = useState<string>()
  const [filename, setFilename] = useState<string>()
  const [showDownloadIframe, setShowDownloadIframe] = useState<boolean>(false)
  const {
    data: reports,
    loading,
    error,
  } = useApi<Reports>(`partners/${partnerId}/reports?limit=3`)
  const formatDate = (date: string) => moment(date).format('MMMM YYYY')
  const constructFilename = (date: string) =>
    `FW Report - ${partnerName} (${formatDate(date)}).csv`

  const triggerReportDownload = ({
    id,
    monthStart,
  }: {
    id: string
    monthStart: string
  }) => {
    /*
     * We can ensure every button click triggers a new download, even if the
     * report ID and filename stay the same, by removing and remounting the
     * download iframe. Because there is no reliable way to check if a report
     * download is complete, we can hide the download iframe on a new button
     * click and then trigger the remounting with the useEffect hook below.
     */
    setShowDownloadIframe(false)
    setReportId(id)
    setFilename(constructFilename(monthStart))
  }

  useEffect(() => {
    if (reportId && filename && !showDownloadIframe) {
      setShowDownloadIframe(true)
    }
  }, [reportId, filename, showDownloadIframe])

  return (
    <SkeletonLoader loading={loading}>
      <Wrapper margin={['L', 0, 'M']} maxWidthInColumns={7}>
        <H tag="h2" size="M" margin={[0, 0, 'S']}>
          Stewardship reports
        </H>
        <P>
          When you download customers’ personal data, you become an independent
          data controller of that data, and you must:
        </P>
        <BulletedList>
          <li>
            put measures in place to keep it safe, like storing it securely,
            keeping it confidential and limiting who sees it
          </li>
          <li>
            only process personal data for as long as you need to, for the
            purpose(s) set out in our agreement and in line with data protection
            laws
          </li>
          <li>
            only use personal data for marketing purposes when you have a legal
            right to
          </li>
          <li>
            let us know if someone wants to exercise their data rights, for
            example if someone wants to delete their data
          </li>
        </BulletedList>
        <P>
          Reports are refreshed every month on the 15th. If you have any
          questions, please see our FAQs or email{' '}
          <a href="mailto:charities@farewill.com">charities@farewill.com</a>.
        </P>
        {reports?.length === 0 && (
          <P>
            There are no reports available for {partnerName} yet. If you have
            any questions, or think you should be seeing reports here, please
            contact{' '}
            <a href="mailto: charities@farewill.com">charities@farewill.com</a>
          </P>
        )}
      </Wrapper>
      {!error && !loading && reports.length > 0 && (
        <Grid gap="M">
          <Grid.Item spanFromL={6}>
            <Button.Secondary
              stretch
              onClick={() => triggerReportDownload(reports[0])}
            >
              Download {formatDate(reports[0].monthStart)} Report
            </Button.Secondary>
          </Grid.Item>
          {reports.length > 1 && (
            <>
              <Grid.Item startRow={2} span={12}>
                <H tag="h3" size="XS" margin={0}>
                  Previous reports
                </H>
              </Grid.Item>
              <Grid.Item startRow={3} spanFromL={6}>
                <Button.Bordered
                  stretch
                  onClick={() => triggerReportDownload(reports[1])}
                >
                  Download {formatDate(reports[1].monthStart)} Report
                </Button.Bordered>
              </Grid.Item>
              {reports.length > 2 && (
                <Grid.Item startRow={4} startRowFromL={3} spanFromL={6}>
                  <Button.Bordered
                    stretch
                    onClick={() => triggerReportDownload(reports[2])}
                  >
                    Download {formatDate(reports[2].monthStart)} Report
                  </Button.Bordered>
                </Grid.Item>
              )}
            </>
          )}
        </Grid>
      )}
      {showDownloadIframe && (
        <Download
          partnerId={partnerId}
          partnerName={partnerName}
          reportId={reportId}
          filename={filename}
        />
      )}
      <Divider margin={['L', 0, 0]} />
    </SkeletonLoader>
  )
}
