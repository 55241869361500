import React from 'react'
import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { Tab } from './Tab'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean
  tabs: {
    label: string
    key: string
  }[]
  activeTabKey: string
  onTabChange: (key: string) => void
}

const StyledTabbedNavigation = styled.nav`
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
  margin-bottom: ${GTR.M};
`

export const TabbedNavigation = ({
  disabled,
  tabs,
  activeTabKey,
  onTabChange,
  ...props
}: Props): React.ReactElement => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledTabbedNavigation {...props}>
    {tabs.map(({ label, key }) => (
      <Tab
        disabled={disabled}
        id={`tab-${key}`}
        key={key}
        active={key === activeTabKey}
        onClick={() => onTabChange(key)}
      >
        {label}
      </Tab>
    ))}
  </StyledTabbedNavigation>
)
