import React from 'react'
import { H, Image, P, Wrapper } from '@farewill/ui'

export const GeneralError = (): React.ReactElement => (
  <Wrapper centered padding={['M', 0]} paddingFromM={['XL', 0, 0]}>
    <H tag="h2" size="S">
      Something’s gone wrong
    </H>
    <Image
      path="illustrations/checks-failed"
      alt="Farewill error"
      width={300}
    />
    <P>
      Try <a href=".">reloading this page.</a>
    </P>
    <P>
      If the problem persists, contact{' '}
      <a
        href="mailto: charities@farewill.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        charities@farewill.com
      </a>
    </P>
  </Wrapper>
)
