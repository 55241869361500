import React from 'react'

export const Avatar = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" fill="none">
    <g clipPath="url(#a)">
      <circle cx="18.42" cy="18" r="18" fill="#DEDBDD" />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M22.42 14a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm2 0a6 6 0 1 1-12 0 6 6 0 0 1 12 0ZM12.387 27.254c.223-.849.942-1.926 2.057-2.8C15.544 23.59 16.936 23 18.42 23s2.877.59 3.977 1.453c1.115.875 1.833 1.952 2.056 2.8a1 1 0 0 0 1.934-.507c-.36-1.373-1.392-2.796-2.756-3.866-1.38-1.082-3.195-1.88-5.21-1.88-2.017 0-3.832.798-5.212 1.88-1.364 1.07-2.396 2.493-2.756 3.866a1 1 0 0 0 1.934.508Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.42 0h36v36h-36z" />
      </clipPath>
    </defs>
  </svg>
)
