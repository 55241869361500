import React from 'react'
import styled from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

import { Header } from 'components/Header'
import { Footer } from 'components/Footer'

const StyledPageWrapper = styled.div`
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
`

export const DefaultLayout = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => (
  <StyledPageWrapper>
    <Header />

    {children}

    <Footer />
  </StyledPageWrapper>
)
