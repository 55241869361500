import { clearedState, State } from './constants'

export const hasDateRangeFilter = (state: State): boolean =>
  !!state.current.startDate && !!state.current.endDate

export const hasFilters = (state: State): boolean =>
  state.current.trafficTypeFilter !== clearedState.trafficTypeFilter ||
  state.current.activeVoucherCodes.length !== 0 ||
  hasDateRangeFilter(state) ||
  state.current.inheritancePrecedence.includes('secondary')
