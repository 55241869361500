import { useAuth0, Auth0ContextInterface } from '@auth0/auth0-react'

export type AuthState = {
  token: string
  user: AuthUser
}

export type AuthUser = {
  sub: string
  email: string
}

export const useAuth = (): Auth0ContextInterface => {
  const auth0 = useAuth0()

  if (window.Cypress) {
    /*
     * If we're running under Cypress the cy.login command will have
     * authenticated with the Auth0 API and put the auth state in
     * localStorage, so we deserialise and use that auth state.
     */
    const isAuthenticated = !!localStorage.getItem('auth0Cypress')

    return {
      ...auth0,
      isAuthenticated,
    }
  }

  return auth0
}
