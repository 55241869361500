import React from 'react'

import { State, ActionType, clearedState } from '../constants'

export const reducer: React.Reducer<State, { type: ActionType }> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionType.ClearFilters:
      return {
        ...state,
        current: clearedState,
        next: clearedState,
      }

    case ActionType.ClearDates:
      return {
        ...state,
        next: {
          ...state.next,
          startDate: null,
          endDate: null,
        },
      }

    case ActionType.ClearVoucherCodes:
      return {
        ...state,
        next: {
          ...state.next,
          activeVoucherCodes: [],
        },
      }

    default:
      return state
  }
}

export const clearFilters =
  (
    dispatch: React.Dispatch<{
      type: ActionType.ClearFilters
      payload: undefined
    }>
  ) =>
  (): void =>
    dispatch({
      type: ActionType.ClearFilters,
      payload: undefined,
    })

export const clearDates =
  (
    dispatch: React.Dispatch<{
      type: ActionType.ClearDates
      payload: undefined
    }>
  ) =>
  (): void =>
    dispatch({
      type: ActionType.ClearDates,
      payload: undefined,
    })

export const clearVoucherCodes =
  (
    dispatch: React.Dispatch<{
      type: ActionType.ClearVoucherCodes
      payload: undefined
    }>
  ) =>
  (): void =>
    dispatch({
      type: ActionType.ClearVoucherCodes,
      payload: undefined,
    })
