/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import { CampaignAssistantLayout } from 'layouts/CampaignGenerator'
import { EmailCampaigns } from '../components/EmailCampaigns'

const Emails = (): React.ReactElement => {
  return (
    <CampaignAssistantLayout>
      <EmailCampaigns />
    </CampaignAssistantLayout>
  )
}

// React.lazy only supports default exports…
// eslint-disable-next-line import/no-default-export
export default Emails
