import React from 'react'

import { State, ActionType } from '../constants'

export const reducer: React.Reducer<State, { type: ActionType }> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionType.ApplyFilters:
      return {
        ...state,
        current: state.next,
      }

    default:
      return state
  }
}

export const applyFilters =
  (
    dispatch: React.Dispatch<{
      type: ActionType.ApplyFilters
      payload: undefined
    }>
  ) =>
  (): void =>
    dispatch({
      type: ActionType.ApplyFilters,
      payload: undefined,
    })
