import type {
  Audience,
  EmailGenerationResult,
  EmailKey,
  Emails,
  EmailType,
} from './types'
import { TABS } from './constants'

export enum ActionType {
  TriggerGenerate = 'GENERATE',
  GenerationStart = 'GENERATION_START',
  GenerationEnd = 'GENERATION_END',
  GenerationError = 'GENERATION_ERROR',
  TriggerRegenerate = 'REGENERATE',
  RegenerationStart = 'REGENERATION_START',
  RegenerationEnd = 'REGENERATION_END',
  SetAnimationState = 'SET_ANIMATION_STATE',
}

export interface TriggerGenerateAction {
  type: ActionType.TriggerGenerate
  payload: {
    audience: Audience
    partnerId: string
  }
}

interface GenerationStartAction {
  type: ActionType.GenerationStart
  payload: {
    audience: Audience
    generationCount: number
  }
}

interface GenerationEndAction {
  type: ActionType.GenerationEnd
  payload: {
    audience: Audience
    emails: Record<EmailType, EmailGenerationResult>
    threadId: string
  }
}

interface GenerationErrorAction {
  type: ActionType.GenerationError
  payload: {
    audience: Audience
    error: string
  }
}

export interface TriggerRegenerateAction {
  type: ActionType.TriggerRegenerate
  payload: {
    audience: Audience
    email: EmailType
    instructions: string
    partnerId: string
    threadId: string
  }
}

export interface RegenerateStartAction {
  type: ActionType.RegenerationStart
  payload: {
    audience: Audience
    email: EmailType
    instructions: string
  }
}

interface RegenerateEndAction {
  type: ActionType.RegenerationEnd
  payload: {
    audience: Audience
    email: EmailType
    content: string
    subject: string
    threadId: string
  }
}

interface SetAnimationStateAction {
  type: ActionType.SetAnimationState
  payload: {
    audience: Audience
    animated: boolean
  }
}

export type Action =
  | TriggerGenerateAction
  | GenerationStartAction
  | GenerationEndAction
  | GenerationErrorAction
  | TriggerRegenerateAction
  | RegenerateStartAction
  | RegenerateEndAction
  | SetAnimationStateAction

export const reducer = (state: Emails, action: Action): Emails => {
  switch (action.type) {
    case ActionType.SetAnimationState: {
      const newState = TABS.reduce(
        (acc, tab) => {
          const key = `${action.payload.audience}_${tab.key}` as EmailKey
          acc[key] = {
            ...acc[key],
            animated: action.payload.animated,
          }
          return acc
        },
        { ...state }
      )

      return newState
    }

    case ActionType.GenerationStart: {
      const newState = TABS.reduce(
        (acc, tab) => {
          const key = `${action.payload.audience}_${tab.key}` as EmailKey
          acc[key] = {
            animated: false,
            audience: action.payload.audience,
            content: undefined,
            error: undefined,
            generationCount: 1,
            generationEndTime: undefined,
            generationInProgress: true,
            generationInstructions: '',
            generationStartTime: Date.now(),
            subject: undefined,
            threadId: undefined,
          }
          return acc
        },
        { ...state }
      )

      return newState
    }

    case ActionType.GenerationEnd: {
      const newState = {
        ...state,
      }

      Object.entries(action.payload.emails).forEach(([email, result]) => {
        const key = `${action.payload.audience}_${email}` as EmailKey
        newState[key as EmailKey] = {
          ...state[key as EmailKey],
          generationInProgress: false,
          generationEndTime: Date.now(),
          content: result.body,
          error: undefined,
          subject: result.subject,
          threadId: action.payload.threadId,
        }
      })

      return newState
    }

    case ActionType.GenerationError: {
      const { audience, error } = action.payload
      const newState = {
        ...state,
      }

      TABS.forEach((tab) => {
        const key = `${audience}_${tab.key}` as EmailKey
        newState[key as EmailKey] = {
          ...state[key as EmailKey],
          error,
          generationInProgress: false,
          generationEndTime: Date.now(),
        }
      })

      return newState
    }

    case ActionType.RegenerationStart: {
      const key =
        `${action.payload.audience}_${action.payload.email}` as EmailKey

      const newState = {
        ...state,
        [key]: {
          animated: false,
          audience: action.payload.audience,
          content: undefined,
          generationCount: state[key].generationCount + 1,
          generationEndTime: undefined,
          generationInProgress: true,
          generationInstructions: action.payload.instructions,
          generationStartTime: Date.now(),
          subject: undefined,
          threadId: state[key].threadId,
        },
      }

      return newState
    }

    case ActionType.RegenerationEnd: {
      const key =
        `${action.payload.audience}_${action.payload.email}` as EmailKey

      const newState = {
        ...state,
        [key]: {
          ...state[key],
          generationInProgress: false,
          generationEndTime: Date.now(),
          content: action.payload.content,
          subject: action.payload.subject,
          threadId: action.payload.threadId,
        },
      }

      return newState
    }

    default:
      return state
  }
}
