import React, { useContext } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import { Wrapper, RadioGroup } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { getISODateString } from 'lib/formatting/time'
import { useApi } from 'lib/hooks/useApi'
import { Spinner } from 'components/loader/Spinner'
import { Traffic, VoucherCodes } from 'types'
import { FilterContext } from 'store/filter'
import { ServiceTypeContext } from 'routes/context'
import { VoucherCodeCheckboxList } from './VoucherCodeCheckboxList'

const StyledSpinner = styled(Spinner)`
  margin: ${GTR.XL} auto;
`

const buildQueryString = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) =>
  qs.stringify(
    {
      start: startDate && getISODateString(startDate),
      end: endDate && getISODateString(endDate),
    },
    { skipNulls: true }
  )

export const TrafficTypeFilter = ({
  partnerId,
}: {
  partnerId: string
}): React.ReactElement => {
  const [{ next }, { setTrafficTypeFilter }] = useContext(FilterContext)
  const willServiceType = useContext(ServiceTypeContext)
  const queryString = buildQueryString(next)
  const { data: voucherCodes, loading: voucherCodesLoading } =
    useApi<VoucherCodes>(
      willServiceType === 'online' && partnerId
        ? `partners/${partnerId}/vouchers?${queryString}`
        : null
    )

  return voucherCodesLoading ? (
    <StyledSpinner />
  ) : (
    <Wrapper margin={['L', 0]} tag="section">
      <RadioGroup
        legend="Traffic type"
        name="trafficType"
        value={next.trafficTypeFilter}
        options={[
          { value: 'all', label: 'All traffic' },
          { value: 'cold', label: 'Cold traffic' },
          {
            value: 'campaign',
            label: 'Free will service',
            nestedContent:
              willServiceType === 'online' ? (
                <VoucherCodeCheckboxList voucherCodes={voucherCodes || []} />
              ) : undefined,
          },
        ]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTrafficTypeFilter(e.currentTarget.value as Traffic)
        }}
      />
    </Wrapper>
  )
}
