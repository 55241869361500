import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Divider, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { LoadingLayout } from 'layouts/LoadingLayout'
import { Logo } from 'components/Logo'
import { PasswordExpiredError } from 'components/PasswordExpiredError'
import { GeneralError } from 'components/GeneralError'
import { Auth0Trust } from 'components/Auth0Trust'
import { useAuth } from 'lib/hooks/useAuth'

const StyledWrapper = styled(Wrapper)`
  min-height: 100vh;
  background: ${COLOR.WHITE};
`

export const Login = (): React.ReactElement => {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth()
  const { state } = useLocation() as { state: { returnTo: string } } | null

  if (isLoading) {
    return <LoadingLayout />
  }

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  if (error) {
    const errorInfo = error.message.split(' ')
    const [userEmail, errorMessage] = errorInfo

    if (errorMessage === 'password_expired') {
      return <PasswordExpiredError email={userEmail} />
    }

    return <GeneralError />
  }

  return (
    <StyledWrapper paddingFromM={['XL', 'XXL']} padding={['M', 'L']}>
      <Grid>
        <Grid.Item
          span={12}
          spanFromL={6}
          startColumn={1}
          startColumnFromL={2}
          margin={[0, 0, GTR.XXL]}
        >
          <Logo />
        </Grid.Item>
        <Grid.Item
          span={12}
          spanFromL={4}
          startColumn={1}
          startColumnFromL={8}
          startRowFromL={1}
          spanRowsFromL={2}
          margin={[0, 'auto', GTR.M]}
          marginFromL={[200, 0, 0]}
        >
          <Image
            width={300}
            widthFromM={500}
            widthFromL={400}
            widthFromXL={500}
            path="illustrations/cropped/welcome"
          />
        </Grid.Item>
        <Grid.Item span={12} spanFromL={5} startColumn={1} startColumnFromL={2}>
          <H size="L" margin={[0, 0, GTR.XL]}>
            Farewill dashboard
          </H>
          <Button.Primary
            stretch
            onClick={() => {
              /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
              loginWithRedirect({
                appState: { returnTo: state?.returnTo },
              })
            }}
          >
            Sign in
          </Button.Primary>
          <Wrapper margin={[GTR.XL, 0, GTR.M]}>
            <Auth0Trust />
          </Wrapper>
          <Wrapper margin={[GTR.XL, 0, GTR.M]} maxWidthInColumns={6}>
            <P size="S">
              If this is your first time logging in, please click the &quot;Sign
              in&quot; button above, then &quot;Forgot password&quot; to setup
              your account.
            </P>
          </Wrapper>
          <Divider margin={[0, 0, 'M']} />
          <P>
            Trouble logging in? Contact{' '}
            <a rel="noopener noreferrer" href="mailto:charities@farewill.com">
              charities@farewill.com
            </a>
          </P>
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}
