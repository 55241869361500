import React from 'react'

import { Traffic } from 'types'

import { State, ActionType } from '../constants'

type Payload = Traffic

export const reducer: React.Reducer<
  State,
  { type: ActionType; payload: Payload }
> = (state, action) => {
  switch (action.type) {
    case ActionType.SetTrafficTypeFilter:
      return {
        ...state,
        next: {
          ...state.next,
          trafficTypeFilter: action.payload,
        },
      }

    default:
      return state
  }
}

export const setTrafficTypeFilter =
  (
    dispatch: React.Dispatch<{
      type: ActionType
      payload: Payload | undefined
    }>
  ) =>
  (payload: Payload): void => {
    if (payload !== 'campaign') {
      dispatch({ type: ActionType.ClearVoucherCodes, payload: undefined })
    }

    return dispatch({
      type: ActionType.SetTrafficTypeFilter,
      payload,
    })
  }
