import React, { lazy, useContext, Suspense, useEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom'
import styled from 'styled-components'

import { Wrapper } from '@farewill/ui'

import { Routes as Paths } from 'lib/constants'
import { GlobalContext } from 'store/global'
import { Spinner } from 'components/loader/Spinner'
import { RETURN_TO_PARM_NAME } from './constants'

const GenerateAccess = lazy(() => import('./Access'))
const GenerateEmailsRoute = lazy(() => import('./Emails'))
const GenerateTerms = lazy(() => import('./Terms'))

const StyledWrapper = styled(Wrapper)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`

export const Assistant = (): React.ReactElement => {
  const [{ partner }] = useContext(GlobalContext)
  const location = useLocation()
  const match = useMatch('/*')
  const navigate = useNavigate()

  useEffect(() => {
    const hasMarketingAssistantAccess = partner.has_marketing_assistant_access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    const isAccessPage = match.pathname === Paths.GenerateAccess

    if (!hasMarketingAssistantAccess) {
      if (!isAccessPage) navigate(`${Paths.GenerateAccess}`)
      return
    }

    /** From here, we know the partner has access */

    const consented = !!partner?.terms_and_conditions_consents?.find(
      (consent) =>
        consent.name === 'AI_MARKETING_BOT' &&
        consent.consentType === 'ACCEPTED'
    )

    if (isAccessPage) {
      if (consented) {
        navigate(`${Paths.GenerateEmails}`)
      } else {
        navigate(`${Paths.GenerateTerms}`)
      }
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    const isTermsPage = match.pathname === Paths.GenerateTerms

    if (consented && isTermsPage) {
      const returnTo = decodeURIComponent(
        new URLSearchParams(location.search).get(RETURN_TO_PARM_NAME) ||
          Paths.GenerateEmails
      )

      navigate(`${returnTo}`)
      return
    }

    if (!consented && !isTermsPage) {
      navigate(
        `${Paths.GenerateTerms}?${RETURN_TO_PARM_NAME}=${encodeURIComponent(location.pathname)}`
      )
    }
  }, [location.pathname, location.search, match.pathname, navigate, partner])

  if (!partner) {
    return (
      <StyledWrapper>
        <Spinner />
      </StyledWrapper>
    )
  }

  return (
    <>
      <Suspense
        fallback={
          <StyledWrapper>
            <Spinner />
          </StyledWrapper>
        }
      >
        <Routes>
          <Route path="/" element={<Navigate to={Paths.GenerateEmails} />} />
          <Route path="/access" element={<GenerateAccess />} />
          <Route path="/email-campaigns" element={<GenerateEmailsRoute />} />
          <Route path="/terms" element={<GenerateTerms />} />
        </Routes>
      </Suspense>
    </>
  )
}
