import React from 'react'
import styled from 'styled-components'
import { Grid, Divider } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

import { range } from 'lib/helpers/array'
import { Columns, DividerColumns } from '@farewill/ui/components/Grid/types'

const StyledDividerGridItem = styled(Grid.Item)`
  ${screenMax.m`
    display: none;
  `}
`

export const DividedColumns = ({
  children,
  ...props
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const columnCount = React.Children.count(children) as Columns

  if (columnCount === 1) return <>{children}</>

  const dividerCount = columnCount - 1

  return (
    <Grid
      columns={columnCount}
      dividerAtColumnFromL={
        range(dividerCount).map((i) => dividerCount - i) as DividerColumns[]
      }
      gap="S"
      gapFromL="M"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {React.Children.map(children, (child, i) => (
        <>
          <Grid.Item
            span={(columnCount + dividerCount) as Columns}
            spanFromL={1}
          >
            {child}
          </Grid.Item>
          {i < columnCount - 1 && (
            <StyledDividerGridItem span={1}>
              <Divider vertical />
            </StyledDividerGridItem>
          )}
        </>
      ))}
    </Grid>
  )
}
