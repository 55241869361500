import React, { FormEvent } from 'react'
import styled from 'styled-components'

import { ButtonGroup, Button, Input, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledList = styled.ul`
  list-style: disc;
  margin-left: 1.24em;
  // list-style-type: '•';

  li {
    &::marker {
      font-size: 12px;
    }
  }
`

const StyledListItem = styled.li`
  color: ${COLOR.BLACK};
`

type RegenerateFormProps = {
  close: () => void
  submit: (data: { instructions: string }) => void
}

export const RegenerateForm = ({
  close,
  submit,
}: RegenerateFormProps): React.ReactElement => {
  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const data = Object.fromEntries(new FormData(event.currentTarget)) as {
      instructions: string
    }
    close()
    submit(data)
  }

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e)
      }}
    >
      <P color={COLOR.BLACK} margin={[0, 0, GTR.L]}>
        Once you re-generate the email text, the previous version will be lost.
        If you prefer, you can refine the email by requesting changes.
      </P>
      <P strong>Advice for requesting changes:</P>
      <Wrapper margin={[0, 0, GTR.L]}>
        <StyledList>
          <StyledListItem>
            Use general comments, for example ‘make it shorter’, instead of
            specifics like ‘remove this word’.
          </StyledListItem>
          <StyledListItem>
            Ask to include content, for example ‘Include our biography’ or
            ‘Include the following information…’
          </StyledListItem>
          <StyledListItem>
            Ask to change the tone of the email, for example ‘More formal’ or
            ‘More casual’.
          </StyledListItem>
        </StyledList>
      </Wrapper>
      <Input
        label="How would you like the content to change?"
        multiline
        name="instructions"
        rows={4}
      />

      <ButtonGroup margin={[GTR.L, 0, 0]}>
        <Button.Primary type="submit">Regenerate email text</Button.Primary>
        <Button.BorderedDark type="button" onClick={close}>
          Cancel
        </Button.BorderedDark>
      </ButtonGroup>
    </form>
  )
}
