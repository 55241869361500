import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { SwapIcon } from '@farewill/ui'
import { MasqueradeModal } from 'components/MasqueradeModal'
import { GlobalContext } from 'store/global'

const ChangePartnerButton = styled.button`
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 48px;
  opacity: 0.4;
  justify-content: center;
  position: relative;
  top: 4px;
  transition: opacity 0.2s ease;
  width: 48px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
    opacity: 1;
  }

  &:not([disabled]):focus {
    outline-offset: 0;
  }
`

export const PartnerSwitcher = () => {
  const [{ availablePartners }] = useContext(GlobalContext)
  const [showMasqueradeModal, setShowMasqueradeModal] = useState(false)

  if (!availablePartners?.length) {
    return null
  }

  return (
    <MasqueradeModal
      open={showMasqueradeModal}
      close={() => setShowMasqueradeModal(false)}
    >
      <ChangePartnerButton
        aria-label="Change partner"
        title="Change partner"
        onClick={() => {
          setShowMasqueradeModal(true)
        }}
      >
        <SwapIcon />
      </ChangePartnerButton>
    </MasqueradeModal>
  )
}
