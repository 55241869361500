import React from 'react'

import { CampaignAssistantLayout } from 'layouts/CampaignGenerator'
import TermsModal from './components/TermsModal'

const Terms = (): React.ReactElement => {
  return (
    <CampaignAssistantLayout>
      <TermsModal />
    </CampaignAssistantLayout>
  )
}

// React.lazy only supports default exports…
// eslint-disable-next-line import/no-default-export
export default Terms
