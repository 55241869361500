import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

interface StringParam {
  key: string
  value: string | null
  defaultValue: string | null
  isArray: false
  setter: React.Dispatch<string>
}
interface ArrayParam {
  key: string
  value: string[]
  defaultValue: string[]
  isArray: true
  setter: React.Dispatch<string[]>
}
type Param = StringParam | ArrayParam
type SetterReturnValue = string & string[]

const getArrayFromValue = (
  value: undefined | null | string | string[]
): string[] => {
  if (!value) return []
  if (typeof value === 'string') return [value]
  return value
}

/* eslint-disable react-hooks/exhaustive-deps */

export const useQueryParamSync = (
  params: Param[],
  {
    setterCallback,
  }: {
    setterCallback: () => void
  }
): [boolean] => {
  const [isInitialising, setIsInitialising] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  const pagePath = location.pathname
  const searchString = location.search

  const query = qs.parse(searchString, {
    ignoreQueryPrefix: true,
    comma: true,
  }) as { [key: string]: undefined | string | string[] }

  // set values from initial query
  useEffect(() => {
    const paramsWithInitialValues = params.filter(({ key }) => !!query[key])
    paramsWithInitialValues.forEach(({ setter, key, isArray }) => {
      setter(
        (isArray
          ? getArrayFromValue(query[key])
          : query[key]) as SetterReturnValue
      )
    })
    if (paramsWithInitialValues.length) setterCallback()

    setIsInitialising(false)
  }, [])

  // update values to match query (history navigation)
  useEffect(() => {
    if (!isInitialising) {
      params.forEach(({ key, value, setter, defaultValue, isArray }) => {
        const queryValue = query[key] || defaultValue
        if (JSON.stringify(queryValue) !== JSON.stringify(value)) {
          setter(
            (isArray
              ? getArrayFromValue(queryValue)
              : queryValue) as SetterReturnValue
          )
        }
      })
      setterCallback()
    }
  }, [searchString])

  // update query to match values
  useEffect(
    () => {
      // only after initialisation so it doesn't reset query before initial filtering
      if (!isInitialising) {
        const newQuery: Record<string, string | string[]> = {}
        params.forEach(({ key, value, defaultValue }) => {
          if (value && JSON.stringify(defaultValue) !== JSON.stringify(value)) {
            newQuery[key] = value
          }
        })

        const newQueryString = qs.stringify(newQuery, {
          addQueryPrefix: true,
          arrayFormat: 'comma',
          encodeValuesOnly: true,
        })

        if (newQueryString !== searchString) {
          navigate(`${pagePath}${newQueryString}`)
        }
      }
    },
    params.map(({ value }) => value)
  )

  return [isInitialising]
}
