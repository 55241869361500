import React from 'react'

import { Dashboard } from 'components/Dashboard'
import { ServiceTypeContext } from 'routes/context'

export const Online = (): React.ReactElement => (
  <ServiceTypeContext.Provider value="online">
    <Dashboard />
  </ServiceTypeContext.Provider>
)
