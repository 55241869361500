import React from 'react'
import styled from 'styled-components'
import { GTR, FONT, COLOR } from '@farewill/ui/tokens'
import { headingS } from '@farewill/ui/helpers/text'

interface Props {
  title: string | null
  description: string
  value: string | number | undefined | null
}

const StyledTitle = styled.dt`
  color: ${COLOR.BLACK};
`

const StyledDescription = styled.dt`
  font-size: ${FONT.SIZE.S};
`

const StyledDl = styled.dl`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0;
`

const StyledDd = styled.dd`
  ${headingS};
  margin: ${GTR.XS} 0 0;
`

export const SingleStat = ({
  title,
  description,
  value,
}: Partial<Props>): React.ReactElement => (
  <StyledDl>
    <div>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </div>
    <StyledDd>{value ?? '-'}</StyledDd>
  </StyledDl>
)
