import * as d3 from 'd3'
import { GTR, FONT } from '@farewill/ui/tokens'

const WRAP_OVERLAP = 8
const DY = parseInt(GTR.XS, 10)

export const wrapText = (
  textList: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>,
  width: number,
  setTotalHeight?: (height: number) => void
): void => {
  const fontSize = width < 50 ? FONT.SIZE.XXS : FONT.SIZE.XS
  const lineHeight = parseInt(fontSize, 10) * 1.3

  let totalHeight = 0

  textList.each((t, i, l) => {
    const text = t as string
    const textEl = d3.select<SVGElement, unknown>(l[i] as SVGElement)

    const words = text.split(/\s+/).reverse()
    let word = ''
    let line: string[] = []
    let lineNumber = 0
    const x = 0
    const y = textEl.attr('y')
    const dy = DY

    let tspan = textEl
      .text(null)
      .append('tspan')
      .attr('x', x)
      .attr('y', y)
      .attr('dy', dy)
      .style('font-size', fontSize)

    while (words.length) {
      word = words.pop()
      line.push(word)
      tspan.text(line.join(' '))
      if (
        tspan.node().getComputedTextLength() > width + WRAP_OVERLAP &&
        line.length > 1
      ) {
        line.pop()
        tspan.text(line.join(' '))
        line = [word]
        lineNumber += 1
        tspan = textEl
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', lineNumber * lineHeight + dy)
          .style('font-size', fontSize)
          .text(word)
      }
    }

    totalHeight = Math.max(totalHeight, (lineNumber + 1) * lineHeight + dy)
  })

  if (setTotalHeight) setTotalHeight(totalHeight)
}
