import { Column, GroupedColumn } from './types'

interface Cell {
  flex: number
  label?: string
}

export const getGroupedColumnsHeaderCells = (
  columns: Column[],
  groupedColumns?: GroupedColumn[]
): Cell[] | null => {
  if (!groupedColumns) return null

  const cells = columns
    .map(({ key, flex }) => {
      const cell = {
        flex: flex || 1,
      } as Cell

      const group = groupedColumns.find(({ keys }) => keys.includes(key))
      if (group) cell.label = group.label

      return cell
    })
    .reduce((prev: Cell[], curr: Cell) => {
      const lastCell = prev.pop()
      if (!lastCell) return [curr]

      if (lastCell.label === curr.label) {
        return [
          ...prev,
          {
            ...lastCell,
            flex: lastCell.flex + curr.flex,
          },
        ]
      }

      return [...prev, lastCell, curr]
    }, [])

  if (cells.length === 1 && !cells[0].label) return null

  return cells
}
