import React from 'react'
import styled from 'styled-components'
import { FONT, GTR } from '@farewill/ui/tokens'

import { Swatch } from 'components/stats/Swatch'

interface Props {
  data: {
    label: string
    color: string
  }[]
}

const StyledOl = styled.ol`
  text-align: center;
  font-size: ${FONT.SIZE.S};
  margin: -${GTR.XXS} 0;
`

const StyledLi = styled.li`
  display: inline-block;
  margin: ${GTR.XXS} calc(${GTR.M} / 2);
`

const StyledSwatch = styled(Swatch)`
  display: inline-block;
  margin: 0 ${GTR.XS} -2px 0;
`

export const Legend = ({ data }: Props): React.ReactElement => (
  <StyledOl>
    {data.map(({ label, color }) => (
      <StyledLi key={label}>
        <StyledSwatch color={color} />
        {label}
      </StyledLi>
    ))}
  </StyledOl>
)
