import React from 'react'
import { P } from '@farewill/ui'
import styled from 'styled-components'

import { formatDate } from 'lib/formatting/time'

interface Props {
  willServiceType: string
  startDate: Date | null
  endDate: Date | null
}

const StyledSpan = styled.span`
  font-weight: bold;
`

export const ConversionFromRegistrationExplainer = ({
  willServiceType,
  startDate,
  endDate,
}: Props): React.ReactElement => {
  if (willServiceType === 'all') {
    return (
      <P>
        Insight into customers’ progress through the will journeys
        {startDate && endDate ? (
          <>
            {' '}
            from <StyledSpan>{formatDate(startDate)}</StyledSpan> to{' '}
            <StyledSpan>{formatDate(endDate)}</StyledSpan>
          </>
        ) : (
          <></>
        )}
        .
      </P>
    )
  }

  if (willServiceType === 'online') {
    return (
      <P>
        Insight into customers’ progress through the online journey for those
        who register through your campaign landing pages
        {startDate && endDate ? (
          <>
            {' '}
            from <StyledSpan>{formatDate(startDate)}</StyledSpan> to{' '}
            <StyledSpan>{formatDate(endDate)}</StyledSpan> Time from registering
            to making will can be up to 5 days.
          </>
        ) : (
          <></>
        )}
        .
      </P>
    )
  }

  if (willServiceType === 'telephone') {
    return (
      <P>
        Insight into customers’ progress through the journey for those who
        register for your free telephone will service
        {startDate && endDate ? (
          <>
            {' '}
            from <StyledSpan>{formatDate(startDate)}</StyledSpan> to{' '}
            <StyledSpan>{formatDate(endDate)}</StyledSpan>
          </>
        ) : (
          <></>
        )}
        . Time from registering to making will can be up to 15 days.
      </P>
    )
  }

  return <></>
}
