/* eslint-disable import/no-default-export */

type EnvKeys = (typeof PUBLIC_ENV_VARS)[number]

type Env = {
  [key in EnvKeys]: string | undefined
}

export const PUBLIC_ENV_VARS = [
  'AUTH0_AUDIENCE',
  'AUTH0_CUSTOM_DOMAIN',
  'AUTH0_DASHBOARD_CLIENT_ID',
  'BACKSTAGE_URL',
  'GIT_HASH',
  'GOOGLE_ANALYTICS_ID',
  'MARKETING_SITE_URL',
  'NODE_ENV',
  'RELEASE_ENV',
  'SENTRY_UI_URL',
] as const

const config = PUBLIC_ENV_VARS.reduce((acc, key) => {
  acc[key] = process.env[key]
  return acc
}, {} as Env)

export default config
