import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { GlobalContext } from 'store/global'
import { useAuth } from 'lib/hooks/useAuth'
import { LoadingLayout } from 'layouts/LoadingLayout'

interface Props {
  children: React.ReactElement
}

export const PrivateRoute = ({ children }: Props): React.ReactElement => {
  const [{ partner }] = useContext(GlobalContext)
  const { isAuthenticated, isLoading } = useAuth()
  if (isLoading || (isAuthenticated && !partner)) {
    return <LoadingLayout showHeaderAndFooter />
  }

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ returnTo: window.location.pathname }} />
  )
}
