import React from 'react'
import styled from 'styled-components'
import { P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import { paragraphS, headingS } from '@farewill/ui/helpers/text'

import { isNumber } from 'lib/helpers/lang'
import { formatPercentage } from 'lib/formatting/numbers'

import { STEP_PADDING } from './Step'

const StyledRate = styled(P)`
  ${screenMax.m`
    display: grid;
    grid-template-columns: 1fr ${GTR.XL} 1fr;
    align-items: center;
    
    > *:first-child {
      text-align: right;
    }

    > *:last-child {
      text-align: left;
    }
  `}

  && {
    ${screenMin.l`
      text-align: left;
      padding-top: ${STEP_PADDING};
    `}
  }
`

const StyledValue = styled.span`
  ${paragraphS};
  margin: 0;

  ${screenMin.l`
    ${headingS};
    margin: 0;
  `}
`

const StyledArrow = styled.span`
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  margin: ${GTR.XS} 0;
  background: ${COLOR.GREY.LIGHT};
  border-right: 1px solid transparent;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 10px;
    background: ${COLOR.GREY.LIGHT};
    border-radius: 1px;
    transform-origin: 100% 50%;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }

  ${screenMax.m`
    width: ${GTR.M};
    justify-self: center;
    transform: rotate(90deg);
  `}
`

interface Props {
  value?: number | null
  label?: string
}

export const Rate = ({ value, label }: Props): React.ReactElement => (
  <StyledRate size="S" padding="S" paddingFromL={['S', 'M']} margin={0}>
    <StyledValue>
      {isNumber(value) ? formatPercentage(value) : 'N/A'}
    </StyledValue>
    <StyledArrow />
    <span>{label}</span>
  </StyledRate>
)
