// See: https://www.figma.com/file/vqPXyTM33W659Dtk8Vwncd/Spotlight-improvements?type=design&node-id=2666-12081&mode=dev
export const FAREWILL_DISCRETE_CHART_COLOURS = [
  '#6469D8',
  '#00A98B',
  '#C95B7C',
  '#039FB4',
  '#A35CC4',
  '#7C9B00',
  '#E2655D',
  '#BC8416',
]
