import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Wrapper, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

const FEEDBACK_EMAIL = 'charities@farewill.com'

const StyledFooter = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  *:focus:not(:active) {
    outline-color: ${COLOR.GREY.LIGHT};
  }
`

export const Footer = (): React.ReactElement => (
  <Wrapper background={COLOR.GREY.DARK} margin={['auto', 0, 0]}>
    <StyledFooter
      tag="footer"
      container
      containerPaddingTop="M"
      containerPaddingBottom="M"
    >
      <Link to="/">
        <img
          src="/icons/logo-icon.svg"
          title="Farewill"
          alt="Farewill logo"
          height={32}
          width={22}
          style={{ filter: 'invert(1)' }}
        />
      </Link>

      <P color={COLOR.WHITE} margin={[0, 0, 0, 'S']}>
        Send feedback to: {}
        <a href={`mailto: ${FEEDBACK_EMAIL}`}>{FEEDBACK_EMAIL}</a>
      </P>
    </StyledFooter>
  </Wrapper>
)
