import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Wrapper, H, Image, P } from '@farewill/ui'

import { FilterContext } from 'store/filter'
import { isNumber } from 'lib/helpers/lang'
import { formatCurrencyInPounds } from 'lib/formatting/currency'
import { formatNumber, formatPercentage } from 'lib/formatting/numbers'
import { SkeletonLoader } from 'components/loader/SkeletonLoader'
import { GridItemWrapper } from 'components/stats/GridItemWrapper'
import { PercentageChartWithTable } from 'components/stats/PercentageChartWithTable'
import { ServiceTypeContext } from 'routes/context'

import {
  Datum,
  LineChart,
  NumberType,
  createFormatter,
} from 'components/stats/LineChart'
import { ChartData } from 'types'
import { BORDER_RADIUS, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import {
  FramedTable,
  Data as FramedTableData,
  Column as FramedTableColumn,
} from 'components/stats/FramedTable'
import { ScrollingWrapper } from 'components/ScrollingWrapper'
import { PerformanceContext } from './context'
import {
  getGiftChartColor,
  GiftChartCategory,
  StatFormat,
} from './chartHelpers'

interface TabWithStatProps {
  title: string | null
  value: string | null
  isSelected: boolean
  className: string
  onClick: () => void
}

export const TabWithStat = ({
  title,
  value,
  onClick,
  isSelected,
  className,
}: Partial<TabWithStatProps>): React.ReactElement => (
  <button
    type="button"
    role="tab"
    aria-selected={isSelected}
    aria-controls="chart-selector"
    className={className}
    onClick={onClick}
  >
    {title} <span>{value ?? '-'}</span>
  </button>
)

const StyledTabWithStat = styled(TabWithStat)`
  --tab-active-indicator-height: 3px;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${GTR.M} ${GTR.L};
  justify-content: space-between;
  margin: 0;
  color: ${COLOR.BLACK};
  font-size: ${FONT.SIZE.S};
  border: var(--border-width) solid ${COLOR.GREY.LIGHT};
  flex: 1 0 calc(25% - var(--border-width) * 2);
  text-align: left;
  overflow: hidden;

  &[aria-selected='true'] {
    &::after {
      background-color: ${COLOR.ACCENT.SECONDARY};
      border-radius: var(--tab-active-indicator-height);
      bottom: calc(-1 * var(--tab-active-indicator-height));
      content: '';
      display: block;
      height: calc(2 * var(--tab-active-indicator-height));
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  span {
    display: block;
    font-weight: ${FONT.WEIGHT.MEDIUM};
    font-size: ${FONT.SIZE.L};
    color: ${COLOR.ACCENT.SECONDARY};
  }
`

const StyledTabsWrapper = styled.div`
  --border-width: 1px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${COLOR.WHITE};

  > *:first-child {
    border-radius: ${BORDER_RADIUS.M} 0px 0px 0px;
  }

  > *:last-child {
    border-radius: 0px ${BORDER_RADIUS.M} 0px 0px;
  }
`

const ChartFrame = styled.div`
  display: flex;
  align-items: center;
  aspect-ratio: 888 / 431; // Size of chart plot plus padding
  border-radius: 0px 0px ${BORDER_RADIUS.M} ${BORDER_RADIUS.M};
  border: 1px solid ${COLOR.GREY.LIGHT};
  background: ${COLOR.WHITE};
  padding: 40px;
  margin-bottom: ${GTR.M};
`

type TabKey =
  | 'future_estimated_income'
  | 'wills_with_gifts'
  | 'wills_sold'
  | 'gir'
type Tab = {
  title: string
  key: TabKey
  value: string | null
}

const tableColumns: FramedTableColumn[] = [
  {
    label: 'Voucher Code',
    key: 'voucher_code',
  },
  {
    label: 'Future est. income',
    key: 'estimated_income',
    valueFormatter: formatCurrencyInPounds,
  },
  {
    label: 'Wills sold',
    key: 'wills_sold',
    valueFormatter: formatNumber,
  },
  {
    label: 'No. incl a gift',
    key: 'wills_with_gifts',
    valueFormatter: formatNumber,
  },
  {
    label: 'Gift inclusion rate',
    key: 'gift_inclusion_rate',
    valueFormatter: formatPercentage,
  },
]

export const Summary = (): React.ReactElement => {
  const [{ current }] = useContext(FilterContext)
  const { data, loading } = useContext(PerformanceContext)
  const willServiceType = useContext(ServiceTypeContext)
  const {
    estimated_income,
    wills_sold,
    wills_with_gifts,
    gift_inclusion_rate,
  } = data?.chart_data || {}
  const { online_voucher_code_uses, telephone_voucher_code_uses } =
    data?.overview || {}
  const totalWillsSold = wills_sold?.total || 0

  const tabs: Tab[] = [
    {
      title: 'Future est. income',
      key: 'future_estimated_income',
      value: isNumber(estimated_income?.total)
        ? formatCurrencyInPounds(estimated_income?.total)
        : null,
    },
    {
      title: 'Wills sold',
      key: 'wills_sold',
      value: formatNumber(totalWillsSold),
    },
    {
      title: 'No. including a gift',
      key: 'wills_with_gifts',
      value: isNumber(wills_with_gifts?.total)
        ? formatNumber(wills_with_gifts?.total)
        : null,
    },
    {
      title: 'Gift inclusion rate',
      key: 'gir',
      value: isNumber(gift_inclusion_rate?.total)
        ? formatPercentage(gift_inclusion_rate?.total)
        : null,
    },
  ]
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])

  let currentChartData: Omit<ChartData, 'total'> = {
    data: [{ x: new Date('2010-01-01'), y: 0 }],
    aggregation_level: 'day',
  }
  let currentChartYAxis: NumberType = 'integer'
  let valueLabel: string = 'Value'
  const chart_data = data?.chart_data
  const voucher_code_performance = data?.voucher_code_performance
  if (data) {
    switch (activeTab.key) {
      case 'future_estimated_income':
        currentChartData = chart_data.estimated_income
        currentChartYAxis = 'currency'
        valueLabel = 'Est. future income'
        break
      case 'wills_with_gifts':
        currentChartData = chart_data.wills_with_gifts
        currentChartYAxis = 'integer'
        valueLabel = 'No. including a gift'
        break
      case 'gir':
        currentChartData = chart_data.gift_inclusion_rate
        currentChartYAxis = 'percentage'
        valueLabel = 'Gift inclusion rate'
        break
      case 'wills_sold':
        currentChartData = chart_data.wills_sold
        currentChartYAxis = 'integer'
        valueLabel = 'Wills sold'
        break
      default:
        throw new Error('Unimplemented tab key!')
    }
  }
  const currentChartDataIsEmpty =
    currentChartData.data.filter(({ y }) => y !== 0).length === 0
  const { trafficTypeFilter } = current
  const formatFn = createFormatter(currentChartYAxis)
  return (
    <Wrapper separator>
      <SkeletonLoader loading={loading} inline>
        <H tag="h2" size="M">
          Summary
        </H>
      </SkeletonLoader>

      <SkeletonLoader loading={loading}>
        <StyledTabsWrapper role="tablist" id="chart-selector">
          {tabs
            .filter(
              (t) =>
                trafficTypeFilter === 'campaign' ||
                ['future_estimated_income', 'wills_with_gifts'].includes(t.key)
            )
            .map((t) => (
              <StyledTabWithStat
                isSelected={t.key === activeTab.key}
                title={t.title}
                value={t.value}
                key={t.key}
                onClick={() => setActiveTab(tabs.find((a) => a.key === t.key))}
              />
            ))}
        </StyledTabsWrapper>
      </SkeletonLoader>

      <SkeletonLoader loading={loading}>
        <ChartFrame>
          {currentChartDataIsEmpty ? (
            <Wrapper maxWidth={310} margin={['auto']}>
              <Image
                path="illustrations/cropped/tortoise-with-coins"
                alt="No chart data"
                width={200}
              />
              <P>
                As soon as a supporter redeems a will or pledges a gift, you’ll
                see it here
              </P>
            </Wrapper>
          ) : (
            <LineChart
              data={currentChartData.data.map((d) => ({
                ...d,
                x: new Date(d.x),
              }))}
              width={800}
              height={300}
              pointTitleFn={({ x, y, z }: Datum) =>
                `Date: ${x.toLocaleDateString()}\n${valueLabel}: ${formatFn(y)}${z ? `\nVoucher Code: ${z}` : ''}`
              }
              xAxisPeriod={currentChartData.aggregation_level}
              yValueType={currentChartYAxis}
              pointShape={currentChartData.data.length < 20 ? 'circle' : 'none'}
            />
          )}
        </ChartFrame>
      </SkeletonLoader>

      {voucher_code_performance && (
        <SkeletonLoader loading={loading}>
          <ScrollingWrapper minWidth={500}>
            <FramedTable
              data={voucher_code_performance as unknown as FramedTableData}
              columns={tableColumns}
            />
          </ScrollingWrapper>
        </SkeletonLoader>
      )}

      {willServiceType === 'all' && trafficTypeFilter === 'campaign' && (
        <GridItemWrapper spanFromM={12} loading={loading}>
          <H tag="h3" size="XS">
            Wills sold by service type
          </H>
          <PercentageChartWithTable
            items={[
              {
                title: 'Online wills',
                value: online_voucher_code_uses || 0,
                percentage: online_voucher_code_uses
                  ? formatPercentage(
                      online_voucher_code_uses / totalWillsSold,
                      1
                    )
                  : null,
                color: getGiftChartColor(
                  StatFormat.Percentage,
                  GiftChartCategory.Residuary
                ),
              },
              {
                title: 'Telephone wills',
                value: telephone_voucher_code_uses || 0,
                percentage: telephone_voucher_code_uses
                  ? formatPercentage(
                      telephone_voucher_code_uses / totalWillsSold,
                      1
                    )
                  : null,
                color: getGiftChartColor(
                  StatFormat.Percentage,
                  GiftChartCategory.Pecuniary
                ),
              },
            ]}
          />
        </GridItemWrapper>
      )}
    </Wrapper>
  )
}
