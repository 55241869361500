import React from 'react'
import { CampaignAssistantLayout } from 'layouts/CampaignGenerator'
import AccessModal from './components/AccessModal'

const Access = (): React.ReactElement => {
  return (
    <CampaignAssistantLayout>
      <AccessModal />
    </CampaignAssistantLayout>
  )
}

// React.lazy only supports default exports…
// eslint-disable-next-line import/no-default-export
export default Access
