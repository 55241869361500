import { DateTime } from 'luxon'

export const MINIMUM_DATE = '01/01/2016'

export const DATE_RANGE = {
  THIS_MONTH: 'This month',
  LAST_MONTH: 'Last month',
  THIS_QUARTER: 'This quarter',
  LAST_QUARTER: 'Last quarter',
  THIS_YEAR: 'This year',
  LAST_YEAR: 'Last year',
  LAST_12_MONTHS: 'Last 12 months',
} as const

export const DATE_RANGE_OPTIONS = Object.values(DATE_RANGE)

export const DEFAULT_DATE_RANGE_START_DATE = DateTime.utc()
  .minus({ months: 12 })
  .startOf('day')
  .toJSDate()
export const DEFAULT_DATE_RANGE_END_DATE = DateTime.utc()
  .startOf('day')
  .toJSDate()
