import React from 'react'
import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { range } from 'lib/helpers/array'
import { formatPercentage } from 'lib/formatting/numbers'

import { TooltipWrapper } from './Tooltip'

const StyledPercentageChart = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  border-right: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledDash = styled.div`
  grid-row: 1;
  border-left: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledChartWrapper = styled.ol`
  grid-row: 1;
  grid-column: 1 / 11;
  margin: ${GTR.XS} -1px ${GTR.XS} 0;
  display: flex;
  list-style: none;
  height: ${GTR.L};
  background: ${COLOR.GREY.LIGHT};
`

const StyledChartSlice = styled.li`
  position: relative;
  height: 100%;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 ${GTR.XXS} rgba(255, 255, 255, 0.4);
  }
`

interface Props {
  items: {
    label: string
    value: number
    color: string
  }[]
}

export const PercentageChart = ({ items }: Props): React.ReactElement => {
  const totalValues = items.reduce((prev, curr) => prev + curr.value, 0)

  return (
    <StyledPercentageChart>
      {range(10).map((i) => (
        <StyledDash key={i} style={{ gridColumn: i + 1 }} />
      ))}
      <StyledChartWrapper>
        {items.map(({ label, value, color }, i) =>
          !value ? null : (
            <TooltipWrapper
              key={label}
              as={StyledChartSlice}
              style={{
                flexBasis: `${(100 * value) / totalValues}%`,
                background: color,
              }}
              gravity={i === 0 ? 'left' : 'right'}
              label={label}
              value={formatPercentage(value / totalValues)}
              swatchColor={color}
            />
          )
        )}
      </StyledChartWrapper>
    </StyledPercentageChart>
  )
}
