import React from 'react'
import styled from 'styled-components'
import { BulletSeparator } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import {
  TooltipWrapper as CommonTooltipWrapper,
  TooltipGravity,
} from 'components/Tooltip'
import { Swatch } from 'components/stats/Swatch'

const StyledSwatch = styled(Swatch)`
  display: inline-block;
  margin: 0 ${GTR.XS} -1px 0;
`

const StyledLabel = styled.div`
  white-space: nowrap;
  color: ${COLOR.BLACK};

  * {
    display: inline-block;
  }
`

interface Props {
  label: string
  value: string | number
  swatchColor: string
  gravity: TooltipGravity
  as?: React.ElementType
  style?: React.CSSProperties
}

export const TooltipWrapper = ({
  label,
  value,
  swatchColor,
  gravity,
  as,
  style,
}: Props): React.ReactElement => {
  const tooltipContent = (
    <StyledLabel>
      <StyledSwatch color={swatchColor} />
      <BulletSeparator>
        <dt>{label}</dt>
        <dd>{value}</dd>
      </BulletSeparator>
    </StyledLabel>
  )

  return (
    <CommonTooltipWrapper
      as={as}
      style={style}
      key={label}
      gravity={gravity}
      tooltipId={label}
      tooltipContent={tooltipContent}
    />
  )
}
