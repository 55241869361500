import React, { useState } from 'react'
import styled from 'styled-components'
import * as d3 from 'd3'

import { useElementSize } from 'lib/hooks/useDimensions'

import { BAR_CHART_HEIGHT, BAR_CHART_MARGIN } from '../chart/constants'
import { BarAxes } from '../chart/BarAxes'
import { Legend } from '../chart/Legend'

type Datum = Record<string, string | number | null>
export type Data = Datum[]

interface Props {
  xLabelKey: string
  label: string
  dataKey: string
  color: string
  data: Data
}

const StyledWrapper = styled.figure`
  position: relative;
  margin: 0;
`

export const BarChart = ({
  xLabelKey,
  label,
  dataKey,
  color,
  data,
}: Props): React.ReactElement => {
  const [wrapperElement, { width: elementWidth }] = useElementSize()
  const [marginBottom, setMarginBottom] = useState<number>(
    BAR_CHART_MARGIN.bottom
  )

  const width = elementWidth
  const height = BAR_CHART_HEIGHT + marginBottom
  const margin = { ...BAR_CHART_MARGIN, bottom: marginBottom }
  const xLabels = data.map((d: Datum) => String(d[xLabelKey]))

  const xScale = d3
    .scaleBand()
    .domain(xLabels)
    .range([margin.left, width - margin.right])
    .padding(0.2)
  const xScaleBandWidth = xScale.bandwidth()

  const yMax = d3.max(data, (d) => d[dataKey] as number)
  const yScale = d3
    .scaleLinear()
    .domain([0, yMax || 1])
    .range([height - margin.bottom, margin.top])
    .nice()

  const xAxis = d3.axisBottom(xScale)
  const yAxis = d3.axisRight(yScale)

  const rects = data.map((d, i) => ({
    key: `${label}-${i}`,
    x: xScale(xLabels[i]),
    y: yScale(d[dataKey] as number),
    width: xScaleBandWidth,
    height: (yScale(0) || 0) - (yScale(d[dataKey] as number) || 0),
    fill: color,
  }))

  return (
    <StyledWrapper ref={wrapperElement}>
      {width && (
        <svg width={width} height={height}>
          <BarAxes
            xAxis={xAxis}
            yAxis={yAxis}
            chartMargin={margin}
            chartWidth={width}
            chartHeight={height}
            setXAxisLabelHeight={setMarginBottom}
          />

          {rects.map((bar) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <rect {...bar} key={bar.key} />
          ))}
        </svg>
      )}

      <Legend data={[{ label, color }]} />
    </StyledWrapper>
  )
}
