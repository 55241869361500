import React from 'react'
import styled from 'styled-components'
import { P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { WillService } from 'types'

const StyledList = styled.ol`
  list-style: decimal;
  padding-left: ${GTR.M};
`

interface Props {
  index: number
  willServiceType: WillService
}

export const TooltipContent = ({
  index,
  willServiceType,
}: Props): React.ReactElement => {
  let content: React.ReactElement[] = []

  if (index === 2) {
    content = [
      <P margin={['S', 0]}>
        The number of people who completed a will and included a primary gift to
        your charity.
      </P>,
    ]
  }
  if (willServiceType === 'all') {
    if (index === 0) {
      content = [
        <P margin={['S', 0]}>
          For telephone wills, we register a customer on their first call with
          the sales team. For online wills, we register a customer when they
          create an account through your campaign landing pages.
        </P>,
      ]
    }
    if (index === 1) {
      content = [
        <P margin={['S', 0]}>
          The number of people who registered and completed a will.
        </P>,
        <P>
          The time between the two steps may be longer for telephone will
          service compared to the online journey.
        </P>,
      ]
    }
  }
  if (willServiceType === 'online') {
    if (index === 0) {
      content = [
        <P margin={['S', 0]}>
          For online wills, we register a customer when they create an account
          through your campaign landing pages.
        </P>,
      ]
    }
    if (index === 1) {
      content = [
        <P margin={['S', 0]}>
          The number of people who registered and completed a will.
        </P>,
        <span>This may be lower than the number of wills sold because:</span>,
        <StyledList>
          <li>
            Here we only count people who register through your campaign landing
            pages
          </li>
          <li>
            A couples will counts as two wills sold, and one person might not
            have finished making their will yet.
          </li>
        </StyledList>,
      ]
    }
  }
  if (willServiceType === 'telephone') {
    if (index === 0) {
      content = [
        <P margin={['S', 0]}>
          For telephone wills, we register a customer on their first call with
          the sales team.
        </P>,
      ]
    }
    if (index === 1) {
      content = [
        <P margin={['S', 0]}>
          The number of people who registered and completed a will.
        </P>,
        <P>
          We aim to schedule the appointment within 5 days and send the will
          within 10 days after that. This can be delayed if customers reschedule
          appointments.
        </P>,
      ]
    }
  }
  return content.length ? (
    // eslint-disable-next-line react/no-array-index-key
    <>{content.map((element, i) => React.cloneElement(element, { key: i }))}</>
  ) : (
    <></>
  )
}
