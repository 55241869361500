import type { Tab } from './types'

export const TABS: Tab[] = [
  {
    label: '01 - Legacies email',
    key: 'first_email',
  },
  {
    label: '02 - Free will offer',
    key: 'second_email',
  },
  {
    label: '03 - Free will reminder',
    key: 'third_email',
  },
]

export const AUDIENCE = {
  SUPPORTERS: 'supporters',
  STAFF: 'staff',
} as const

export const AUDIENCE_OPTIONS = {
  [AUDIENCE.SUPPORTERS]: 'Our supporters',
  [AUDIENCE.STAFF]: 'Our staff',
}

export const EMAIL_TYPES = {
  FIRST: 'first_email',
  SECOND: 'second_email',
  THIRD: 'third_email',
} as const

export const AUDIENCE_DROPDOWN_OPTIONS = Object.entries(AUDIENCE_OPTIONS).map(
  ([value, label]) => ({
    value,
    label,
  })
)
