import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import {
  P,
  Wrapper,
  Checkbox,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Divider,
} from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { VoucherCodes } from 'types'
import { FilterContext } from 'store/filter'

const MAX_VOUCHER_CODES_BEFORE_SPLIT = 5

const splitCodes = ({
  showAllVoucherCodes,
  voucherCodes,
  startDate,
}: {
  showAllVoucherCodes: boolean
  voucherCodes: VoucherCodes
  startDate?: Date
}): {
  shownCodes: string[]
  hideCodesButtonText?: string
  showCodesButtonText?: string
} => {
  // If there is a date range set, we want to split codes by active vs inactive.
  if (startDate) {
    const activeCodes = voucherCodes
      .filter(({ status }) => status === 'active')
      .map(({ voucher_code }) => voucher_code)
    const inactiveCodes = voucherCodes
      .filter(({ status }) => status === 'inactive')
      .map(({ voucher_code }) => voucher_code)

    if (showAllVoucherCodes) {
      return {
        shownCodes: activeCodes.concat(inactiveCodes), // Ensure active codes are listed first
        hideCodesButtonText: `Hide inactive (${inactiveCodes.length})`,
      }
    }
    return {
      shownCodes: activeCodes,
      showCodesButtonText: inactiveCodes.length
        ? `Show inactive (${inactiveCodes.length})`
        : undefined,
    }
  }

  // If we're looking at data all time, we want to split codes when there are too many.
  const excessCodeCount = voucherCodes.length - MAX_VOUCHER_CODES_BEFORE_SPLIT

  if (showAllVoucherCodes) {
    return {
      shownCodes: voucherCodes.map(({ voucher_code }) => voucher_code),
      hideCodesButtonText:
        excessCodeCount > 0 ? `Hide (${excessCodeCount})` : undefined,
    }
  }
  return {
    shownCodes: voucherCodes
      .slice(0, MAX_VOUCHER_CODES_BEFORE_SPLIT)
      .map(({ voucher_code }) => voucher_code),
    showCodesButtonText:
      excessCodeCount > 0 ? `Show all (${excessCodeCount})` : undefined,
  }
}

const StyledLabel = styled.span`
  cursor: pointer;
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`

const StyledCheckbox = styled(Checkbox)`
  margin: ${GTR.XS} 0;
`

const StyledButton = styled(Button.Plain)`
  padding-left: 0;
`

export const VoucherCodeCheckboxList = ({
  voucherCodes,
}: {
  voucherCodes: VoucherCodes
}): React.ReactElement => {
  const [showAllVoucherCodes, setShowAllVoucherCodes] = useState(false)
  const [allChecked, setAllChecked] = useState(true)
  const [{ next }, { toggleVoucherCode, clearVoucherCodes }] =
    useContext(FilterContext)
  const { shownCodes, hideCodesButtonText, showCodesButtonText } = splitCodes({
    showAllVoucherCodes,
    voucherCodes,
    startDate: next.startDate,
  })

  return voucherCodes.length === 0 ? (
    <P margin={['XXS', 0, 'XXS', 20]}>No campaigns live</P>
  ) : (
    <Wrapper>
      <StyledCheckbox
        label="All codes"
        checked={allChecked && next.activeVoucherCodes.length === 0}
        onChange={() => {
          setAllChecked(!allChecked)
          clearVoucherCodes()
        }}
        size="S"
      />

      <Divider />

      {shownCodes.map((code) => {
        const onClick = () => toggleVoucherCode(code)
        const label = (
          <StyledLabel onClick={onClick} title={code}>
            {code}
          </StyledLabel>
        )

        return (
          <StyledCheckbox
            label={label}
            key={code}
            checked={next.activeVoucherCodes.includes(code)}
            onChange={onClick}
            size="S"
            disabled={allChecked && next.activeVoucherCodes.length === 0}
          />
        )
      })}

      {showCodesButtonText && (
        <StyledButton
          onClick={() => setShowAllVoucherCodes(true)}
          icon={ChevronDownIcon}
          iconOnLeft
        >
          {showCodesButtonText}
        </StyledButton>
      )}

      {hideCodesButtonText && (
        <StyledButton
          onClick={() => setShowAllVoucherCodes(false)}
          icon={ChevronUpIcon}
          iconOnLeft
        >
          {hideCodesButtonText}
        </StyledButton>
      )}
    </Wrapper>
  )
}
