import React from 'react'
import styled, { css } from 'styled-components'
import { GTR, COLOR, FONT } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'

import { Props } from './types'
import { getGroupedColumnsHeaderCells } from './helpers'

export type { Data, Column } from './types'

const TABLE_BORDER_COLOR = COLOR.GREY.LIGHT
const TABLE_BORDER_WIDTH = '1px'
const TABLE_ALT_ROW_BG = alpha(COLOR.GREY.LIGHT, 0.2)

enum TextAlign {
  Left,
  Right,
}

interface CellProps {
  $textAlign: TextAlign
  $flex: number
}

const cellStyle = css<CellProps>`
  ${({ $textAlign }: Pick<CellProps, '$textAlign'>) =>
    $textAlign === TextAlign.Left
      ? `
        text-align: left;
        align-items: flex-start;
      `
      : `
        text-align: right;
        align-items: flex-end;
      `}

  ${({ $flex }: Pick<CellProps, '$flex'>) => `
    flex-grow: ${$flex};
    /* assuring group labels line up when they span multiple columns */
    flex-basis: calc(${$flex - 1} * ${TABLE_BORDER_WIDTH});
  `}
  color: ${COLOR.BLACK};
`

const setDisplayOnIE11 = (display: string): string => `
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: ${display};
  }`

const StyledTable = styled.table`
  display: block;
  width: 100%;
  font-size: ${FONT.SIZE.S};
  font-variant-numeric: tabular-nums;
`

const StyledRow = styled.tr`
  display: flex;
  ${setDisplayOnIE11('table-row')}

  > * {
    border-width: 0 0 0 ${TABLE_BORDER_WIDTH};
    border-style: solid;
    border-color: ${TABLE_BORDER_COLOR};

    &:last-child {
      border-right-width: ${TABLE_BORDER_WIDTH};
    }
  }

  &:first-child > * {
    border-top-width: ${TABLE_BORDER_WIDTH};
  }

  &:last-child > * {
    border-bottom-width: ${TABLE_BORDER_WIDTH};
  }

  &:nth-child(odd) {
    background: ${TABLE_ALT_ROW_BG};
  }
`

const StyledCell = styled.td<CellProps>`
  display: flex;
  ${setDisplayOnIE11('table-cell')}

  flex: 1 0 0;
  flex-direction: column;
  justify-content: center;
  font-weight: ${FONT.WEIGHT.REGULAR};
  border-left: ${TABLE_BORDER_WIDTH} solid ${TABLE_BORDER_COLOR};
  padding: 0;

  ${cellStyle}
`

const StyledColumnHeaderRow = styled.tr`
  display: flex;
  ${setDisplayOnIE11('table-row')}
`

const StyledColumnHeader = styled.th<CellProps>`
  display: flex;
  ${setDisplayOnIE11('table-cell')}
  color: ${COLOR.BLACK};
  flex: 1 0 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  word-break: break-word;
  font-weight: ${FONT.WEIGHT.BOLD};
  border-width: ${TABLE_BORDER_WIDTH} 0 0 ${TABLE_BORDER_WIDTH};
  border-style: solid;
  border-color: ${TABLE_BORDER_COLOR};

  ${cellStyle}

  &:last-child {
    border-width: ${TABLE_BORDER_WIDTH} ${TABLE_BORDER_WIDTH} 0
      ${TABLE_BORDER_WIDTH};
  }

  &:empty {
    border-top-color: transparent;

    &:first-child {
      border-left-color: transparent;
    }

    &:last-child {
      border-right-color: transparent;
    }
  }
`

const StyledCellContent = styled.div`
  padding: ${GTR.XS} ${GTR.S};
`

export const FramedTable = ({
  data,
  columns,
  groupedColumns,
}: Props): React.ReactElement => {
  const groupedColumnsHeaderCells = getGroupedColumnsHeaderCells(
    columns,
    groupedColumns
  )

  return (
    <StyledTable>
      <thead style={{ display: 'block' }}>
        {groupedColumnsHeaderCells && (
          <StyledColumnHeaderRow>
            {groupedColumnsHeaderCells.map(({ label, flex }, i) => (
              <StyledColumnHeader
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                scope="col"
                $textAlign={TextAlign.Left}
                $flex={flex}
              >
                {label && <StyledCellContent>{label}</StyledCellContent>}
              </StyledColumnHeader>
            ))}
          </StyledColumnHeaderRow>
        )}

        <StyledColumnHeaderRow>
          {columns.map(({ label, key, flex }) => (
            <StyledColumnHeader
              key={key}
              scope="col"
              $textAlign={TextAlign.Left}
              $flex={flex || 1}
            >
              {label && <StyledCellContent>{label}</StyledCellContent>}
            </StyledColumnHeader>
          ))}
        </StyledColumnHeaderRow>
      </thead>

      <tbody style={{ display: 'block' }}>
        {data.map((row) => (
          <StyledRow key={row[columns[0].key] as string}>
            {columns.map(({ key, valueFormatter }, i) => {
              const column = columns[i]
              let value = row[key]
              if (valueFormatter && typeof value === 'number')
                value = valueFormatter(value)
              if (value === null) value = '-'

              return (
                <StyledCell
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  key={`${row[column.key]}-${key}`}
                  as={i === 0 ? 'th' : 'td'}
                  $textAlign={TextAlign.Left}
                  $flex={column.flex || 1}
                >
                  <StyledCellContent>{value}</StyledCellContent>
                </StyledCell>
              )
            })}
          </StyledRow>
        ))}
      </tbody>
    </StyledTable>
  )
}
