import { UserConfig } from 'types'

export enum ActionType {
  SetPartnerData = 'SET_PARTNER_DATA',
  SetMasqueradePartnerId = 'SET_MASQUERADE_PARTNER_ID',
}

export interface State {
  canDownloadPartnerDataSharingReports: boolean
  partner: UserConfig['activePartner'] | null
  availablePartners: UserConfig['partners'] | []
  masqueradePartnerId?: string
}

export const initialState: State = {
  canDownloadPartnerDataSharingReports: false,
  partner: null,
  availablePartners: [],
  masqueradePartnerId: window.localStorage.getItem('masqueradePartnerId'),
}
