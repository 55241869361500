/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Button } from '@farewill/ui'

import { CopyIcon } from '../CopyIcon'

export type CopyButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  onCopy?: () => void
  sourceElement: React.RefObject<HTMLElement>
  wide?: boolean
}

const StyledChildrenWrapper = styled.span`
  opacity: var(--opacity, 1);
`

/**
 * Stack the children on top of each other (in the z-axis) so that the button
 * is always as long as the longest child. This trick, combined with the fact
 * that we keep the normal button copy in the DOM (and make it invisible via
 * by setting opacity to 0), allows us to keep the button width consistent while
 * we show the copied message.
 */
const StyledButtonContent = styled.div`
  display: grid;
  place-content: center;

  > * {
    grid-area: 1 / 1;
  }
`

const COPY_MESSAGE_DURATION = 2000

export const CopyButton = ({
  children,
  disabled,
  onCopy,
  sourceElement,
  ...props
}: CopyButtonProps): React.ReactElement => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)
  const [copyResult, setCopyResult] = useState('Copied!')
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    // Cleanup timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Button.Secondary
      disabled={disabled}
      icon={CopyIcon}
      iconOnLeft
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        /**
         * Copy both plaintext and HTML versions of the content to the
         * clipboard. If you need to see what we've copied, paste into
         * Clipboard Inspector[1].
         *
         * [1] https://evercoder.github.io/clipboard-inspector
         */
        const data = [
          new ClipboardItem({
            'text/plain': new Blob(
              /*
               * We can't obtain the plaintext version by using innerText as
               * this does not work well cross-browser (it results in an empty
               * string in Safari). Instead, we store the plaintext content on
               * the sourceElement in a data attribute and just read it from
               * there directly.
               */
              [sourceElement.current.dataset.plaintextContent],
              {
                type: 'text/plain',
              }
            ),
            'text/html': new Blob([sourceElement.current.innerHTML], {
              type: 'text/html',
            }),
          }),
        ]

        try {
          await navigator.clipboard.write(data)
          setCopyResult('Copied!')
          onCopy?.()
        } catch (err) {
          setCopyResult('Failed to copy!')
        } finally {
          setShowCopiedMessage(true)
          timeoutRef.current = setTimeout(
            () => setShowCopiedMessage(false),
            COPY_MESSAGE_DURATION
            /**
             * FIXME: Adjust tsconfig so frontend source files are not compiled
             */
          ) as unknown as number
        }
      }}
      {...props}
    >
      <StyledButtonContent>
        <StyledChildrenWrapper
          aria-hidden={showCopiedMessage}
          style={
            {
              '--opacity': showCopiedMessage ? 0 : 1,
            } as React.CSSProperties
          }
        >
          {children}
        </StyledChildrenWrapper>
        <span {...(!showCopiedMessage ? { hidden: true } : {})}>
          {copyResult}
        </span>
      </StyledButtonContent>
    </Button.Secondary>
  )
}
