import axios, { type AxiosRequestHeaders } from 'axios'

import ENV from 'config'
import { getAccessToken } from 'lib/auth0/storage'
import { AuthState } from '../hooks/useAuth'

export const client = axios.create({
  baseURL: `${ENV.BACKSTAGE_URL}/api/spotlight`,
})

client.interceptors.request.use(async (config) => {
  let accessToken: string

  if (window.Cypress) {
    /*
     * If we're running under Cypress the cy.login command will have
     * authenticated with the Auth0 API and put the auth state in
     * localStorage, so we use the token from there instead of calling the
     * Auth0 API to obtain the authorization token for the request.
     */
    const authState = JSON.parse(
      localStorage.getItem('auth0Cypress')
    ) as AuthState
    accessToken = authState?.token
  } else {
    accessToken = await getAccessToken({
      authorizationParams: {
        audience: ENV.AUTH0_AUDIENCE,
        scope: 'read:current_user',
      },
    })
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    } as AxiosRequestHeaders,
  }
})
