import React, { useContext } from 'react'
import styled from 'styled-components'
import { H, Button, Divider } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'

import { FilterContext } from 'store/filter'
import { hasFilters } from 'store/filter/selectors'

const StyledFiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledTitle = styled(H)`
  line-height: 1.5;
`

const StyledClearButton = styled(Button.Plain)`
  color: ${COLOR.GREY.MEDIUM};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

export const FiltersHeader = (): React.ReactElement => {
  const [state, { clearFilters }] = useContext(FilterContext)
  const showResetButton = hasFilters(state)

  return (
    <>
      <StyledFiltersHeader>
        <StyledTitle tag="h2" size="XS" margin={0}>
          Filters
        </StyledTitle>
        {showResetButton && (
          <StyledClearButton flush onClick={clearFilters}>
            Clear
          </StyledClearButton>
        )}
      </StyledFiltersHeader>
      <Divider margin={['S', 0]} />
    </>
  )
}
