import React from 'react'
import { DropdownList as Dropdown } from 'react-widgets'
import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

import 'react-widgets/dist/css/react-widgets.css'

const StyledDropdownList = styled(Dropdown)`
  max-width: 312px;
  margin-bottom: ${GTR.S};

  &.rw-state-focus {
    .rw-widget-container {
      border-color: ${COLOR.GREY.MEDIUM};
      box-shadow: none;
    }
  }

  .rw-dropdown-list-input {
    color: ${COLOR.BLACK};
    padding: ${GTR.S};
  }

  .rw-list-option {
    color: ${COLOR.BLACK};
    padding: ${GTR.XS};
  }

  .rw-state-focus {
    color: ${COLOR.WHITE};
    background-color: ${COLOR.ACCENT.SECONDARY};
    border-color: ${COLOR.ACCENT.SECONDARY};
    &:hover,
    &:active {
      background-color: ${COLOR.ACCENT.SECONDARY};
      border-color: ${COLOR.ACCENT.SECONDARY};
    }
  }
`

type DropdownListProps = React.ComponentProps<typeof Dropdown>

export const DropdownList = ({
  defaultValue,
  data,
  onChange,
  ...props
}: DropdownListProps): React.ReactElement => {
  return (
    <>
      <StyledDropdownList
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultValue={defaultValue}
        data={data}
        onChange={onChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  )
}
