import React from 'react'
import styled from 'styled-components'

import momentLocalizer from 'react-widgets-moment'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import 'react-widgets/dist/css/react-widgets.css'

import { DATE_FORMAT } from 'lib/formatting/time'

momentLocalizer()

const StyledLabel = styled.label`
  margin-bottom: ${GTR.XXS};
`

const StyledDateTimePicker = styled(DateTimePicker)<{
  $error: boolean
}>`
  max-width: 312px;
  margin-bottom: ${GTR.S};

  &.rw-state-focus {
    .rw-widget-container {
      border-color: ${COLOR.GREY.MEDIUM};
      box-shadow: none;
    }
  }

  .rw-widget-picker {
    width: 100%;
    border: 1px solid
      ${({ $error }) => ($error ? COLOR.STATE.ERROR : COLOR.GREY.LIGHT)};
    transition: border-color 0.2s ease-in-out;
  }

  .rw-widget-input {
    color: ${COLOR.BLACK};
    padding: ${GTR.S};
  }

  .rw-btn-select {
    color: ${COLOR.GREY.DARK};
    opacity: 1;
    transform: translateY(-3px);

    &:hover {
      background-color: ${COLOR.BACKGROUND.FOG};
    }
  }

  .rw-select-bordered {
    border-left: 1px solid ${COLOR.GREY.LIGHT};

    &:hover {
      background-color: transparent;
    }

    &:active {
      box-shadow: none;
      background-color: transparent;
    }
  }

  .rw-popup {
    border-width: 1px;
    margin-top: -1px;
  }

  .rw-cell {
    &.rw-state-selected,
    &.rw-state-selected:hover {
      background-color: ${COLOR.ACCENT.SECONDARY};
      border-color: ${COLOR.ACCENT.SECONDARY};
    }
  }

  .rw-list-option {
    &.rw-state-focus,
    &.rw-state-focus:hover {
      border-color: ${COLOR.ACCENT.SECONDARY};
    }
  }
`

const StyledErrorMessage = styled(P)`
  font-size: ${GTR.S};
  color: ${COLOR.STATE.ERROR};
`

interface Props {
  value?: Date
  onChange: (newValue?: Date) => void
  label: string
  min?: Date
  max?: Date
  errorMessage?: React.ReactNode
}

export const DatePicker = ({
  value,
  onChange,
  label,
  min,
  max,
  errorMessage,
}: Props): React.ReactElement => {
  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledDateTimePicker
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        time={false}
        format={DATE_FORMAT}
        $error={!!errorMessage}
      />
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </>
  )
}
