import React from 'react'

export const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
    <path
      fill="#fff"
      d="M9.42 18c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 7.42 16V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 9.42 2h9c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413v12c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 18.42 18h-9Zm0-2h9V4h-9v12Zm-4 6c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3.42 20V6h2v14h11v2h-11Z"
    />
  </svg>
)
