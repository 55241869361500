import { useContext } from 'react'

import { FilterContext } from 'store/filter'
import { useQueryParamSync } from 'lib/hooks/useQueryParamSync'
import { getISODateString } from 'lib/formatting/time'

export const useFilterQueryParamSync = (): [boolean] => {
  const [
    { current },
    {
      applyFilters,
      setTrafficTypeFilter,
      setVoucherCodes,
      setStartDate,
      setEndDate,
      setInheritancePrecedence,
    },
  ] = useContext(FilterContext)

  return useQueryParamSync(
    [
      {
        key: 'traffic',
        value: current.trafficTypeFilter,
        defaultValue: 'all',
        isArray: false,
        setter: setTrafficTypeFilter,
      },
      {
        key: 'codes',
        value: current.activeVoucherCodes,
        defaultValue: [],
        isArray: true,
        setter: setVoucherCodes,
      },
      {
        key: 'start',
        value: current.startDate ? getISODateString(current.startDate) : null,
        defaultValue: null,
        isArray: false,
        setter: (startDate) =>
          setStartDate(startDate ? new Date(startDate) : null),
      },
      {
        key: 'end',
        value: current.endDate ? getISODateString(current.endDate) : null,
        defaultValue: null,
        isArray: false,
        setter: (endDate) => setEndDate(endDate ? new Date(endDate) : null),
      },
      {
        key: 'inheritancePrecedence',
        value: current.inheritancePrecedence,
        defaultValue: ['primary'],
        isArray: true,
        setter: setInheritancePrecedence,
      },
    ],
    {
      setterCallback: applyFilters,
    }
  )
}
