/* eslint-disable react/no-unescaped-entities */
import * as Sentry from '@sentry/browser'
import React, { useContext, useEffect, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Button, Checkbox, H, Image, P } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import ENV from 'config'
import { client as axios } from 'lib/axios/apiClient'
import { Routes } from 'lib/constants'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/loader/Spinner'
import { RETURN_TO_PARM_NAME } from 'routes/Assistant/constants'
import { GlobalContext } from 'store/global'

import { UpdateConsentRequest, UpdateConsentResponse } from './types'

const ILLUSTRATION_WIDTH = 280

const StyledLogo = styled.img`
  display: inline-block;
  margin: 0 auto;
`

const StyledImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledIllustration = styled(Image)`
  width: 100%;
`

const StyledIllustrationContainer = styled.div`
  aspect-ratio: 320 / 240;
  max-width: ${ILLUSTRATION_WIDTH}px;
  width: 100%;
`

const StyledError = styled.p`
  color: ${COLOR.STATE.ERROR};
  font-size: ${FONT.SIZE.XS};
  margin: 6px 0 ${GTR.M};
`

const StyledForm = styled.form`
  position: relative;
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
`

const StyledSpinnerContainer = styled.div`
  align-items: center;
  animation: ${({ 'aria-hidden': ariaHidden }) =>
    ariaHidden === 'true'
      ? 'none'
      : css`
          ${fadeOut} 300ms forwards 350ms ease-out
        `};
  background-color: ${COLOR.WHITE};
  display: flex;
  justify-content: center;
  position: absolute;
  inset: calc(-1 * var(--dialog-padding));
  z-index: 10;
`

const TermsModal = (): React.ReactElement => {
  const [
    {
      partner: { id: partnerId },
    },
  ] = useContext(GlobalContext)
  const [open, setOpen] = React.useState(true)
  const [errorRecodingConsent, setErrorRecordingConsent] = React.useState(false)
  const [isValid, setIsValid] = React.useState(true)
  const [isDirty, setIsDirty] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const checkboxRef = useRef<HTMLInputElement>(null)

  const showValidationError = !isDirty && !isValid
  const fetchingPartnerId = !partnerId

  useEffect(() => {
    if (!fetchingPartnerId) {
      checkboxRef.current?.focus()
    }
  }, [fetchingPartnerId])

  return (
    <>
      <Modal open={open}>
        <Modal.Content
          showHeader={false}
          title="Farewill Marketing Assistant Terms and Conditions"
          onOpenAutoFocus={(e: CustomEvent) => {
            /*
             * Since we're showing the spinner we need to delay the auto-focus
             * of the modal content and handle it ourselves once we reveal the
             * form.
             */
            e.preventDefault()
          }}
        >
          <StyledForm
            aria-busy={fetchingPartnerId}
            aria-label="terms-form"
            noValidate
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={async (e) => {
              e.preventDefault()
              const form = e.target as HTMLFormElement
              const valid = form.checkValidity()
              setErrorRecordingConsent(false)
              setIsDirty(false)
              setIsValid(valid)

              if (!valid) {
                return
              }

              setSubmitting(true)

              try {
                await axios.request<
                  UpdateConsentRequest,
                  UpdateConsentResponse
                >({
                  method: 'POST',
                  data: {
                    consentType: 'ACCEPTED',
                    partnerId,
                  },
                  url: `${ENV.BACKSTAGE_URL}/api/terms-and-conditions-consents/AI_MARKETING_BOT/1.0`,
                })

                const returnPath =
                  new URLSearchParams(window.location.search).get(
                    RETURN_TO_PARM_NAME
                  ) || Routes.GenerateEmails

                // Close modal and navigate.
                setOpen(false)
                setTimeout(() => {
                  /*
                   * TODO: A future improvement would involve avoiding a full page reload here.
                   * We're doing this at the moment to work around difficulties in refetching the
                   * charity data (which is initially fetched by the useFetchCharity hook) which
                   * needs to be re-fetched after the user has accepted the terms to prevent us
                   * from re-showing the terms modal because we check the consent status on the
                   * charity data object.
                   */
                  document.location = `${document.location.origin}${returnPath}`
                }, 300)
              } catch (error) {
                Sentry.captureException(error)
                setErrorRecordingConsent(true)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            <StyledSpinnerContainer aria-hidden={!fetchingPartnerId}>
              <Spinner size={40} />
            </StyledSpinnerContainer>
            <div aria-hidden={fetchingPartnerId}>
              <StyledImageContainer>
                <StyledIllustrationContainer>
                  <StyledIllustration
                    path="illustrations/blob-laptop-continue"
                    alt=""
                    width={ILLUSTRATION_WIDTH}
                  />
                </StyledIllustrationContainer>
                <StyledLogo
                  src="/images/logo-wordmark.svg"
                  height={35}
                  alt="Farewill logo"
                />
              </StyledImageContainer>
              <H centered size="M">
                Marketing Assistant
              </H>
              <P>
                We’ve created a tool that takes our knowledge of high-performing
                legacy campaigns, and uses AI to generate fresh new campaigns
                for you.
              </P>
              <P>
                We hope this helps you deliver great campaigns in less time, and
                improve engagement and gift inclusion rates.
              </P>
              <Checkbox
                aria-invalid={showValidationError}
                aria-describedby={
                  showValidationError ? 'accept-validation-error' : undefined
                }
                error={showValidationError}
                id="terms-checkbox"
                label={
                  <label htmlFor="terms-checkbox">
                    I have read and accept the{' '}
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      href={`${ENV.MARKETING_SITE_URL}/wills/marketing-assistant-terms`}
                      target="_blank"
                      onClick={(e) => {
                        /*
                         * Prevent clicks on the link from changing the checked
                         * state of the checkbox.
                         */
                        e.stopPropagation()
                      }}
                    >
                      Farewill Marketing Assistant terms
                    </a>
                    .
                  </label>
                }
                onChange={() => {
                  setIsDirty(true)
                }}
                ref={checkboxRef}
                required
              />
              <div aria-live="polite">
                {showValidationError && (
                  <StyledError id="accept-validation-error">
                    You must accept the terms to use the Farewill Marketing
                    Assistant.
                  </StyledError>
                )}

                {errorRecodingConsent && (
                  <StyledError>
                    We were unable to record your consent. Please check your
                    connection and try again. If this keeps happening, contact
                    us at{' '}
                    <a href="mailto:partnerships@farewill.com">
                      partnerships@farewill.com
                    </a>
                    .
                  </StyledError>
                )}
              </div>

              <Button.Primary stretch loading={submitting}>
                Generate your first campaign
              </Button.Primary>
            </div>
          </StyledForm>
        </Modal.Content>
      </Modal>
    </>
  )
}

// React.lazy only supports default exports…
// eslint-disable-next-line import/no-default-export
export default TermsModal
