import React, { useCallback, createContext, useReducer } from 'react'

import { Traffic, InheritancePrecedence } from 'types'
import { State, initialState, ActionType } from './constants'
import * as reducers from './reducers'
import { applyFilters } from './ducks/applyFilters'
import {
  clearFilters,
  clearDates,
  clearVoucherCodes,
} from './ducks/clearFilters'
import { setStartDate, setEndDate } from './ducks/dateRange'
import { setInheritancePrecedence } from './ducks/inheritancePrecedenceFilter'
import { setTrafficTypeFilter } from './ducks/trafficTypeFilter'
import { toggleVoucherCode, setVoucherCodes } from './ducks/voucherCodes'

interface Action {
  type: ActionType
  payload: unknown
}

const combinedReducers: React.Reducer<State, Action> = (state, action) =>
  Object.values(reducers).reduce(
    (prevState, reducer: React.Reducer<State, unknown>) =>
      reducer(prevState, action),
    state
  )
type Actions = {
  readonly applyFilters: () => void
  readonly clearFilters: () => void
  readonly clearDates: () => void
  readonly clearVoucherCodes: () => void
  readonly toggleVoucherCode: (payload: string) => void
  readonly setVoucherCodes: (payload: string[]) => void
  readonly setStartDate: (payload: Date) => void
  readonly setEndDate: (payload: Date) => void
  readonly setTrafficTypeFilter: (payload: Traffic) => void
  readonly setInheritancePrecedence: (payload: InheritancePrecedence[]) => void
}

type Context = [State, Actions]
export const FilterContext = createContext([{}, {}] as Context)

export const withFilterProvider =
  (Component: () => React.ReactElement) =>
  (props: Record<string, unknown>): React.ReactElement => {
    const [state, dispatch] = useReducer(combinedReducers, initialState)

    /* eslint-disable react-hooks/exhaustive-deps */
    const actions: Actions = {
      applyFilters: useCallback(applyFilters(dispatch), [dispatch]),
      clearFilters: useCallback(clearFilters(dispatch), [dispatch]),
      clearDates: useCallback(clearDates(dispatch), [dispatch]),
      clearVoucherCodes: useCallback(clearVoucherCodes(dispatch), [dispatch]),
      toggleVoucherCode: useCallback(toggleVoucherCode(dispatch), [dispatch]),
      setVoucherCodes: useCallback(setVoucherCodes(dispatch), [dispatch]),
      setStartDate: useCallback(setStartDate(dispatch), [dispatch]),
      setEndDate: useCallback(setEndDate(dispatch), [dispatch]),
      setTrafficTypeFilter: useCallback(setTrafficTypeFilter(dispatch), [
        dispatch,
      ]),
      setInheritancePrecedence: useCallback(
        setInheritancePrecedence(dispatch),
        [dispatch]
      ),
    }

    /* eslint-disable react/jsx-props-no-spreading */
    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <FilterContext.Provider value={[state, actions]}>
        <Component {...props} />
      </FilterContext.Provider>
    )
  }
