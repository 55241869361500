/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import { Button, H, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { Spinner } from 'components/loader/Spinner'

import { SkeletonLoader } from 'components/loader/SkeletonLoader'

const StyledPlaceholderBlock = styled(Wrapper)`
  height: var(--height, 1lh);
`

const SPINNER_SIZE = 40

const StyledContentWrapper = styled(Wrapper)`
  display: grid;
  position: relative;
  width: 100%;

  // Stack content in z space (so we can display error on top of skeleton
  // loader w/o using absolute positioning).
  > * {
    grid-area: 1 / 1;
  }
`

const StyledLoadingMessage = styled(Wrapper)`
  position: absolute;
  left: 50%;
  top: calc(50% - ${SPINNER_SIZE / 2}px);
  transform: translate(-50%, -50%);
  z-index: 1;
`

const StyledLoadingMessageTitle = styled(H)`
  font-weight: 500;
`

const StyledSpinner = styled(Spinner)`
  margin: 0 auto;
`

const StyledSkeletonWrapper = styled(Wrapper)`
  opacity: var(--skeleton-opacity, 1);
  transition: opacity 0.3s ease-in-out;
`

const StyledErrorMessage = styled.div`
  margin: auto ${GTR.L};
  position: relative;
  text-align: center;
  z-index: 1;
`

/**
 * [1] Work around the fact that we can't retrieve the actual type using
 *     WrapperProps['margin'] because of the [key: string]: any; index signature
 *     in the type definition of WrapperProps…
 */
type PlaceholderBlockProps = {
  lines?: number
  margin?: unknown // [1]
}

const PlaceholderBlock = ({
  lines = 1,
  margin = [0, 0, GTR.S],
  ...props
}: PlaceholderBlockProps) => {
  const height = `${lines}lh`
  return (
    <StyledPlaceholderBlock
      margin={margin}
      style={{ '--height': height }}
      {...props}
    >
      <P>&nbsp;</P>
    </StyledPlaceholderBlock>
  )
}

type PlaceholderContentProps = {
  error?: string
  onRetry: () => void
}

export const PlaceholderContent = ({
  error,
  onRetry,
}: PlaceholderContentProps): React.ReactElement => (
  <StyledContentWrapper noTrim>
    <StyledSkeletonWrapper
      style={
        {
          '--skeleton-opacity': error ? 0.3 : 1,
        } as React.CSSProperties
      }
    >
      <SkeletonLoader animate={!error} loading inline width={200}>
        <PlaceholderBlock lines={1} />
      </SkeletonLoader>
      <SkeletonLoader animate={!error} loading>
        <PlaceholderBlock lines={2} />
      </SkeletonLoader>
      <SkeletonLoader animate={!error} loading>
        <PlaceholderBlock lines={3} />
      </SkeletonLoader>
      <SkeletonLoader animate={!error} loading>
        <PlaceholderBlock lines={2} />
      </SkeletonLoader>
      <SkeletonLoader animate={!error} loading inline width={176}>
        <PlaceholderBlock lines={1} />
      </SkeletonLoader>
      <SkeletonLoader animate={!error} loading inline width={100}>
        <PlaceholderBlock lines={1} margin={[0, 0, 0]} />
      </SkeletonLoader>
      <SkeletonLoader animate={!error} loading inline width={78}>
        <PlaceholderBlock lines={1} />
      </SkeletonLoader>
    </StyledSkeletonWrapper>
    {error ? (
      <StyledErrorMessage>
        <P strong>We’re having trouble generating your email</P>
        <P margin={[0, 0, GTR.M]}>
          Sorry about that. If this keeps happening, let us know:{' '}
          <a href="mailto:partnerships@farewill.com">
            partnerships@farewill.com
          </a>
          .
        </P>
        <Button.Primary
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            onRetry()
          }}
          type="button"
        >
          Retry
        </Button.Primary>
      </StyledErrorMessage>
    ) : (
      <StyledLoadingMessage centered>
        <StyledSpinner size={SPINNER_SIZE} />
        <StyledLoadingMessageTitle size="S" margin={[GTR.S, 0, 0]} tag="h3">
          Generating your emails
        </StyledLoadingMessageTitle>
        <P>This may take up to a minute</P>
      </StyledLoadingMessage>
    )}
  </StyledContentWrapper>
)
