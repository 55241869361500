import styled from 'styled-components'
import { GTR } from '@farewill/ui/tokens'

export const BulletedList = styled.ul`
  list-style: disc;
  padding-left: ${GTR.M};

  li:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }
`
