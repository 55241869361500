import { Auth0Provider, AppState, useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { deferred } from 'lib/auth0/storage'
import ENV from './config'

const Auth0InnerWrapper = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  /*
   * Store a reference to the `getAccessTokenSilently` method so that we can
   * call it from outside React components.
   * See https://github.com/auth0/auth0-react/issues/67
   */
  deferred.resolve(getAccessTokenSilently)

  return <>{children}</>
}

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={ENV.AUTH0_CUSTOM_DOMAIN}
      clientId={ENV.AUTH0_DASHBOARD_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: ENV.AUTH0_AUDIENCE,
        scope: 'read:current_user',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0InnerWrapper>{children}</Auth0InnerWrapper>
    </Auth0Provider>
  )
}
