import React from 'react'
import styled from 'styled-components'
import { Button, H, Image, P, Wrapper } from '@farewill/ui'

import { Modal } from 'components/Modal'

const ILLUSTRATION_WIDTH = 280

const StyledLogo = styled.img`
  display: inline-block;
  margin: 0 auto;
`

const StyledImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledIllustration = styled(Image)`
  width: 100%;
`

const StyledIllustrationContainer = styled.div`
  aspect-ratio: 320 / 240;
  max-width: ${ILLUSTRATION_WIDTH}px;
  width: 100%;
`

const AccessModal = (): React.ReactElement => {
  return (
    <Modal open>
      <Modal.Content showHeader={false} title="Farewill Marketing Assistant">
        <Wrapper>
          <StyledImageContainer>
            <StyledIllustrationContainer>
              <StyledIllustration
                path="illustrations/Sweat_The_Details"
                alt=""
                width={ILLUSTRATION_WIDTH}
              />
            </StyledIllustrationContainer>
            <StyledLogo
              src="/images/logo-wordmark.svg"
              height={35}
              alt="Farewill logo"
            />
          </StyledImageContainer>
          <H centered size="M">
            Marketing Assistant
          </H>
          <P>
            Welcome! You’ve found our new AI-powered tool that can help you
            create impactful legacy marketing campaigns.
          </P>
          <P>
            However, we’ll need to set you up with access first. Contact us at{' '}
            <a href="mailto:charities@farewill.com">charities@farewill.com</a>{' '}
            to get started.
          </P>

          <Button.Primary stretch href="/">
            Back to your dashboard
          </Button.Primary>
        </Wrapper>
      </Modal.Content>
    </Modal>
  )
}

// React.lazy only supports default exports…
// eslint-disable-next-line import/no-default-export
export default AccessModal
