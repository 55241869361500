import React, { useContext } from 'react'
import styled from 'styled-components'
import { InfoCircleIcon, P } from '@farewill/ui'
import { COLOR, GTR, BORDER } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import { paragraphS, headingS } from '@farewill/ui/helpers/text'

import { formatNumber } from 'lib/formatting/numbers'
import { TooltipGravity, TooltipWrapper } from 'components/Tooltip'
import { ServiceTypeContext } from 'routes/context'
import { TooltipContent } from './TooltipContent'

export const STEP_PADDING = '90px'
const TRAPEZOID_SHIFT = '2px'
const TRAPEZOID_HEIGHT_REDUCTION_PERCENTAGE_PER_STEP = 24

const StyledTrapezoid = styled.div<{ $index: number }>`
  position: relative;
  ${paragraphS};
  padding: ${GTR.XS};
  margin: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: calc(
      100% - ${({ $index }): number => $index} * ${GTR.XL} - ${GTR.M}
    );
    height: ${({ $index }) => 90 - $index * 20}%;
    box-sizing: border-box;
    background: ${COLOR.WHITE};
    border: 1px solid ${COLOR.GREY.LIGHT};
    border-radius: ${BORDER.RADIUS.S};
    transform-origin: 50% 100%;
  }

  ${screenMax.m`
    &:before,
    &:after {
      height: 100%;
    }

    &:before {
      transform: translate(-50%, 0) skewX(10deg);
      margin-left: -${TRAPEZOID_SHIFT};
    }

    &:after {
      transform: translate(-50%, 0) skewX(-10deg);
      margin-left: ${TRAPEZOID_SHIFT};
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `}

  ${screenMin.l`
    padding: ${STEP_PADDING} ${GTR.XXS} 0;

    /* 
       use first trapezoid as the marker for height
       so text can wrap on others without effecting size
    */
    &:first-child {
      padding-bottom: ${STEP_PADDING};
    }

    &:before,
    &:after {
      top: 50%;
      left: 0;
      width: 100%;
      transform-origin: 100% 50%;
    }

    &:before {
      transform: translate(0, -50%) skewY(4deg);
      margin-top: -${TRAPEZOID_SHIFT};
    }

    &:after {
      transform: translate(0, -50%) skewY(-4deg);
      margin-top: ${TRAPEZOID_SHIFT};
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  `}
`

const StyledValue = styled.span`
  ${headingS};
  display: block;
  margin: 0;
`

interface Props {
  value: number
  label: string
  index: number
}

export const Step = ({ value, label, index }: Props): React.ReactElement => {
  const willServiceType = useContext(ServiceTypeContext)
  const gravityByIndex: TooltipGravity[] = ['left', 'center', 'right']

  return (
    <TooltipWrapper
      tooltipId={label}
      tooltipContent={
        <TooltipContent index={index} willServiceType={willServiceType} />
      }
      style={{ borderRadius: BORDER.RADIUS.S, width: '100%' }}
      position="below"
      positionModifier={
        (index * TRAPEZOID_HEIGHT_REDUCTION_PERCENTAGE_PER_STEP) / 2
      }
      wide
      gravity={gravityByIndex[index]}
    >
      <StyledTrapezoid $index={index}>
        <StyledValue>{formatNumber(value)}</StyledValue>
        <P margin={0}>{label}</P>
        <InfoCircleIcon color={COLOR.GREY.MEDIUM} />
      </StyledTrapezoid>
    </TooltipWrapper>
  )
}
