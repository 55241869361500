import React from 'react'

import { State, ActionType } from '../constants'

type Action = SetVoucherCodesAction | ToggleVoucherCodeAction

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.ToggleVoucherCode: {
      const { activeVoucherCodes } = state.next
      const { payload } = action
      const checked = activeVoucherCodes.includes(payload)

      if (!checked) {
        return {
          ...state,
          next: {
            ...state.next,
            activeVoucherCodes: [...activeVoucherCodes, payload],
          },
        }
      }

      const copiedVoucherCodes = [...activeVoucherCodes]
      const activeIndex = copiedVoucherCodes.indexOf(payload)
      copiedVoucherCodes.splice(activeIndex, 1)

      return {
        ...state,
        next: {
          ...state.next,
          activeVoucherCodes: copiedVoucherCodes,
        },
      }
    }

    case ActionType.SetVoucherCodes: {
      return {
        ...state,
        next: {
          ...state.next,
          activeVoucherCodes: action.payload,
        },
      }
    }

    default:
      return state
  }
}

interface ToggleVoucherCodeAction {
  type: ActionType.ToggleVoucherCode
  payload: string
}
export const toggleVoucherCode =
  (dispatch: React.Dispatch<ToggleVoucherCodeAction>) =>
  (payload: ToggleVoucherCodeAction['payload']): void =>
    dispatch({
      type: ActionType.ToggleVoucherCode,
      payload,
    })

interface SetVoucherCodesAction {
  type: ActionType.SetVoucherCodes
  payload: string[]
}
export const setVoucherCodes =
  (dispatch: React.Dispatch<SetVoucherCodesAction>) =>
  (payload: SetVoucherCodesAction['payload']): void =>
    dispatch({
      type: ActionType.SetVoucherCodes,
      payload,
    })
