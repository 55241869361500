/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react'

import { useAuth } from 'lib/hooks/useAuth'
import { useApi } from 'lib/hooks/useApi'
import { Partner, UserConfig } from 'types'
import { GlobalContext } from 'store/global'
import { MASQUERADE_PARTNER_ID_KEY } from 'lib/storage/constants'

export const useFetchCharity = (): void => {
  const [
    { availablePartners, masqueradePartnerId },
    { setPartnerData, setMasqueradePartner },
  ] = useContext(GlobalContext)
  const { isAuthenticated } = useAuth()
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)

  const { data: initialData } = useApi<UserConfig>('partners/initial-data', {
    enabled: isAuthenticated && !initialDataLoaded,
  })

  const { data: masqueradePartnerData } = useApi<Partner>(
    `partners/${masqueradePartnerId}`,
    {
      enabled: isAuthenticated && !!masqueradePartnerId,
    }
  )

  useEffect(() => {
    if (initialData) {
      setInitialDataLoaded(true)
    }

    // We should immediately just set data as we know only one request was fired.
    if (initialData && !masqueradePartnerId) {
      console.debug(
        '[useFetchCharity] User is not masquerading and initialData has loaded',
        {
          initialData,
        }
      )
      setPartnerData(initialData)
    } else if (
      /*
       * If we're masqurading we wait for both initial data and
       * masqueradePartnerData to have loaded before calling setPartnerData to
       * avoid a flash of(potentially misleading) content.
       */
      masqueradePartnerId &&
      initialData &&
      masqueradePartnerData
    ) {
      console.debug(
        '[useFetchCharity] Using is masquerading and masqueradePartnerData has loaded',
        {
          initialData,
          masqueradePartnerId,
          masqueradePartnerData,
        }
      )
      setPartnerData({
        ...initialData,
        activePartner: { ...masqueradePartnerData },
      })
    }

    if (masqueradePartnerData) {
      console.debug(
        `[useFetchCharity] useEffect setting masqueradePartnerId to ${masqueradePartnerData.id} in localStorage`
      )
      window.localStorage.setItem(
        MASQUERADE_PARTNER_ID_KEY,
        masqueradePartnerData.id
      )
    }
  }, [
    availablePartners,
    setPartnerData,
    initialData,
    masqueradePartnerData,
    setMasqueradePartner,
    masqueradePartnerId,
  ])
}
