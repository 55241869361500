import React from 'react'
import styled from 'styled-components'
import { Wrapper, BulletSeparator } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

const StyledStatsTable = styled(Wrapper)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${GTR.XS};
  line-height: 1;
  font-variant-numeric: tabular-nums;

  > *:nth-child(-n + 2) {
    color: ${COLOR.BLACK};
  }
`

const StyledDd = styled.dd`
  margin: 0;
  text-align: right;
`

interface Props {
  items: {
    label: string
    value: null | string | string[]
  }[]
}

const getRenderedValue = (value: null | string | string[]): React.ReactNode => {
  if (value === null) return '-'
  if (typeof value === 'string') return value

  return (
    <BulletSeparator>
      {value.map((valuePart: string) => (
        <span key={valuePart}>{valuePart}</span>
      ))}
    </BulletSeparator>
  )
}

export const StatsTable = ({ items }: Props): React.ReactElement => (
  <StyledStatsTable tag="dl">
    {items.map(({ label, value }) => (
      <React.Fragment key={label}>
        <dt>{label}</dt>
        <StyledDd>{getRenderedValue(value)}</StyledDd>
      </React.Fragment>
    ))}
  </StyledStatsTable>
)
