import React, { useContext, useEffect } from 'react'

import { Dashboard } from 'components/Dashboard'
import { FilterContext } from 'store/filter'
import { ServiceTypeContext } from 'routes/context'

export const Telephone = (): React.ReactElement => {
  const [, { clearVoucherCodes, applyFilters }] = useContext(FilterContext)

  useEffect(() => {
    clearVoucherCodes()
    applyFilters()
  }, [clearVoucherCodes, applyFilters])

  return (
    <ServiceTypeContext.Provider value="telephone">
      <Dashboard />
    </ServiceTypeContext.Provider>
  )
}
