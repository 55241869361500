import React from 'react'
import { FormStatusMessage, P } from '@farewill/ui'
import styled from 'styled-components'

import { useApi } from 'lib/hooks/useApi'

const StyledIframe = styled.iframe`
  display: block;
`

export const Download = ({
  partnerName,
  partnerId,
  reportId,
  filename,
}: {
  partnerName: string
  partnerId: string
  reportId: string
  filename: string
}) => {
  const {
    data: url,
    loading,
    error,
  } = useApi<string>(
    `partners/${partnerId}/reports/${reportId}/presigned-url?filename=${encodeURIComponent(
      /*
       * AWS S3 only accepts ISO-8859-1 (Latin-1) characters for the filename. When we use
       * encodeURIComponent, it encodes to UTF-8 but some of these characters might not be
       * available in the ISO-8859-1 character set, such as ’. We are therefore removing
       * these characters with regex.
       */
      // eslint-disable-next-line no-control-regex
      filename.replace(/[^\x00-\xFF]/g, '')
    )}`
  )

  return (
    <>
      {error ? (
        <FormStatusMessage status="error" title="Sorry, Something went wrong">
          <P>
            Unable to download report. Please try again or contact{' '}
            <a href="mailto: charities@farewill.com">charities@farewill.com</a>
          </P>
        </FormStatusMessage>
      ) : (
        <>
          {!loading && url && (
            <StyledIframe
              title={`Report download for ${partnerName}`}
              aria-hidden="true"
              frameBorder="0"
              width="1"
              height="1"
              src={url}
            />
          )}
        </>
      )}
    </>
  )
}
