import React from 'react'
import { Grid, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { SpanColumns } from '@farewill/ui/components/Grid/types'

import { SkeletonLoader } from 'components/loader/SkeletonLoader'
import { ScrollingWrapper } from 'components/ScrollingWrapper'

interface Props {
  children: React.ReactNode
  loading?: boolean
  spanFromM?: SpanColumns
  minWidth?: number
  minHeight?: number
  maxWidth?: number
  maxHeight?: number
  className?: string
}

export const GridItemWrapper = ({
  children,
  loading,
  spanFromM,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  className,
}: Props): React.ReactElement => {
  const useScrollingWrapper = !!(minWidth || minHeight || maxWidth || maxHeight)

  return (
    <Grid.Item
      loading={!!loading}
      tag={SkeletonLoader}
      borderRadius="S"
      spanFromM={spanFromM}
    >
      {useScrollingWrapper ? (
        <Wrapper background={COLOR.WHITE} bordered borderRadius="S">
          <ScrollingWrapper
            minWidth={minWidth}
            minHeight={minHeight}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
          >
            <Wrapper padding={GTR.S} paddingFromM={GTR.M}>
              {children}
            </Wrapper>
          </ScrollingWrapper>
        </Wrapper>
      ) : (
        <Wrapper
          background={COLOR.WHITE}
          bordered
          borderRadius="S"
          padding={GTR.S}
          paddingFromM={GTR.M}
          className={className}
        >
          {children}
        </Wrapper>
      )}
    </Grid.Item>
  )
}
