import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { GTR, COLOR, BORDER, INTERACTIVE } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

export type TooltipGravity = 'left' | 'right' | 'center'

export type TooltipPosition = 'above' | 'below'

const StyledWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: help;

  &:focus,
  &:active {
    box-shadow: 0 0 0 ${INTERACTIVE.OUTLINE_WEIGHT} ${COLOR.STATE.ACTIVE};
  }

  &:focus-visible {
    outline: none;
  }
`

interface TooltipWrapperProps {
  $visible: boolean
  $gravity: TooltipGravity
  $position: TooltipPosition
  $positionModifier: number
  $wide: boolean
  $wrapper: HTMLDivElement | null
  $target: HTMLDivElement | null
}

const StyledTooltipWrapper = styled.span<TooltipWrapperProps>`
  display: block;
  position: absolute;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
  cursor: default;
  padding-bottom: 10px;

  ${({ $wide }) =>
    $wide &&
    css`
      width: max-content;
      max-width: 340%;
      text-align: left;

      ${screenMax.s`
        max-width: 80vw;
    `}
    `}

  ${({ $visible }) =>
    $visible &&
    `
    visibility: visible;
    pointer-events: auto;
  `}

  ${({ $gravity, $wrapper, $target }) => {
    if ($wrapper && $target && $gravity === 'center') {
      return `
        left: -${$wrapper.clientWidth / 2 - $target.clientWidth / 2}px;

        > *:before {
          left: calc(50% - ${GTR.XS});
        }
      `
    }
    if ($gravity === 'right') {
      return `
        right: 0;

        > *:before {
          right: ${GTR.XS};
        }
      `
    }
    return `
      left: 0;

      > *:before {
        left: ${GTR.XS};
      }
    `
  }}

  ${({ $wide, $wrapper, $target }) =>
    $wide &&
    $wrapper &&
    $target &&
    screenMax.m`
      left: ${-($wrapper.clientWidth / 2 - $target.clientWidth / 2)}px;

      > *:before {
        left: calc(50% - ${GTR.XS});
      }
  `}

  ${({ $position, $positionModifier }) =>
    $position === 'below'
      ? css`
          top: calc(100% + ${GTR.XS} - ${$positionModifier}%);

          ${screenMax.m`
            top: calc(100% + ${GTR.XS});
          `}
        `
      : `bottom: 100%;`}
`

const StyledTooltip = styled.span<{ $position: TooltipPosition }>`
  display: block;
  padding: 2px ${GTR.S};
  background: ${COLOR.WHITE};
  border: 1px solid ${COLOR.GREY.LIGHT};
  border-radius: 2px;
  box-shadow: ${BORDER.SHADOW.M};

  &:before {
    content: '';
    position: absolute;
    width: ${GTR.XS};
    height: ${GTR.XS};
    background: ${COLOR.WHITE};
    border-style: solid;
    border-color: ${COLOR.GREY.LIGHT};
    transform: rotate(-45deg);
    transform-origin: 0 0;

    ${({ $position }) =>
      $position === 'below'
        ? `
            border-width: 1px 1px 0 0;
            top: -1px;
          `
        : `
            border-width: 0 0 1px 1px;
            bottom: 1px;
          `}
  }
`

interface Props {
  children?: React.ReactNode
  tooltipId: string
  tooltipContent: React.ReactNode
  gravity?: TooltipGravity
  position?: TooltipPosition
  positionModifier?: number
  wide?: boolean
  as?: React.ElementType
  className?: string
  style?: React.CSSProperties
}

export const TooltipWrapper = ({
  tooltipId,
  tooltipContent,
  gravity,
  position,
  positionModifier,
  wide,
  as,
  className,
  style,
  children,
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const targetWrapperRef = useRef<HTMLDivElement>(null)
  const tooltipWrapperRef = useRef<HTMLDivElement>(null)

  return (
    <StyledWrapper
      tabIndex={0}
      onFocus={() => {
        setIsOpen(true)
        setIsFocused(true)
      }}
      onBlur={() => {
        setIsOpen(false)
        setIsFocused(false)
      }}
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => !isFocused && setIsOpen(false)}
      aria-expanded={isOpen}
      aria-labelledby={tooltipId}
      as={as}
      className={className}
      style={style}
      ref={targetWrapperRef}
    >
      {children}
      <StyledTooltipWrapper
        role="tooltip"
        $visible={isOpen}
        $gravity={gravity || 'left'}
        $position={position || 'above'}
        $positionModifier={positionModifier || 0}
        $wide={wide || false}
        $wrapper={tooltipWrapperRef.current}
        $target={targetWrapperRef.current}
        id={tooltipId}
        aria-hidden={!isOpen}
        ref={tooltipWrapperRef}
      >
        <StyledTooltip $position={position || 'above'}>
          {tooltipContent}
        </StyledTooltip>
      </StyledTooltipWrapper>
    </StyledWrapper>
  )
}
