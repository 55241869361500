import React from 'react'
import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`

const StyledSpinner = styled.figure`
  font-size: 85px;
  width: 1em;
  height: 1em;
  margin: 0;
  transform-origin: 50% 50%;
  animation: 0.8s ${spinAnimation} infinite linear;
  transition: 0.1s opacity linear;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20'%3E%3Cpath d='M20 10h-2c0 4.4-3.6 8-8 8v2c5.5 0 10-4.5 10-10z'/%3E%3Cdefs%3E%3Cpath id='a' d='M10 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8m0-2C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='hidden'/%3E%3C/clipPath%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='9' y1='11' x2='19' y2='1' gradientTransform='rotate(90 15 6)'%3E%3Cstop offset='.1' stop-opacity='.5'/%3E%3Cstop offset='.9'/%3E%3C/linearGradient%3E%3Cpath clip-path='url(%23b)' fill='url(%23c)' d='M10 10V0h10v10z'/%3E%3Cg%3E%3Cdefs%3E%3Cpath id='d' d='M10 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8m0-2C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z'/%3E%3C/defs%3E%3CclipPath id='e'%3E%3Cuse xlink:href='%23d' overflow='hidden'/%3E%3C/clipPath%3E%3ClinearGradient id='f' gradientUnits='userSpaceOnUse' x1='0' y1='10' x2='10' y2='0'%3E%3Cstop offset='.05' stop-opacity='0'/%3E%3Cstop offset='.9' stop-opacity='.5'/%3E%3C/linearGradient%3E%3Cpath clip-path='url(%23e)' fill='url(%23f)' d='M0 0h10v10H0z'/%3E%3C/g%3E%3C/svg%3E");
`

interface Props {
  hidden?: boolean
  size?: number | string
  className?: string
}

export const Spinner = ({
  hidden,
  size,
  className,
}: Props): React.ReactElement => (
  <StyledSpinner
    className={className}
    role="alert"
    aria-label="Loading"
    aria-busy="true"
    style={{
      opacity: hidden ? 0 : 0.25,
      fontSize: size || 85,
    }}
  />
)
