import { toLocaleStringSupportsOptions } from 'lib/formatting/common'

export const shortFormatNumber = (number: number): string => {
  const f = Math.floor

  if (number >= 10000000) return `${f(number / 1000000)}m`
  if (number >= 1000000) {
    return f(number / 100000) % 10 === 0
      ? `${f(number / 1000000)}m`
      : `${f(number / 100000) / 10}m`
  }

  if (number >= 10000) return `${f(number / 1000)}k`
  if (number >= 1000) {
    return f(number / 100) % 10 === 0
      ? `${f(number / 1000)}k`
      : `${f(number / 100) / 10}k`
  }

  return String(f(number))
}

export const formatNumber = (number: number): string =>
  Math.round(number).toLocaleString('en')

export const formatPercentage = (number: number, decimalPlaces = 2): string => {
  if (toLocaleStringSupportsOptions()) {
    return number.toLocaleString('en-GB', {
      style: 'percent',
      maximumFractionDigits: decimalPlaces,
    })
  }

  return `${(number * 100).toFixed(decimalPlaces)}%`
}
