export enum Routes {
  Home = '/',
  Online = '/online',
  Resources = '/resources',
  Telephone = '/telephone',
  GenerateAccess = '/assistant/access',
  GenerateEmails = '/assistant/email-campaigns',
  GenerateSocial = '/assistant/social-content',
  GeneratePrint = '/assistant/print-materials',
  GenerateTerms = '/assistant/terms',
}
