import React, { useRef, useLayoutEffect } from 'react'
import * as d3 from 'd3'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { shortFormatNumber } from 'lib/formatting/numbers'

import { wrapText } from './wrapText'

const TEXT_MARGIN = 10

interface Props {
  xAxis: d3.Axis<string>
  yAxis: d3.Axis<d3.NumberValue>
  chartMargin: {
    top: number
    left: number
    right: number
    bottom: number
  }
  chartWidth: number
  chartHeight: number
  setXAxisLabelHeight?: (height: number) => void
}

export const BarAxes = ({
  xAxis,
  yAxis,
  chartMargin,
  chartWidth,
  chartHeight,
  setXAxisLabelHeight,
}: Props): React.ReactElement => {
  const xAxisElement = useRef<SVGGElement>(null)
  const yAxisElement = useRef<SVGGElement>(null)

  useLayoutEffect(() => {
    if (xAxisElement.current && yAxisElement.current) {
      d3.select(xAxisElement.current)
        .call(xAxis.tickSize(0))
        .call((g) => {
          g.select('.domain').remove()
          g.selectAll('.tick text')
            .style('font-size', FONT.SIZE.XS)
            .call(
              wrapText,
              (xAxis.scale().bandwidth as () => number)(),
              setXAxisLabelHeight
            )
        })

      // use a right axis to stretch the ticks the whole way across
      d3.select(yAxisElement.current)
        .call(
          yAxis
            .tickSize(chartWidth - chartMargin.left - chartMargin.right)
            .tickFormat((v) => shortFormatNumber(v as number))
        )
        .call((g) => {
          g.select('.domain').remove() // hide vertical line
          g.selectAll('.tick').attr('text-anchor', 'end')
          g.selectAll('.tick line').attr('stroke', COLOR.GREY.LIGHT)
          g.selectAll('.tick:nth-of-type(2n) text').remove() // hide every second label
          g.selectAll('.tick text')
            .attr('x', -TEXT_MARGIN)
            .style('font-size', FONT.SIZE.XS)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xAxis.scale(), yAxis.scale(), chartWidth, chartHeight])

  return (
    <>
      <line
        x1={chartMargin.left + 0.5}
        y1={chartMargin.top}
        x2={chartMargin.left + 0.5}
        y2={chartHeight - chartMargin.bottom}
        style={{ stroke: COLOR.GREY.LIGHT }}
      />

      <g
        ref={xAxisElement}
        transform={`translate(0, ${
          chartHeight - chartMargin.bottom + TEXT_MARGIN
        })`}
        style={{ fontFamily: FONT.FAMILY.BODY }}
      />

      <g
        ref={yAxisElement}
        transform={`translate(${chartMargin.left}, 0)`}
        style={{ fontFamily: FONT.FAMILY.BODY }}
      />
    </>
  )
}
