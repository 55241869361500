import React from 'react'

import { State, ActionType } from '../constants'

type Payload = Date | null

export const reducer: React.Reducer<
  State,
  { type: ActionType; payload: Payload }
> = (state, action) => {
  switch (action.type) {
    case ActionType.SetStartDate:
      return {
        ...state,
        next: {
          ...state.next,
          startDate: action.payload,
        },
      }

    case ActionType.SetEndDate:
      return {
        ...state,
        next: {
          ...state.next,
          endDate: action.payload,
        },
      }

    default:
      return state
  }
}

export const setStartDate =
  (
    dispatch: React.Dispatch<{
      type: ActionType
      payload: Payload
    }>
  ) =>
  (payload: Payload): void =>
    dispatch({
      type: ActionType.SetStartDate,
      payload,
    })

export const setEndDate =
  (
    dispatch: React.Dispatch<{
      type: ActionType
      payload: Payload
    }>
  ) =>
  (payload: Payload): void =>
    dispatch({
      type: ActionType.SetEndDate,
      payload,
    })
