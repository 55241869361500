import React from 'react'
import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

interface Props {
  minWidth?: number
  minHeight?: number
  maxWidth?: number
  maxHeight?: number
  children: React.ReactNode
}

const SCROLLBAR_THUMB = COLOR.GREY.LIGHT

const StyledScroller = styled.div`
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: thin;
  scrollbar-color: ${SCROLLBAR_THUMB} transparent;

  &::-webkit-scrollbar {
    width: ${GTR.XS};
    height: ${GTR.XS};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${SCROLLBAR_THUMB};
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-top: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-left: 2px solid transparent;
  }
`

export const ScrollingWrapper = ({
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  children,
}: Props): React.ReactElement => (
  <StyledScroller style={{ maxWidth, maxHeight }}>
    <div style={{ minWidth, minHeight }}>{children}</div>
  </StyledScroller>
)
