import { InheritancePrecedence, Traffic } from 'types'
import {
  DEFAULT_DATE_RANGE_END_DATE,
  DEFAULT_DATE_RANGE_START_DATE,
} from 'components/Filters/constants'

export enum ActionType {
  ApplyFilters = 'APPLY_FILTERS',
  ClearFilters = 'CLEAR_FILTERS',
  ClearDates = 'CLEAR_DATES',
  ClearVoucherCodes = 'CLEAR_VOUCHER_CODES',
  ToggleVoucherCode = 'TOGGLE_VOUCHER_CODE',
  SetVoucherCodes = 'SET_VOUCHER_CODES',
  SetStartDate = 'SET_START_DATE',
  SetEndDate = 'SET_END_DATE',
  SetTrafficTypeFilter = 'SET_TRAFFIC_TYPE_FILTER',
  SetInheritancePrecedence = 'SET_INHERITANCE_PRECEDENCE',
}

type CaseState = {
  trafficTypeFilter: Traffic
  activeVoucherCodes: string[]
  startDate: Date | null
  endDate: Date | null
  inheritancePrecedence: InheritancePrecedence[]
}

export const clearedState: CaseState = {
  trafficTypeFilter: 'all',
  activeVoucherCodes: [],
  startDate: null,
  endDate: null,
  inheritancePrecedence: ['primary'],
}

const initialCaseState: CaseState = {
  ...clearedState,
  /**
   * We want the initial date range to be "Last 12 months",
   * with startDate and endDate set accordingly; however, clearing the filters should
   * still reset the date range to "All time" and startDate and endDate to null.
   */
  startDate: DEFAULT_DATE_RANGE_START_DATE,
  endDate: DEFAULT_DATE_RANGE_END_DATE,
  trafficTypeFilter: 'campaign',
}

export type State = {
  current: CaseState
  next: CaseState
}

export const initialState: State = {
  current: initialCaseState,
  next: initialCaseState,
}
