export enum ColorHue {
  Blue = 'blue',
  Pink = 'pink',
}

export enum ColorGrade {
  Dark = 'dark',
  Medium = 'medium',
  Light = 'light',
}

export const CHART_COLORS = {
  [ColorHue.Blue]: {
    [ColorGrade.Dark]: '#1F25AC',
    [ColorGrade.Medium]: '#7580E5',
    [ColorGrade.Light]: '#B8C3FF',
  },
  [ColorHue.Pink]: {
    [ColorGrade.Dark]: '#AC1F74',
    [ColorGrade.Medium]: '#E575AC',
    [ColorGrade.Light]: '#FFB8D1',
  },
}
