import React from 'react'
import styled from 'styled-components'

import { Grid } from '@farewill/ui'
import {
  COLOR,
  CONTENT_INNER_WIDTH,
  CONTAINER_PADDING_HORIZONTAL_AT_BP,
} from '@farewill/ui/tokens'
import { screenMin, screen } from '@farewill/ui/helpers/responsive'

import { Filters } from 'components/Filters'

const SIDEBAR_BACKGROUND_COLOR = COLOR.WHITE

const StyledSidebar = styled(Grid.Item)`
  position: relative;

  > * {
    position: relative;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 ${CONTAINER_PADDING_HORIZONTAL_AT_BP.S};
    left: -${CONTAINER_PADDING_HORIZONTAL_AT_BP.S};
    bottom: 0;
    background: ${SIDEBAR_BACKGROUND_COLOR};
    border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  }

  ${screenMin.m`
    background: ${SIDEBAR_BACKGROUND_COLOR};
    border-right: 1px solid ${COLOR.BACKGROUND.SMOKE};
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);

    &:before {
      height: 100%;
      width: ${CONTAINER_PADDING_HORIZONTAL_AT_BP.L};
      top: 0;
      left: auto;
      right: 100%;
      padding: 0;
      border-bottom: 0;
    }
  `}

  ${screen.xl`
    &:before {
      width: calc(50vw - ${CONTENT_INNER_WIDTH} / 2);
    }
  `}
`

export const SideBar = (): React.ReactElement => (
  <StyledSidebar
    spanFromM={4}
    spanFromXL={3}
    tag="aside"
    padding={['M', 0]}
    paddingFromM={['L', 'M', 'L', 0]}
    paddingFromL={['XL', 'M', 'XL', 0]}
  >
    <Filters />
  </StyledSidebar>
)
