import { CHART_COLORS, ColorGrade, ColorHue } from 'components/stats/constants'
/* eslint-disable consistent-return, default-case */

export const enum StatFormat {
  Monetary,
  Numerical,
  Percentage,
}

export const getGiftChartHue = (format: StatFormat): ColorHue => {
  switch (format) {
    case StatFormat.Monetary:
      return ColorHue.Pink
    case StatFormat.Numerical:
      return ColorHue.Blue
    case StatFormat.Percentage:
      return ColorHue.Pink
  }
}

export const enum GiftChartCategory {
  Residuary,
  Pecuniary,
  Total,
}

export const getGiftChartGrade = (grade: GiftChartCategory): ColorGrade => {
  switch (grade) {
    case GiftChartCategory.Residuary:
      return ColorGrade.Dark
    case GiftChartCategory.Pecuniary:
      return ColorGrade.Light
    case GiftChartCategory.Total:
      return ColorGrade.Medium
  }
}

export const getGiftChartColor = (
  giftFormat: StatFormat,
  giftCategory: GiftChartCategory
): string => {
  const hue = getGiftChartHue(giftFormat)
  const grade = getGiftChartGrade(giftCategory)

  return CHART_COLORS[hue][grade]
}
