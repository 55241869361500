import React from 'react'
import styled from 'styled-components'

import { DefaultLayout } from 'layouts/DefaultLayout'
import { Spinner } from 'components/loader/Spinner'

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em;
`

interface Props {
  showHeaderAndFooter?: boolean
}

export const LoadingLayout = ({
  showHeaderAndFooter,
}: Props): React.ReactElement =>
  showHeaderAndFooter ? (
    <DefaultLayout>
      <div style={{ flex: 1 }}>
        <StyledSpinner />
      </div>
    </DefaultLayout>
  ) : (
    <StyledSpinner />
  )
