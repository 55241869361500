import React, { useContext } from 'react'
import styled from 'styled-components'
import { Grid } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { useAuth } from 'lib/hooks/useAuth'
import { GlobalContext } from 'store/global'

import { ServiceTypeContext } from 'routes/context'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { SideBar } from 'layouts/SideBar'

import { CustomerReports } from 'components/CustomerReports'
import { GeneralError } from 'components/GeneralError'
import { Performance } from 'components/Performance'

const StyledGrid = styled(Grid)`
  width: 100%;
  background-color: ${COLOR.WHITE};
`

export const Dashboard = (): React.ReactElement => {
  const { error } = useAuth()
  const [{ canDownloadPartnerDataSharingReports }] = useContext(GlobalContext)
  const willServiceType = useContext(ServiceTypeContext)

  /**
   * Show the reports section when willServiceType is 'all' AND the user has
   * canDownloadPartnerDataSharingReports attribute in the global context
   */
  const canSeeReports =
    willServiceType === 'all' && canDownloadPartnerDataSharingReports

  return (
    <DefaultLayout>
      <StyledGrid container containerPaddingTop={0} containerPaddingBottom={0}>
        {error ? (
          <Grid.Item
            padding={[0, 0, 'M']}
            paddingFromM={['L', 0]}
            paddingFromL={['XL', 0]}
          >
            <GeneralError />
          </Grid.Item>
        ) : (
          <>
            <SideBar />

            <Grid.Item
              spanFromM={8}
              spanFromXL={9}
              tag="main"
              padding={[0, 0, 'M']}
              paddingFromM={['L', 0]}
              paddingFromL={['XL', 0]}
            >
              <Performance />
              {canSeeReports && <CustomerReports />}
            </Grid.Item>
          </>
        )}
      </StyledGrid>
    </DefaultLayout>
  )
}
