import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

import { DividedColumns } from 'components/DividedColumns'
import { Swatch } from 'components/stats/Swatch'
import { PercentageChart } from 'components/stats/PercentageChart'
import { StatsTable } from 'components/stats/StatsTable'

const StyledDividedColumns = styled(DividedColumns)`
  margin: ${GTR.M} 0 0;
`

const StyledSwatch = styled(Swatch)`
  position: absolute;
  && {
    margin-top: 1px;
  }
`

interface Props {
  items: {
    title: string
    value: number
    percentage?: string | null
    color: string
    valueFormatter?: (value: number) => string
    additionalData?: {
      label: string
      value: null | string | string[]
    }[]
  }[]
}

export const PercentageChartWithTable = ({
  items,
}: Props): React.ReactElement => (
  <>
    <PercentageChart
      items={items.map(({ title, value, color }) => ({
        label: title,
        value,
        color,
      }))}
    />
    <StyledDividedColumns>
      {items.map(
        ({
          title,
          value,
          percentage,
          color,
          valueFormatter,
          additionalData,
        }) => {
          const formattedValue = valueFormatter
            ? valueFormatter(value)
            : value.toString()

          return (
            <React.Fragment key={title}>
              <StyledSwatch color={value ? color : COLOR.GREY.LIGHT} />
              <Wrapper padding={[0, 0, 0, 'M']} key={title}>
                <StatsTable
                  items={[
                    {
                      label: title,
                      value: percentage
                        ? [percentage, formattedValue]
                        : formattedValue,
                    },
                    ...(additionalData || []),
                  ]}
                />
              </Wrapper>
            </React.Fragment>
          )
        }
      )}
    </StyledDividedColumns>
  </>
)
