import React from 'react'
import { InheritancePrecedence } from 'types'
import { State, ActionType } from '../constants'

type Payload = InheritancePrecedence[]

export const reducer: React.Reducer<
  State,
  { type: ActionType; payload: Payload }
> = (state, action) => {
  switch (action.type) {
    case ActionType.SetInheritancePrecedence:
      return {
        ...state,
        next: {
          ...state.next,
          inheritancePrecedence: action.payload,
        },
      }

    default:
      return state
  }
}

export const setInheritancePrecedence =
  (
    dispatch: React.Dispatch<{
      type: ActionType
      payload: Payload | undefined
    }>
  ) =>
  (payload: Payload): void => {
    return dispatch({
      type: ActionType.SetInheritancePrecedence,
      payload,
    })
  }
