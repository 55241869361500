import { useState, useRef, useLayoutEffect } from 'react'

export const useElementSize = (): [
  React.MutableRefObject<HTMLElement | null>,
  DOMRect,
] => {
  const element = useRef<HTMLElement>(null)

  const [dimensions, setDimensions] = useState({} as DOMRect)

  const getDimensions = (): DOMRect => {
    const target = element.current as unknown as HTMLElement
    return target ? target.getBoundingClientRect() : dimensions
  }

  useLayoutEffect(() => {
    setDimensions(getDimensions())

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current])

  return [element, dimensions]
}
