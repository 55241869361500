/**
 * @fileoverview This module holds an in-memory reference to Auth0's
 * `getAccessTokenSilently` method. We need this because the Auth0 React SDK
 * does not expose the client instance and only makes the
 * `getAccessTokenSilently` method available through a hook which means it can
 * only be called from within a React component.
 *
 * See https://github.com/auth0/auth0-react/issues/67
 */

import type { Auth0ContextInterface } from '@auth0/auth0-react'

type GetAccessTokenSilently = Auth0ContextInterface['getAccessTokenSilently']

type Deferred = {
  promise: Promise<GetAccessTokenSilently>
  resolve: (value: GetAccessTokenSilently) => void
}

const createDeferred = (): Deferred => {
  let resolve: (value: GetAccessTokenSilently) => void
  const promise = new Promise<GetAccessTokenSilently>(
    (res: (value: GetAccessTokenSilently) => void) => {
      resolve = res
    }
  )
  return { promise, resolve }
}

export const deferred = createDeferred()

export const getAccessToken = async (...args) => {
  const getToken = await deferred.promise
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return getToken(...args)
}
