import React from 'react'
import styled from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'
import { Wrapper } from '@farewill/ui'

import { Footer } from 'components/Footer'
import { Header } from '../routes/Assistant/components/Header'

const StyledPageWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
`

const StyledContentWrapper = styled(Wrapper)`
  width: 100%;
`

export const CampaignAssistantLayout = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => (
  <StyledPageWrapper background={COLOR.WHITE}>
    <Header />

    <StyledContentWrapper container>{children}</StyledContentWrapper>

    <Footer />
  </StyledPageWrapper>
)
