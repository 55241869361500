import React, { useContext } from 'react'
import styled from 'styled-components'
import { Divider as LibDivider } from '@farewill/ui'

import { PerformanceContext } from './context'

const StyledDivider = styled(LibDivider)<{ $loading: boolean }>`
  ${({ $loading }) =>
    $loading &&
    `
    visibility: hidden;
  `}
`

interface Props {
  margin?: string | number | Array<string | number>
}

export const Divider = ({ margin }: Props): React.ReactElement => {
  const { loading } = useContext(PerformanceContext)

  return <StyledDivider margin={margin || ['M', 0]} $loading={loading} />
}
