import React from 'react'

import { GeneratedParagraph } from '../components/GeneratedParagraph'
import { LinkReminder } from '../components/LinkReminder'

type RenderMode = 'copy:plaintext' | 'copy:html' | 'plaintext' | 'jsx'

const getParagraphs = (text: string): string[] =>
  text
    .split('\n\n')
    .filter((paragraph) => paragraph.length > 0)
    .map((p) => p.trim())

const processContent = (
  paragraph: string,
  mode: RenderMode
): string | React.ReactElement => {
  /*
   * Split the paragraph around links so that we can replace the link bits
   * with either just the link text (when `mode` is `plaintext`) or our
   * LinkReminder component (when `mode` is `jsx`).
   *
   * This regex matches Markdowns Link format: [linkText](linkUrl). It's
   * important that we do not include the capture groups in this instance
   * because doing so changes the behaviour of the split method[1] and we
   * end up the link text and URL in the rendered text.
   *
   * [1] https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split#description
   */
  const parts = paragraph.split(/(\[.*?\]\(.*?\))/)

  // Process links
  const fragments = parts.map((part) => {
    /*
     * The regex below is essentially the same regex as above. It matches
     * the Markdown link format and captures the link text, but not the
     * URL (as we never want to include any AI suggested URLs in our
     * output). In this instance we use capture groups to extract the link
     * text.
     */
    const match = /(\[(.*?)\]\((.*?)\))/g.exec(part)
    const isLink = match !== null
    if (isLink) {
      const linkText = match[2]

      /*
       * In plaintext mode we just want to render the link text in the JSX. It
       * will not look like a link and will not be interactive while the content
       * reveal animation runs.
       */
      if (mode === 'plaintext') {
        return linkText
      }

      /**
       * In copy:plaintext mode we want to render the link text with some extra
       * characters to draw attention to the fact that the user needs to add a
       * link to the text after pasting into their email sending application.
       */
      if (mode === 'copy:plaintext') {
        return `[ * ${linkText} * ]<--ADD LINK HERE`
      }

      /**
       * In copy:html mode we want to render the link bold with some extra
       * characters to draw attention to the fact that the user needs to add a
       * link to the text after pasting into their email sending application.
       */
      if (mode === 'copy:html') {
        return (
          <strong
            key={linkText}
          >{`[ * ${linkText} * ]<--ADD LINK HERE`}</strong>
        )
      }

      /**
       * In `jsx` mode we want to render the link text as a LinkReminder so that
       * it's interactive and the user can see the tooltip when they hover it.
       */
      return (
        <LinkReminder key={`link-reminder-${linkText}`}>
          {linkText}
        </LinkReminder>
      )
    }
    return part
  })

  if (mode === 'copy:plaintext') {
    return fragments.join('')
  }

  // Replace single newlines with <br /> elements
  const fragmentsWithBrs = fragments.map((fragment) => {
    if (typeof fragment === 'string') {
      const needsBreak = fragment.includes('\n')

      if (!needsBreak) {
        return fragment
      }

      const lines = fragment.split('\n').map((line, lineIndex) => {
        return (
          <React.Fragment key={line}>
            {lineIndex > 0 && <br />}
            {line}
          </React.Fragment>
        )
      })

      return lines
    }

    return fragment
  })

  return <>{fragmentsWithBrs}</>
}

/**
 * @param text The email content.
 * @param animate Whether we're going to play the text reveal animation. It's
 * only played once after a generation completes, and not when the user switches
 * tabs. Ignored when mode is `copy:painText` or `copy:html`.
 * @param mode Controls what markup the rendered result returns.
 *  - When mode is `plaintext` the text is rendered as plain text. This mode is
 *    used when playing the reveal animation which (due to the way the animation
 *    works) does not support animating with nested content.
 *  - When mode is `jsx` the text is rendered as JSX with interactive elements,
 *    (e.g. links are rendered as LinkReminder components).
 *  - When mode is `copy`, the text is rendered as HTML, but with link
 *    content augmented with extra characters to draw attention so that the
 *    user remembers to add links to the text after pasting in their email
 *    sending application.
 */
export function convertToJsx(text: string, mode: 'copy:plaintext'): string
export function convertToJsx(
  text: string,
  mode: 'copy:html'
): React.ReactElement
export function convertToJsx(
  text: string,
  mode: Exclude<RenderMode, 'copy:plaintext' | 'copy:html'>,
  animate: boolean
): React.ReactElement
export function convertToJsx(
  text: string,
  mode: RenderMode,
  animate?: boolean
): string | React.ReactElement {
  let charCount = 0
  const paragraphs = getParagraphs(text)
  const processedParagraphs = paragraphs.map((paragraph, index) => {
    const startIndex = charCount
    charCount += paragraph.length
    const isFirst = startIndex === 0
    const isLast = index === paragraphs.length - 1
    const content = processContent(paragraph, mode)

    if (mode === 'copy:plaintext') {
      return content
    }

    if (mode === 'copy:html') {
      // eslint-disable-next-line react/no-array-index-key
      return <p key={paragraph}>{content}</p>
    }

    return (
      <GeneratedParagraph
        animate={!!animate}
        key={paragraph}
        isFirst={isFirst}
        isLast={isLast}
        startIndex={startIndex}
      >
        {content}
      </GeneratedParagraph>
    )
  })

  if (mode === 'copy:plaintext') {
    return processedParagraphs.join('\n\n')
  }

  return <>{processedParagraphs}</>
}
