import React from 'react'
import { P, LockIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

export const Auth0Trust = (): React.ReactElement => (
  <P strong color={COLOR.GREY.DARK}>
    <LockIcon title="Padlock" inline style={{ marginRight: GTR.XS }} />
    Powered by Auth0.
  </P>
)
