import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { Button, Divider, H, P, UpdateIcon, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { paragraphS } from '@farewill/ui/helpers/text'

import { Toast } from 'components/Toast'
import { Modal } from 'components/Modal'
import { SkeletonLoader } from 'components/loader/SkeletonLoader'

import { Avatar } from './components/Avatar'
import { CopyButton } from './components/CopyButton'
import { PlaceholderContent } from './components/PlaceholderContent'
import { RegenerateForm } from './components/RegenerateForm'
import { convertToJsx } from '../../helpers/parsing'

const StyledBackgroundWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
`

const StyledMessageContainerWrapper = styled(Wrapper)`
  box-shadow: ${BORDER.SHADOW.M};
  flex-grow: 1;
  border-radius: 16px;
  overflow: hidden;
`

const StyledHeaderWrapper = styled(Wrapper)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${GTR.S};
  justify-content: space-between;
`

/**
 * Styles for the email content. We must target using descendant selectors here
 * as the content will be plain HTML that comes out of the Markdown => HTML
 * conversion.
 */
const StyledContentWrapper = styled(Wrapper)`
  color: ${COLOR.BLACK};

  ol,
  ul {
    list-style: auto;
    list-style-position: outside;
    padding-inline-start: 1.1em;
  }
`

const StyledHeader = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${GTR.M};

  ${screenMin.l`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const StyledHeaderCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GTR.XS};

  ${screenMin.m`
    align-items: center;
    flex-direction: row;
    gap: ${GTR.M};
  `}
`

const StyledEmailMetaWrapper = styled(Wrapper)`
  display: flex;
  gap: 13px;
`

const StyledSubjectSpan = styled.span`
  ${paragraphS}

  color: ${COLOR.GREY.MEDIUM};
`

const StyledCopyNotice = styled(P)`
  text-wrap: balance;
`

const InvisibleCopyTargetContent = styled.div`
  display: none;
`

/**
 * Radix requires that we forward a ref to the underlying element. See
 * https://www.radix-ui.com/primitives/docs/guides/composition#your-component-must-forward-ref
 */
const RegenerateButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button.BorderedDark>
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button.BorderedDark ref={ref} {...props}>
    {props.children}
  </Button.BorderedDark>
))

type EmailPreviewProps = {
  animate: boolean
  children: string
  error?: string
  from: string
  loading?: boolean
  regenerate: ({ instructions }: { instructions: string }) => void
  subject: string
  to: string
  onAnimationEnd: () => void
  onRetry: () => void
}

export const EmailPreview = ({
  animate,
  children,
  error,
  from,
  loading,
  regenerate,
  subject,
  to,
  onAnimationEnd,
  onRetry,
}: EmailPreviewProps): React.ReactElement => {
  const [showRegenerateModal, setShowRegenerateModal] = useState(false)
  const [renderMode, setRenderMode] = useState<'plaintext' | 'jsx'>(
    animate ? 'plaintext' : 'jsx'
  )
  const copyTargetRef = useRef(null)
  const [copyToastOpen, setCopyToastOpen] = React.useState(false)

  const onOpenChange = (isOpen: boolean) => {
    setShowRegenerateModal(isOpen)
  }

  const showCopyToast = () => {
    setCopyToastOpen(true)
  }

  const handleAnimationEnd = useCallback(
    (e: React.AnimationEvent) => {
      if (e.currentTarget.lastChild.contains(e.target as Node)) {
        onAnimationEnd()
        setRenderMode('jsx')
      }
    },
    [onAnimationEnd]
  )

  /*
   * If animate changes to `true` (e.g. after a regeneration is started)
   * EmailPreview should initially render in `plaintext` mode so that the reveal
   * animation can run correctly. After the animation completes the rendering
   * mode will be set back to jsx to allow the interactive elements of the email
   * (e.g. tooltips) to be shown.
   */
  useEffect(() => {
    if (animate) {
      setRenderMode('plaintext')
    }
  }, [animate])

  return (
    <>
      <StyledHeader margin={[0, 0, GTR.M]}>
        <H margin="0" size="M">
          Email preview
        </H>
        <StyledHeaderCTAContainer>
          <P color={COLOR.BLACK} margin="0">
            Happy with the content?
          </P>
          <CopyButton
            disabled={loading}
            onCopy={showCopyToast}
            sourceElement={copyTargetRef}
          >
            Copy email text
          </CopyButton>
          <Toast
            open={copyToastOpen}
            onOpenChange={setCopyToastOpen}
            title="Email content copied!"
            description="Remember to check the content add relevant links before sending."
          />
        </StyledHeaderCTAContainer>
      </StyledHeader>
      <StyledBackgroundWrapper
        background={COLOR.ACCENT.PRIMARY_10}
        padding={[GTR.XXL, GTR.L]}
      >
        <StyledMessageContainerWrapper maxWidthInColumns="7">
          <StyledHeaderWrapper
            background={COLOR.ACCENT.PRIMARY_60}
            padding={['M', 'L']}
          >
            <Modal open={showRegenerateModal} onOpenChange={onOpenChange}>
              <P color={COLOR.BLACK} strong margin="0">
                Not happy with the content?
              </P>
              <Modal.Trigger asChild>
                <RegenerateButton
                  disabled={loading}
                  icon={UpdateIcon}
                  iconOnLeft
                >
                  Re-generate email text
                </RegenerateButton>
              </Modal.Trigger>
              <Modal.Content title="Re-generate content">
                <RegenerateForm
                  close={() => {
                    setShowRegenerateModal(false)
                  }}
                  submit={({ instructions }) => {
                    void regenerate({ instructions })
                  }}
                />
              </Modal.Content>
            </Modal>
          </StyledHeaderWrapper>
          <StyledContentWrapper
            background={COLOR.WHITE}
            padding={[GTR.M, GTR.L, GTR.L]}
            paddingFromM={[GTR.L, GTR.XL, GTR.XL]}
            paddingFromL={[GTR.XL, GTR.XXL, GTR.XXL]}
          >
            <StyledEmailMetaWrapper>
              <Avatar />
              <div>
                <P size="S" strong margin="0">
                  <span hidden>From:</span>

                  <SkeletonLoader loading={!from} inline tag="span">
                    {from || <>Charity Name</>}
                  </SkeletonLoader>
                </P>
                <P size="S" margin="0">
                  To: {to}
                </P>
              </div>
            </StyledEmailMetaWrapper>
            <Divider margin={[GTR.M, 0]} />
            {loading ? (
              <>
                <P margin="0" strong>
                  <StyledSubjectSpan>Subject:</StyledSubjectSpan>
                </P>
                <SkeletonLoader loading={loading} inline>
                  &nbsp;
                </SkeletonLoader>
              </>
            ) : (
              <P margin="0" strong>
                <StyledSubjectSpan>Subject:</StyledSubjectSpan>
                <br />
                {subject}
              </P>
            )}

            <Divider margin={[GTR.M, 0]} />

            {loading || error ? (
              <PlaceholderContent error={error} onRetry={onRetry} />
            ) : (
              <div onAnimationEnd={handleAnimationEnd}>
                {convertToJsx(children, renderMode, animate)}
              </div>
            )}

            <InvisibleCopyTargetContent
              data-plaintext-content={
                !loading && !error && convertToJsx(children, 'copy:plaintext')
              }
              data-testid="copy-source"
              ref={copyTargetRef}
            >
              {!loading && !error && convertToJsx(children, 'copy:html')}
            </InvisibleCopyTargetContent>

            <Divider margin={[GTR.M, 0]} />
            <Wrapper centered>
              <P color={COLOR.BLACK} strong margin={[0, 0, GTR.XXS]}>
                Happy with this content?
              </P>
              <StyledCopyNotice
                color={COLOR.GREY.MEDIUM}
                margin={[0, 'auto', GTR.S]}
                size="S"
              >
                Remember to check the content and add relevant links before
                sending.
              </StyledCopyNotice>
              <CopyButton
                disabled={loading}
                onCopy={showCopyToast}
                sourceElement={copyTargetRef}
                wide
              >
                Copy email text
              </CopyButton>
            </Wrapper>
          </StyledContentWrapper>
        </StyledMessageContainerWrapper>
      </StyledBackgroundWrapper>
    </>
  )
}
