import React, { useContext } from 'react'
import styled from 'styled-components'
import { Checkbox, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import { FilterContext } from 'store/filter'
import { InheritancePrecedence } from 'types'

const StyledWrapper = styled(Wrapper)`
  > * {
    margin: ${GTR.XS} 0;
  }
`

const StyledLegend = styled.legend`
  color: ${COLOR.BLACK};
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.BOLD};
  margin-bottom: ${GTR.S};
`

const InheritancePrecedenceOptions = [
  { key: 'primary', label: 'Primary gifts' },
  { key: 'secondary', label: 'Secondary gifts' },
]

export const InheritancePrecedenceFilter = (): React.ReactElement => {
  const [{ next }, { setInheritancePrecedence }] = useContext(FilterContext)

  return (
    <Wrapper margin={['L', 0]} tag="section">
      <StyledLegend>Gifting</StyledLegend>
      <StyledWrapper>
        {InheritancePrecedenceOptions.map((option) => {
          const checked = next.inheritancePrecedence.includes(
            option.key as InheritancePrecedence
          )
          return (
            <Checkbox
              checked={checked}
              label={option.label}
              key={option.key}
              onChange={() => {
                if (option.key === 'primary') {
                  if (next.inheritancePrecedence.includes('secondary')) {
                    if (checked) {
                      /** We will only allow 'primary' to become unchecked if 'secondary' is already checked */
                      setInheritancePrecedence(['secondary'])
                    } else {
                      setInheritancePrecedence(['primary', 'secondary'])
                    }
                  } else {
                    /** If 'secondary' is unchecked, 'primary' must ALWAYS be checked */
                    setInheritancePrecedence(['primary'])
                  }
                }

                if (option.key === 'secondary') {
                  if (checked) {
                    setInheritancePrecedence(['primary'])
                  } else {
                    setInheritancePrecedence(['primary', 'secondary'])
                  }
                }
              }}
              size="S"
            />
          )
        })}
      </StyledWrapper>
    </Wrapper>
  )
}
