import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { Step } from './Step'
import { Rate } from './Rate'

const StyledFunnel = styled(Wrapper)<{ $columns: number }>`
  text-align: center;
  position: relative;
  z-index: 0;

  ${({ $columns }) =>
    screenMin.l`
      display: flex;

      > * {
        flex-basis: ${100 / $columns}%;
      }
    `}
`

interface Props {
  steps: {
    value: number
    label: string
    rate?: number | null
    rateLabel?: string
  }[]
}

export const FunnelChart = ({ steps }: Props): React.ReactElement => (
  <StyledFunnel $columns={steps.length * 2 - 1} margin={['M', 0]} noTrim>
    {steps.map(({ value, label, rate, rateLabel }, i) => (
      <React.Fragment key={label}>
        {i > 0 && <Rate label={rateLabel} value={rate} />}
        <Step label={label} value={value} index={i} />
      </React.Fragment>
    ))}
  </StyledFunnel>
)
