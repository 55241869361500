import React, { useContext } from 'react'
import styled from 'styled-components'
import { P, BulletSeparator } from '@farewill/ui'

import { FilterContext } from 'store/filter'
import { hasFilters } from 'store/filter/selectors'
import { formatDate } from 'lib/formatting/time'
import { SkeletonLoader } from 'components/loader/SkeletonLoader'

const StyledFilterListTitle = styled(P)`
  line-height: 1.5;
  display: inline;
`

const StyledFilterList = styled(BulletSeparator)`
  display: inline;
  list-style: none;

  > * {
    display: inline;
  }
`

const StyledFilter = styled.span`
  white-space: nowrap;
`

interface Props {
  loading: boolean
}

export const ActiveFiltersList = ({ loading }: Props): React.ReactElement => {
  const [state] = useContext(FilterContext)
  const filtersApplied = hasFilters(state)

  const filtersList: string[] = []

  const { trafficTypeFilter, activeVoucherCodes, inheritancePrecedence } =
    state.current
  if (trafficTypeFilter === 'cold') filtersList.push('Cold traffic')
  if (trafficTypeFilter === 'campaign') {
    if (activeVoucherCodes.length === 0) {
      filtersList.push('Free will service')
    } else {
      filtersList.push(
        `${activeVoucherCodes.length} code${activeVoucherCodes.length === 1 ? '' : 's'} selected`
      )
    }
  }
  /**
   * We will only display inheritancePrecedence filters if 'secondary' is one of the filters,
   * because 'primary'-only is the default "no-filter" state
   */
  if (inheritancePrecedence.includes('secondary')) {
    filtersList.push('Secondary gifts')
    if (inheritancePrecedence.includes('primary')) {
      filtersList.push('Primary gifts')
    }
  }

  const { startDate, endDate } = state.current
  if (startDate && endDate) {
    filtersList.push(`${formatDate(startDate)} to ${formatDate(endDate)}`)
  }

  return (
    <>
      <SkeletonLoader loading={loading} inline>
        {filtersApplied ? (
          <>
            <StyledFilterListTitle tag="h2" margin={0}>
              {filtersList.length} filter{filtersList.length > 1 && 's'}{' '}
              applied:&ensp;
            </StyledFilterListTitle>
            <StyledFilterList>
              {filtersList.map((filterName: string) => (
                <StyledFilter key={filterName}>{filterName}</StyledFilter>
              ))}
            </StyledFilterList>
          </>
        ) : (
          <P strong>No filters applied</P>
        )}
      </SkeletonLoader>
    </>
  )
}
