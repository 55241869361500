import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useReducerAsync } from 'use-reducer-async'

import { H, Image, P, Wrapper } from '@farewill/ui'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import { DropdownList } from 'components/form/DropdownList'
import { TabbedNavigation } from 'components/TabbedNavigation'
import { EmailPreview } from 'routes/Assistant/components/EmailPreview'
import { GlobalContext } from 'store/global'

import { reducer, ActionType } from './reducer'
import type { Audience, Emails, EmailKey, EmailType, Tab } from './types'
import { asyncActionHandlers } from './asyncActions'

import {
  AUDIENCE,
  AUDIENCE_DROPDOWN_OPTIONS,
  EMAIL_TYPES,
  TABS,
} from './constants'

const StyledIntroWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  gap: ${GTR.XXL};
  padding-left: 0;
  padding-right: 0;
`

const StyledIntroCopyWrapper = styled(Wrapper)`
  max-width: 57ch;
`

const StyledImage = styled(Image)`
  ${screenMax.m`
    display: none;
  `}
`

const StyledDropdownLabel = styled.label`
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  margin-block-end: ${GTR.XS};
`

const initialState: Emails = Object.values(AUDIENCE).reduce(
  (emails, audience) => {
    Object.values(EMAIL_TYPES).forEach((emailType) => {
      const key = `${audience}_${emailType}` as EmailKey
      // eslint-disable-next-line no-param-reassign
      emails[key] = {
        animated: false,
        audience,
        content: '',
        error: undefined,
        generationCount: 0,
        generationEndTime: undefined,
        generationInProgress: false,
        generationInstructions: '',
        generationStartTime: undefined,
        subject: undefined,
        threadId: undefined,
      }
    })
    return emails
  },
  {} as Emails
)

export const EmailCampaigns = (): React.ReactElement => {
  const [
    {
      partner: { id: partnerId, name: partnerName },
    },
  ] = useContext(GlobalContext)
  const [activeTab, setActiveTab] = useState<Tab>(TABS[0])
  const [activeAudience, setActiveAudience] = useState<Audience>(
    AUDIENCE.SUPPORTERS
  )
  const [emailState, dispatch] = useReducerAsync(
    reducer,
    initialState,
    asyncActionHandlers
  )
  const emailKey = `${activeAudience}_${activeTab.key}` as EmailKey
  const loading = !partnerId || emailState[emailKey].generationInProgress
  const isFirstLoad = emailState[emailKey].generationCount === 0

  useEffect(() => {
    // Don't trigger loading until we have the partner ID
    if (!partnerId) return

    /*
     * Don't re-run generation if the email has already been generated.
     * e.g. When changing tabs if the user has already prevuously run the initial
     * generation for that audiences emails we should not re-generate. The only
     * way to "start from scratch" right now is to reload the page.
     */
    if (!isFirstLoad) {
      return
    }

    dispatch({
      type: ActionType.TriggerGenerate,
      payload: {
        audience: activeAudience,
        partnerId,
      },
    })
  }, [activeAudience, dispatch, isFirstLoad, partnerId])

  return (
    <>
      <StyledIntroWrapper container>
        <StyledIntroCopyWrapper>
          <H tag="h1" size="M">
            Email campaigns
          </H>
          <P color={COLOR.BLACK}>
            Use AI to create a bespoke sequence of emails to promote your free
            will service. They’ll be based on Farewill’s knowledge of great
            campaigns, and personalised to your charity.
          </P>
          <P color={COLOR.BLACK}>
            You can regenerate the text with custom requests, and copy the text
            to your preferred email provider once you’re happy with the email.
            Always double check the content is accurate and make any updates you
            need once you’ve copied it.
          </P>
        </StyledIntroCopyWrapper>
        <StyledImage
          path="illustrations/blob-with-laptop-unfinished"
          ext="png"
          formatPath={({ path, width }) =>
            `${CLOUDINARY_ROOT_PATH}/e_trim/c_scale,f_auto,q_auto,w_${width}/${path}.png`
          }
          width={368}
        />
      </StyledIntroWrapper>

      <Wrapper margin={[GTR.M, 0, GTR.XL]} marginFromL={[0, 0, GTR.XL]}>
        <StyledDropdownLabel htmlFor="audience">
          I want to create an email campaign for:
        </StyledDropdownLabel>
        <DropdownList
          defaultValue={activeAudience}
          data={AUDIENCE_DROPDOWN_OPTIONS}
          id="audience"
          onChange={(data: { value: Audience }) => {
            setActiveAudience(data.value)
          }}
          textField="label"
          valueField="value"
        />
      </Wrapper>

      <TabbedNavigation
        activeTabKey={activeTab.key as string}
        aria-controls="email-tabs"
        role="tablist"
        tabs={TABS}
        onTabChange={(key) => {
          const tab = TABS.find((t) => (t.key as string) === key)
          if (tab) setActiveTab(tab)
        }}
      />

      <div id="email-tabs">
        {TABS.map((tab) => (
          <div
            aria-labelledby={`tab-${tab.key}`}
            id={`tab-panel-${tab.key}`}
            key={`tab-panel-${tab.key}`}
            role="tab"
            hidden={tab.key !== activeTab.key}
            style={{ display: tab.key === activeTab.key ? 'block' : 'none' }}
          >
            {tab.key === activeTab.key && (
              <>
                <EmailPreview
                  animate={!emailState[emailKey].animated}
                  key={emailKey}
                  from={partnerName}
                  loading={loading}
                  error={emailState[emailKey].error}
                  subject={emailState[emailKey].subject}
                  to={
                    activeAudience === AUDIENCE.SUPPORTERS
                      ? 'Supporters'
                      : 'Staff'
                  }
                  regenerate={({
                    instructions,
                  }: {
                    email: EmailType
                    instructions: string
                  }) => {
                    dispatch({
                      type: ActionType.TriggerRegenerate,
                      payload: {
                        audience: activeAudience,
                        email: activeTab.key,
                        instructions,
                        partnerId,
                        threadId: emailState[emailKey].threadId,
                      },
                    })
                  }}
                  onAnimationEnd={() => {
                    dispatch({
                      type: ActionType.SetAnimationState,
                      payload: {
                        audience: activeAudience,
                        animated: true,
                      },
                    })
                  }}
                  onRetry={() => {
                    if (emailState[emailKey].generationInstructions) {
                      dispatch({
                        type: ActionType.TriggerRegenerate,
                        payload: {
                          audience: activeAudience,
                          email: activeTab.key,
                          instructions:
                            emailState[emailKey].generationInstructions,
                          partnerId,
                          threadId: emailState[emailKey].threadId,
                        },
                      })
                    } else {
                      dispatch({
                        type: ActionType.TriggerGenerate,
                        payload: {
                          audience: activeAudience,
                          partnerId,
                        },
                      })
                    }
                  }}
                >
                  {emailState[emailKey].content}
                </EmailPreview>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
