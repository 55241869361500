import React, { useContext } from 'react'
import { Wrapper, H } from '@farewill/ui'

import { FilterContext } from 'store/filter'

import { SkeletonLoader } from 'components/loader/SkeletonLoader'
import { FunnelChart } from 'components/stats/FunnelChart'
import { ServiceTypeContext } from 'routes/context'

import { PerformanceContext } from './context'
import { ConversionFromRegistrationExplainer } from './ConversionFromRegistrationExplainer'

export const ConversionFromRegistration = (): React.ReactElement => {
  const { data, loading } = useContext(PerformanceContext)
  const willServiceType = useContext(ServiceTypeContext)

  const [state] = useContext(FilterContext)
  const { startDate, endDate } = state.current

  return (
    <Wrapper separator>
      <SkeletonLoader loading={loading} inline>
        <H tag="h2" size="M" margin={0}>
          Conversion from registration
        </H>
        <ConversionFromRegistrationExplainer
          willServiceType={willServiceType}
          startDate={startDate}
          endDate={endDate}
        />
      </SkeletonLoader>

      <SkeletonLoader loading={loading}>
        <FunnelChart
          steps={[
            {
              value: data?.users ? data.users.registrations : 0,
              label: 'Registered',
            },
            {
              rate: data?.users?.purchase_rate,
              rateLabel: 'reg to will',
              value: data?.users ? data.users.purchases : 0,
              label: 'Made will',
            },
            {
              rate: data?.users?.donor_rate,
              rateLabel: 'will to gift',
              value: data?.users ? data.users.donors : 0,
              label: 'Included gift',
            },
          ]}
        />
      </SkeletonLoader>
    </Wrapper>
  )
}
