import { toLocaleStringSupportsOptions } from 'lib/formatting/common'

export const formatCurrencyInPounds = (number: number): string => {
  if (toLocaleStringSupportsOptions()) {
    return number.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  return `£${Math.round(number)}`
}
