import React from 'react'
import styled, { keyframes } from 'styled-components'
import { COLOR, BORDER, GTR } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'

const INLINE_SKELETON_WIDTH = 520
const SHINE_WIDTH = 200

const shineAnimation = keyframes`
  from {
    transform: translate(-${SHINE_WIDTH}px, 0);
  }
  to {
    transform: translate(100%, 0);
  }
`

const StyledSkeletonWrapper = styled.div`
  position: relative;

  > * {
    visibility: hidden;
    pointer-events: none;
  }
`
const StyledSkeleton = styled.span<{ $inline: boolean }>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #efefef;
  border-radius: ${BORDER.RADIUS.S};
  visibility: visible;

  ${({ $inline }) =>
    $inline &&
    `
    height: ${GTR.M};
    width: var(--skeleton-width);
    max-width: 100%;
  `}

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to right,
      ${alpha(COLOR.WHITE, 0)} 0%,
      ${alpha(COLOR.WHITE, 0.4)} ${SHINE_WIDTH / 2}px,
      ${alpha(COLOR.WHITE, 0)} ${SHINE_WIDTH}px
    );
    animation: ${shineAnimation} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation-play-state: var(--skeleton-animation-play-state);
  }
`

interface Props {
  animate?: boolean
  children: React.ReactNode
  loading: boolean
  inline?: boolean
  tag?: React.ElementType
  className?: string
  width?: number | string
}

export const SkeletonLoader = ({
  animate = true,
  loading,
  inline,
  className,
  children,
  tag,
  width = `${INLINE_SKELETON_WIDTH}px`,
}: Props): React.ReactElement => {
  const Element = tag || 'div'
  if (!loading) return <Element className={className}>{children}</Element>
  const $width = typeof width === 'string' ? width : `${width}px`

  return (
    <StyledSkeletonWrapper className={className} as={Element}>
      <StyledSkeleton
        $inline={!!inline}
        style={
          {
            '--skeleton-animation-play-state': animate ? 'running' : 'paused',
            '--skeleton-width': $width,
          } as React.CSSProperties
        }
      />
      {children}
    </StyledSkeletonWrapper>
  )
}
