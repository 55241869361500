import React from 'react'
import styled, { keyframes } from 'styled-components'

import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { TooltipWrapper } from 'components/Tooltip'

const enhance = keyframes`
  0% {
    color: inherit;
    text-decoration-color: transparent;
  }

  100% {
    color: ${COLOR.STATE.ACTIVE};
    text-decoration-color: ${COLOR.STATE.ACTIVE};
  }
`

const StyledWrapper = styled(Wrapper)`
  color: ${COLOR.BLACK};

  [role='tooltip'] {
    width: 250px;
  }
`

/**
 * [1] Used to enable a smooth color transition for our LinkReminder component
 *     whose text starts out as black and only later becomes blue. This delayed
 *     color change is a workaround for a technical limitation of the way the
 *     text reveal animation works (`background-clip: text` & a gradient
 *     background image whose `background-size` is animated). Because of the
 *     limitations imposed by the approach used for the text reveal animation
 *     we're only able to set the colour to blue once the animation completes.
 */
const StyledLinkText = styled.span`
  animation: ${enhance} 0.3s ease forwards; // [1]
  color: ${COLOR.STATE.ACTIVE};
  text-decoration: underline;
`

type LinkReminderProps = {
  children: React.ReactNode
}

export const LinkReminder = ({
  children,
}: LinkReminderProps): React.ReactElement => (
  <StyledWrapper tag="span">
    <TooltipWrapper
      as="span"
      gravity="left"
      tooltipContent="Remember to add a relevant link to this text before sending!"
      tooltipId="link-tooltip"
    >
      <StyledLinkText>{children}</StyledLinkText>
    </TooltipWrapper>
  </StyledWrapper>
)
