import moment from 'moment'

interface DateIsInRange {
  selectedDate: moment.MomentInput
  minDate: moment.MomentInput
  maxDate: moment.MomentInput
}

export const dateIsInRange = ({
  selectedDate,
  minDate,
  maxDate,
}: DateIsInRange): boolean =>
  moment(selectedDate).isBetween(minDate, maxDate, 'days', '[]')
