/* eslint-disable multiline-comment-style */

import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import * as Plot from '@observablehq/plot'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { Prettify } from 'types'
import { formatNumber, formatPercentage } from 'lib/formatting/numbers'
import { FAREWILL_DISCRETE_CHART_COLOURS } from './constants'

/**
 * A single point on the chart.
 * @see ChartData
 */
export interface Datum {
  /**
   * The value for the x-axis
   */
  x: Date
  /**
   * The value for the y-axis
   */
  y: number
  /**
   * An optional value used to separate data into multiple lines.
   * This is best thought of as "series" or "group".
   * It is assumed that either every Datum has a z-dimension or no Datum has a z-dimension
   */
  z?: string
}

/**
 * Chart Data must be "tidy data"!!! (see below!)
 * We do our best to enforce this using @see Datum.
 * However, it's important your data is structured correctly and in chronoglogical order.
 * The following references explain the concepts of TidyData (in decreasing order of practicality!)
 * See: https://observablehq.com/plot/features/plots#marks-option
 * See: https://observablehq.com/plot/features/marks#marks-have-tidy-data)
 * See: http://vita.had.co.nz/papers/tidy-data.html
 */
export type ChartData = Prettify<Datum>[]

const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  notation: 'compact',
})

/**
 * A factory for generating number formatters.
 * @param yValueType The type of number input
 * @returns A function to format numbers
 */
export function createFormatter(
  yValueType: NumberType
): (value: number) => string {
  if (yValueType === 'integer') {
    return (value: number) => formatNumber(value)
  }
  if (yValueType === 'currency') {
    return (value: number) => currencyFormatter.format(value)
  }
  if (yValueType === 'float') {
    return (value: number) => value.toFixed(2)
  }

  if (yValueType === 'percentage') {
    return (value: number) => formatPercentage(value)
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`No formatter implemented for ${yValueType}`)
}

const StyledWrapper = styled.figure`
  position: relative;
  margin: 0;
  max-width: fit-content;
  max-height: fit-content;

  /* 
  * Axis labels
  */
  svg text {
    color: ${COLOR.GREY.DARK};
  }

  /*
  * Grid lines by default have a reduced opacity
  */
  svg line {
    stroke-opacity: 1;
  }

  figcaption {
    margin-top: ${GTR.S};
    margin-left: 35px;
    font-family: ${FONT.FAMILY.BODY};
  }

  figcaption {
    svg {
      border-radius: 2px;
      overflow: hidden;
    }

    span {
      color: ${COLOR.GREY.DARK};
    }
  }

  g[aria-label='tip'] tspan {
    color: ${COLOR.BLACK};
  }
`

/**
 * Used to specify how JavaScript's number type should be represented
 */
export type NumberType = 'integer' | 'currency' | 'float' | 'percentage'

export interface LineChartProps {
  data: ChartData
  /**
   * The unit of frequency for x-axis data. Used for displaying the x-axis.
   *  Must match the data provided to the chart.
   */
  xAxisPeriod: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
  /**
   * Transforms a Datum into a descriptive title.
   * Used for both the tooltip (if enabled) and also for accessibility.
   * Falls back to a default implementation if none is provided
   * @param d The datum
   * @returns The title
   */
  pointTitleFn?: (d: Datum) => string
  /**
   * Shows or hides the legend.
   * Defaults to shown.
   */
  showLegend?: boolean
  /**
   * Enables or disables the tooltip shown when hovering data points.
   * Defaults to enabled.
   */
  tooltipEnabled?: boolean
  /**
   * Use this to override how the X-Axis looks and behaves
   */
  xScaleOverrides?: Partial<Plot.ScaleOptions>
  /**
   * Use this to override how the Y-Axis looks and behaves
   */
  yScaleOverrides?: Partial<Plot.ScaleOptions>
  /**
   * The shape used to indicate where a point is found
   */
  pointShape?: Plot.Marker | 'none'
  /**
   * The type of quantative data expressed on the Y-Axis
   */
  yValueType: NumberType
  width?: number
  height?: number
}

/**
 * A line chart which is not currently fully generic.
 * It is currently assumed:
 * * The x-axis is continuous temporal data in UTC.
 * * The y-axis is linear numerical data.
 * * The data is "tidy" and sorted in chronological order.
 * See: https://observablehq.com/plot/features/marks#marks-imply-data-types
 */
export const LineChart = ({
  data,
  pointTitleFn,
  xAxisPeriod,
  pointShape = 'none',
  showLegend = true,
  tooltipEnabled = true,
  xScaleOverrides,
  yScaleOverrides,
  yValueType,
  width = 640,
  height = 480,
}: LineChartProps): React.ReactElement => {
  const plotContainerRef = useRef<HTMLElement>(null)
  const legendContainerRef = useRef<HTMLElement>(null)

  // TODO AF 2024-05-15 This should be made a prop so the formatting can be overriden on a chart by chart basis
  const formatFn = createFormatter(yValueType)

  // useEffect required to manage the lifecycle of the DOM managed by Plot
  useEffect(() => {
    const defaultpointTitleFn = ({ x, y, z }: Datum) =>
      `${x.toLocaleDateString()}\n${formatFn(y)}${z ? `\n${z}` : ''}`

    const LINE_DEFAULTS: Partial<Plot.LineOptions> = {
      x: 'x',
      y: 'y',
      curve: 'monotone-x',
      marker: pointShape,
      title: pointTitleFn ?? defaultpointTitleFn,
      ...(tooltipEnabled && {
        // See: https://observablehq.com/plot/marks/tip#tip-mark
        tip: {
          fontFamily: FONT.FAMILY.BODY,
          fontSize: 14,
          lineHeight: 1.5,
          fontWeight: FONT.WEIGHT.REGULAR,
        },
      }),
      strokeOpacity: 1,
      strokeWidth: 3,
      // Used for charts with a single line. Overwritten by Z_PRESENT_DEFAULTS for multiple lines.
      stroke: FAREWILL_DISCRETE_CHART_COLOURS[0],
    }

    /**
     * Only used when the data is separated by a z dimension
     */
    const Z_PRESENT_DEFAULTS: Partial<Plot.LineOptions> = {
      z: 'z',
      // Line stroke is set dynamically per z dimension
      stroke: { value: 'z', scale: 'color' },
    }

    const lineOptions: Partial<Plot.LineOptions> = {
      ...LINE_DEFAULTS,
      ...(data?.[0]?.z !== undefined &&
        data?.[0]?.z !== null &&
        Z_PRESENT_DEFAULTS),
    }

    const plotOptions: Plot.PlotOptions = {
      style: {
        // By default currentColour is used for all axis lines and text
        // We override this for text (e.g. labels) in the wrapper component
        color: COLOR.GREY.LIGHT,
      },
      // Prevents the left axis being clipped
      marginLeft: 40,
      width,
      height,
      color: {
        // See: https://observablehq.com/plot/features/scales#color-scales
        type: 'ordinal',
        range: FAREWILL_DISCRETE_CHART_COLOURS,
      },
      x: {
        line: true,
        label: null,
        grid: false,
        nice: xAxisPeriod,
        type: 'utc',
        ...(xScaleOverrides && { ...xScaleOverrides }),
      },
      y: {
        zero: true,
        line: true,
        label: null,
        grid: true,
        nice: true,
        type: 'linear',
        tickFormat: (t) => formatFn(t as number),
        ...(yScaleOverrides && { ...yScaleOverrides }),
      },
      marks: [Plot.line(data, lineOptions)],
    }

    const plot = Plot.plot(plotOptions)
    plotContainerRef.current.prepend(plot)

    let legend: HTMLElement | SVGElement
    if (showLegend) {
      legend = plot.legend('color')
      const svgs = Array.from(legend.querySelectorAll('svg'))
      svgs
        .filter((a) => !!a)
        .forEach((svg) => {
          const fill = svg.getAttribute('fill')
          svg.setAttribute('color', fill)
        })
      legendContainerRef.current.append(legend)
    }

    // Cleanup during component dismount
    return () => {
      plot.remove()
      if (showLegend) {
        legend.remove()
      }
    }
  }, [
    data,
    pointTitleFn,
    tooltipEnabled,
    showLegend,
    xAxisPeriod,
    pointShape,
    xScaleOverrides,
    yScaleOverrides,
    formatFn,
    width,
    height,
  ])
  return (
    <StyledWrapper ref={plotContainerRef}>
      {showLegend && <figcaption ref={legendContainerRef} />}
    </StyledWrapper>
  )
}
