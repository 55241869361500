import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { Auth0ProviderWithNavigate } from 'auth0-provider-with-navigate'
import { App } from './App'

Sentry.init({
  dsn: process.env.SENTRY_UI_URL,
  release: process.env.GIT_HASH,
  environment: process.env.RELEASE_ENV,
})

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <App />
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
  document.getElementById('root')
)
