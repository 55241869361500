import React, { useState } from 'react'
import styled from 'styled-components'
import * as d3 from 'd3'

import { useElementSize } from 'lib/hooks/useDimensions'

import { BAR_CHART_HEIGHT, BAR_CHART_MARGIN } from '../chart/constants'
import { BarAxes } from '../chart/BarAxes'
import { Legend } from '../chart/Legend'

type Datum = Record<string, string | number | null>
export type Data = Datum[]

interface Props {
  xLabelKey: string
  groups: {
    label: string
    key: string
    color: string
  }[]
  data: Data
}

const StyledWrapper = styled.figure`
  position: relative;
  margin: 0;
`

export const GroupedBarChart = ({
  xLabelKey,
  groups,
  data,
}: Props): React.ReactElement => {
  const [wrapperElement, { width: elementWidth }] = useElementSize()
  const [marginBottom, setMarginBottom] = useState<number>(
    BAR_CHART_MARGIN.bottom
  )

  const width = elementWidth
  const height = BAR_CHART_HEIGHT + marginBottom
  const margin = { ...BAR_CHART_MARGIN, bottom: marginBottom }

  const xLabels = data.map((d: Datum) => String(d[xLabelKey]))

  const xScaleOuter = d3
    .scaleBand()
    .domain(xLabels)
    .range([margin.left, width - margin.right])
    .padding(0.2)

  const xScaleInner = d3
    .scaleBand()
    .domain(groups.map(({ key }) => key))
    .range([0, xScaleOuter.bandwidth()])
  const xScaleBandWidth = xScaleInner.bandwidth()

  const yMax = d3.max(
    groups
      .map(({ key }) => data.map((d) => d[key] as number))
      .reduce((acc, group) => [...acc, ...group], [])
  )
  const yScale = d3
    .scaleLinear()
    .domain([0, yMax || 1])
    .range([height - margin.bottom, margin.top])
    .nice()

  const xAxis = d3.axisBottom(xScaleOuter)
  const yAxis = d3.axisRight(yScale)

  const groupRects = groups
    .map(({ label, key, color }) =>
      data.map((d, i) => ({
        key: `${label}-${i}`,
        x: (xScaleOuter(xLabels[i]) || 0) + (xScaleInner(key) || 0),
        y: yScale(d[key] as number),
        width: xScaleBandWidth,
        height: (yScale(0) || 0) - (yScale(d[key] as number) || 0),
        fill: color,
      }))
    )
    .reduce((acc, group) => [...acc, ...group], [])

  return (
    <StyledWrapper ref={wrapperElement}>
      {width && (
        <svg width={width} height={height}>
          <BarAxes
            xAxis={xAxis}
            yAxis={yAxis}
            chartMargin={margin}
            chartWidth={width}
            chartHeight={height}
            setXAxisLabelHeight={setMarginBottom}
          />

          {groupRects.map((bar) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <rect {...bar} key={bar.key} />
          ))}
        </svg>
      )}

      <Legend data={groups} />
    </StyledWrapper>
  )
}
