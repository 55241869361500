/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'

import { P, Input, Wrapper, SearchIcon } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { interactiveBoxShadowStyles } from '@farewill/ui/helpers/interactive'

import { Modal } from 'components/Modal'
import { GlobalContext } from 'store/global'

const StyledListItem = styled.li``

const StyledButton = styled.button`
  ${interactiveBoxShadowStyles}
  border-radius: ${BORDER.RADIUS.S};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  justify-content: flex-start;
  padding: ${GTR.XS} 12px;
  text-align: left;
  transition: var(--interactive-transition);
  width: 100%;

  &:hover {
    background-color: ${COLOR.ACCENT.SECONDARY};
    color: ${COLOR.WHITE};
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled(Input)`
  margin: 0;
`

const StyledSearchWrapper = styled(Wrapper)`
  --fader-height: 12px;
  margin-bottom: var(--fader-height);
  padding: ${GTR.M} ${GTR.L} calc(${GTR.M} - var(--fader-height)) ${GTR.L};
  position: sticky;
  top: 0;
  z-index: 1;

  &::after {
    background-image: linear-gradient(to top, transparent, ${COLOR.WHITE});
    content: '';
    display: block;
    height: var(--fader-height);
    position: absolute;
    left: 0;
    bottom: calc(-1 * var(--fader-height));
    width: 100%;
  }
`

const StyledResultsWrapper = styled(Wrapper)`
  flex-grow: 1;
`

const StyledNoResultsMessage = styled(P)`
  max-width: 100%;
  word-break: break-word;
`

type SwitchPartnerFormProps = {
  className?: string
  onSubmit: (partnerId: string) => void
}

const SwitchPartnerForm = ({ className, onSubmit }: SwitchPartnerFormProps) => {
  const [{ availablePartners }] = useContext(GlobalContext)
  const [filter, setFilter] = useState('')
  const [selectedPartnerId, setSelectedPartnerId] = useState<string>('')

  const filteredPartners = useMemo(() => {
    return availablePartners
      .filter((p) => p.name.toLowerCase().includes(filter.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [availablePartners, filter])

  return (
    <StyledForm
      className={className}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit(selectedPartnerId)
      }}
    >
      <StyledSearchWrapper background={COLOR.WHITE}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <StyledInput
            aria-label="Search for a partner"
            autoFocus
            optional
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilter(e.target.value)
            }}
            prefix={<SearchIcon inline />}
            placeholder="Search for a partner"
          />
        </label>
      </StyledSearchWrapper>
      <input type="hidden" name="partner" value={selectedPartnerId} />
      <StyledResultsWrapper noTrim padding={[0, GTR.L, GTR.M]}>
        {filteredPartners.length === 0 ? (
          <StyledNoResultsMessage centered margin={[GTR.S, 0, GTR.M]}>
            🧐 No results for "{filter}"
          </StyledNoResultsMessage>
        ) : (
          <Wrapper tag="ol" margin={0}>
            {filteredPartners.map((p) => (
              <StyledListItem key={p.id}>
                <StyledButton
                  onClick={(e: React.FormEvent<HTMLButtonElement>) => {
                    setSelectedPartnerId(e.currentTarget.value)
                  }}
                  type="submit"
                  value={p.id}
                >
                  {p.name}
                </StyledButton>
              </StyledListItem>
            ))}
          </Wrapper>
        )}
      </StyledResultsWrapper>
    </StyledForm>
  )
}

const StyledSwitchPartnerForm = styled(SwitchPartnerForm)`
  max-height: 90vh;
`

type MasqueradeModalProps = {
  children: React.ReactNode
  close: () => void
  open: boolean
}

const StyledModalContent = styled(Modal.Content)`
  --dialog-padding: 0;
`

export const MasqueradeModal = ({
  children,
  open,
  close,
}: MasqueradeModalProps): React.ReactElement => {
  const [, { setMasqueradePartnerId }] = useContext(GlobalContext)

  return (
    <Modal
      open={open}
      onOpenChange={(state: boolean) => {
        if (!state) close()
      }}
    >
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <StyledModalContent title="Switch active partner">
        <StyledSwitchPartnerForm
          onSubmit={(partnerId) => {
            setMasqueradePartnerId(partnerId)
            close()
          }}
        />
      </StyledModalContent>
    </Modal>
  )
}
