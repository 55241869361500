import React from 'react'
import { H, Image, P, Wrapper, Divider } from '@farewill/ui'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'

import { Logo } from 'components/Logo'
import { Auth0Trust } from 'components/Auth0Trust'

export const PasswordExpiredError = ({
  email,
}: {
  email: string
}): React.ReactElement => (
  <Wrapper container>
    <Wrapper maxWidth={getWidthOfContainerColumns(8)} margin={[0, 'auto']}>
      <Logo />
    </Wrapper>

    <Wrapper maxWidth={420} margin={['XXL', 'auto']} centered>
      <Image
        stretch
        width={300}
        widthFromM={420}
        path="illustrations/cropped/welcome"
      />
      <H tag="h1" size="L" margin={['XL', 0, 'M']}>
        Reset your password
      </H>
      <P margin={[0, 0, 'M']}>
        Your password expired, so we’ve sent you an email with a reset link to{' '}
        <strong>{email}</strong>.
      </P>
      <P>Please follow the instructions in the email to reset your password.</P>
    </Wrapper>

    <Divider maxWidth={getWidthOfContainerColumns(8)} margin={['M', 'auto']} />

    <Wrapper centered>
      <Auth0Trust />
    </Wrapper>
  </Wrapper>
)
