import React from 'react'
import styled, { keyframes } from 'styled-components'

import * as RadixToast from '@radix-ui/react-toast'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'

type ToastProps = {
  description: React.ReactNode
  open: RadixToast.ToastProps['open']
  onOpenChange: RadixToast.ToastProps['onOpenChange']
  title: React.ReactNode
}

const TOAST_VIEWPORT_Z_INDEX = 200

const hide = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }

  to {
    transform: translateX(0);
  }
`

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
`

const StyledToastTitle = styled(RadixToast.Title)`
  color: ${COLOR.BLACK};
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  grid-area: title;
  margin-bottom: ${GTR.XXS};
`

const StyledToastDescription = styled(RadixToast.Title)`
  color: ${COLOR.GREY.DARK};
  font-size: ${FONT.SIZE.S};
  grid-area: description;
  line-height: 1.5;
  margin: 0;
`

const StyledToastRoot = styled(RadixToast.Root)`
  background-color: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.M};
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  align-items: center;
  column-gap: 15px;
  display: grid;
  grid-template-areas: 'title action' 'description action';
  grid-template-columns: auto max-content;
  padding: ${GTR.S};

  &[data-state='closed'] {
    animation: ${hide} 100ms ease-in;
  }

  &[data-state='open'] {
    animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='end'] {
    animation: ${swipeOut} 100ms ease-out;
  }
`

const StyledToastViewport = styled(RadixToast.Viewport)`
  --viewport-padding: ${GTR.M};
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  margin: 0;
  max-width: 100vw;
  outline: none;
  padding: var(--viewport-padding);
  position: fixed;
  right: 0;
  width: 390px;
  z-index: ${TOAST_VIEWPORT_Z_INDEX};
`

export const Toast = ({
  description,
  open,
  onOpenChange,
  title,
}: ToastProps) => {
  return (
    <>
      <StyledToastRoot
        open={open}
        onOpenChange={onOpenChange}
        aria-labelledby="toast-title"
      >
        <StyledToastTitle id="toast-title">{title}</StyledToastTitle>
        <StyledToastDescription>{description}</StyledToastDescription>
      </StyledToastRoot>
      <StyledToastViewport />
    </>
  )
}

Toast.Provider = RadixToast.Provider
