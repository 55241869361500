import React, { useContext } from 'react'
import { Wrapper, H, Grid } from '@farewill/ui'
import styled from 'styled-components'

import { isNumber } from 'lib/helpers/lang'
import { formatCurrencyInPounds } from 'lib/formatting/currency'
import { formatNumber, formatPercentage } from 'lib/formatting/numbers'
import { SkeletonLoader } from 'components/loader/SkeletonLoader'
import { GridItemWrapper } from 'components/stats/GridItemWrapper'
import { SingleStat } from 'components/stats/SingleStat'
import { PercentageChartWithTable } from 'components/stats/PercentageChartWithTable'

import { PerformanceContext } from './context'
import {
  getGiftChartColor,
  GiftChartCategory,
  StatFormat,
} from './chartHelpers'

const StyledGridItemWrapper = styled(GridItemWrapper)`
  height: 100%;
`

export const Gifts = (): React.ReactElement => {
  const { data, loading } = useContext(PerformanceContext)
  const { total_gifts, avg_gift_size, residuary, pecuniary } = data?.gifts || {}

  return (
    <Wrapper separator>
      <SkeletonLoader loading={loading} inline>
        <H tag="h2" size="M">
          Gifts
        </H>
      </SkeletonLoader>

      <Grid>
        <StyledGridItemWrapper spanFromM={6} loading={loading}>
          <SingleStat
            title="Total gifts pledged"
            description="A will may include multiple gifts, so this number may be higher than ‘Number of wills including a gift’."
            value={isNumber(total_gifts) ? formatNumber(total_gifts) : null}
          />
        </StyledGridItemWrapper>

        <StyledGridItemWrapper spanFromM={6} loading={loading}>
          <SingleStat
            title="Average gift size"
            description="Across all residuary and pecuniary gifts."
            value={
              isNumber(avg_gift_size)
                ? formatCurrencyInPounds(avg_gift_size)
                : null
            }
          />
        </StyledGridItemWrapper>

        <GridItemWrapper loading={loading}>
          <H tag="h3" size="XS">
            Income by gift type
          </H>
          <PercentageChartWithTable
            items={[
              {
                title: 'Residuary income',
                value: residuary?.total_income || 0,
                valueFormatter: formatCurrencyInPounds,
                additionalData: [
                  {
                    label: 'Average gift size',
                    value:
                      residuary?.avg_gift_size && residuary?.avg_income
                        ? [
                            formatPercentage(residuary.avg_gift_size, 1),
                            formatCurrencyInPounds(residuary.avg_income),
                          ]
                        : null,
                  },
                  {
                    label: 'Maximum gift size',
                    value:
                      residuary?.max_gift_size && residuary?.max_income
                        ? [
                            formatPercentage(residuary.max_gift_size, 1),
                            formatCurrencyInPounds(residuary.max_income),
                          ]
                        : null,
                  },
                ],
                color: getGiftChartColor(
                  StatFormat.Monetary,
                  GiftChartCategory.Residuary
                ),
              },
              {
                title: 'Pecuniary income',
                value: data?.gifts.pecuniary.total_income || 0,
                valueFormatter: formatCurrencyInPounds,
                additionalData: [
                  {
                    label: 'Average gift size',
                    value: pecuniary?.avg_income
                      ? formatCurrencyInPounds(pecuniary.avg_income)
                      : null,
                  },
                  {
                    label: 'Maximum gift size',
                    value: pecuniary?.max_income
                      ? formatCurrencyInPounds(pecuniary.max_income)
                      : null,
                  },
                ],
                color: getGiftChartColor(
                  StatFormat.Monetary,
                  GiftChartCategory.Pecuniary
                ),
              },
            ]}
          />
        </GridItemWrapper>

        <GridItemWrapper loading={loading}>
          <H tag="h3" size="XS">
            Total gifts by type
          </H>
          <PercentageChartWithTable
            items={[
              {
                title: 'Residuary gifts',
                value: residuary?.total_gifts || 0,
                valueFormatter: formatNumber,
                color: getGiftChartColor(
                  StatFormat.Numerical,
                  GiftChartCategory.Residuary
                ),
              },
              {
                title: 'Pecuniary gifts',
                value: pecuniary?.total_gifts || 0,
                valueFormatter: formatNumber,
                color: getGiftChartColor(
                  StatFormat.Numerical,
                  GiftChartCategory.Pecuniary
                ),
              },
            ]}
          />
        </GridItemWrapper>
      </Grid>
    </Wrapper>
  )
}
