/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react'
import styled from 'styled-components'

import { Button, H, P, Wrapper } from '@farewill/ui'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { GlobalContext } from 'store/global'

const StyledWrapper = styled(Wrapper)`
  width: 100%;
`

export const Resources = (): React.ReactElement => {
  const [
    {
      partner: { has_marketing_assistant_access },
    },
  ] = useContext(GlobalContext)

  return (
    <DefaultLayout>
      <StyledWrapper container>
        <Wrapper margin={[0, 0, 'L']}>
          <H size="L" tag="h1">
            Resources
          </H>
          <P>
            The following resources can be used to help you run your campaigns.
          </P>
          <H size="S" tag="h2">
            Partner hub
          </H>
          <P>
            Find inspiration for your free will campaigns, read FAQs on our
            service and discover what&apos;s new at Farewill.
          </P>
          <Button.Primary
            href="https://partnerships.farewill.com/handbook"
            target="_blank"
          >
            View hub
          </Button.Primary>
        </Wrapper>
        {has_marketing_assistant_access && (
          <Wrapper margin={[0, 0, 'L']}>
            <H size="S" tag="h2">
              Marketing Assistant
            </H>
            <P>
              We’ve created a tool that takes our knowledge of high-performing
              legacy campaigns, and uses AI to generate fresh new campaigns for
              you. We hope this helps you deliver great campaigns in less time,
              and improve engagement and gift inclusion rates.
            </P>
            <Button.Primary href="/assistant" target="_blank">
              Go to Marketing Assistant
            </Button.Primary>
          </Wrapper>
        )}
        <Wrapper margin={[0, 0, 'L']}>
          <H size="S" tag="h2">
            Create a new campaign
          </H>
          <P>
            To create a new campaign, let us know and we will provide you with a
            new code and landing page within 5 working days. This way, you can
            direct supporters to the new page and track the impact of your
            campaign. You can also share your campaign materials with us to get
            some feedback before launching.
          </P>
          <Button.Primary
            href="https://form.typeform.com/to/BTkRl2vQ"
            target="_blank"
          >
            Create a new campaign
          </Button.Primary>
        </Wrapper>
      </StyledWrapper>
    </DefaultLayout>
  )
}
