import { DateTime } from 'luxon'
import { DATE_RANGE } from '../constants'

export type DateRangeOption = (typeof DATE_RANGE)[keyof typeof DATE_RANGE]

export const getDateRange = (
  option: DateRangeOption
): { startDate: Date; endDate: Date } => {
  const dt = DateTime.utc()
  /**
   * When we send the dates to the API, we leave out the time, so strictly speaking, the time is not important.
   * However, in this method, we will always set the time to the start of the UTC day (T00:00:00.000Z), so that
   * the day will always be the same regardless of whether we are in GMT or BST (one hour ahead).
   */
  const now = dt.startOf('day').toJSDate()

  switch (option) {
    case DATE_RANGE.THIS_MONTH:
      return {
        startDate: dt.startOf('month').toJSDate(),
        endDate: now,
      }
    case DATE_RANGE.LAST_MONTH:
      return {
        startDate: dt.minus({ months: 1 }).startOf('month').toJSDate(),
        endDate: dt
          .minus({ months: 1 })
          .endOf('month')
          .startOf('day')
          .toJSDate(),
      }
    case DATE_RANGE.THIS_QUARTER:
      return {
        startDate: dt.startOf('quarter').toJSDate(),
        endDate: now,
      }
    case DATE_RANGE.LAST_QUARTER:
      return {
        startDate: dt.minus({ quarters: 1 }).startOf('quarter').toJSDate(),
        endDate: dt
          .minus({ quarters: 1 })
          .endOf('quarter')
          .startOf('day')
          .toJSDate(),
      }
    case DATE_RANGE.THIS_YEAR:
      return {
        startDate: dt.startOf('year').toJSDate(),
        endDate: now,
      }
    case DATE_RANGE.LAST_YEAR:
      return {
        startDate: dt.minus({ years: 1 }).startOf('year').toJSDate(),
        endDate: dt.minus({ years: 1 }).endOf('year').startOf('day').toJSDate(),
      }
    case DATE_RANGE.LAST_12_MONTHS:
      return {
        startDate: dt.minus({ months: 12 }).startOf('day').toJSDate(),
        endDate: now,
      }
    default:
      throw new Error(`Invalid date range option: ${option as string}`)
  }
}
