import React from 'react'
import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'

const BORDER_HEIGHT = GTR.XXS

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  onClick: () => void
  active: boolean
  children: React.ReactNode
}

const StyledTab = styled.button`
  text-decoration: none;
  border-bottom: solid ${BORDER_HEIGHT} transparent;
  position: relative;
  padding: ${GTR.S} 0;
  line-height: 1;

  &:not(:last-of-type) {
    margin-right: ${GTR.L};
  }

  &:hover {
    text-decoration: none;
    border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.15)};
  }

  &:active {
    outline: 0;
    text-decoration: none;
    border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.25)};
  }

  &:focus {
    outline: 0;
    text-decoration: none;
    border-bottom-color: ${alpha(COLOR.GREY.DARK, 0.35)};
  }

  // increase touch area
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: calc(-1 * ${BORDER_HEIGHT});
    left: calc(-1 * ${GTR.XS});
    right: calc(-1 * ${GTR.XS});
  }

  ${({ $active }: { $active: boolean }) =>
    $active &&
    `
    && {
      border-bottom-color: ${COLOR.ACCENT.SECONDARY};
    }
  `}
`

export const Tab = ({
  disabled,
  onClick,
  active,
  children,
  ...props
}: Props): React.ReactElement => (
  <StyledTab
    aria-selected={active}
    onClick={onClick}
    disabled={disabled}
    $active={active}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </StyledTab>
)
